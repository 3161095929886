module.exports = function (context) {

  var store = localStorage;

  return {
    /**
     * Uložení do local storage
     * @param  {string} prefix
     * @param  {object} objekt k ulozeni
     * @return {void}
     */
    saveToLocalStorage (prefix, obj) {
      // kontrola, zda je ukladani do localStorage vubec mozne
      // (napr. Safari, iOS anonymni rezeim - v tom to nefunguje a formular se pote neda odeslast)
      function isLocalStorageNameSupported() {
          var testKey = 'test', storage = window.localStorage;
          try {
              storage.setItem(testKey, '1');
              storage.removeItem(testKey);
              return true;
          } catch (error) {
              return false;
          }
      }

      if(!isLocalStorageNameSupported())
        return

      var searchLength = store.getItem(`${prefix}-search_length`) || 0;

      //neukladame duplicity
      var duplicit = true;

      for(var i = 0; i < searchLength; i++) {
        var item = JSON.parse(localStorage.getItem(`${prefix}-item_${i}`));

        duplicit = ((item.to !== obj.to) || (item.from !== obj.from));

        if (duplicit === false)
          return;
      }

      if (duplicit)
        store.setItem(`${prefix}-item_${searchLength}`, JSON.stringify(obj));
        store.setItem(`${prefix}-search_length`, parseInt(searchLength) + 1);
    },
    /**
     * Načtení z local storage při inicializaci formuláře
     * @return {void}
     */
    loadFromLocalStorage (prefix) {

      var searchLength = store.getItem(`${prefix}-search_length`) || 0;
      var i = 0;

      context.data('opts').routes = [];

      while (i < searchLength) {
        if(store.getItem(`${prefix}-item_${i}`))
          context.data('opts').routes.unshift(JSON.parse(store.getItem(`${prefix}-item_${i}`)));
        else
          store.clear(); // smaze stary format
        i += 1;
      }
    }
  };

};
