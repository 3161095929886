module.exports = function jpSelectActions(component, context) {
  var select = require('../../utils/fields/select');
	var renderSelect = require('../../utils/fields/select');
  var renderTag = require('../../utils/render-tag');

  return {
    jp_changeSelect,
    jp_changeType
  };
  function jp_changeSelect(a) {
  	var type = $(a.target).closest('.jp-form-type').data('jpformtype');
  	var select = $('.study-abroad-form select', context);
  	var options = component.options;
    var customSerial = `${options.dataPath}?id=${options.params.id}&jp_type=${type}&` + customSerialization(select, a.target);
    $.getJSON(customSerial, (data) => {
      data.fields.forEach((obj) => {
        var activeSelect = $('.' + obj.id, context);

        if(!$(activeSelect).is("select"))
          return;

        if(obj.hide) //Selectbox schovám, ak má byť skrytý 
          activeSelect.closest('.form-group').hide();
        else if(activeSelect.is(':hidden')) //Inak ho zobrazím
          activeSelect.closest('.form-group').show();

        activeSelect.empty();
        obj.items.forEach((item) => {
          $('<option/>', {
              value: item.value,
              text: item.name
          }).appendTo(activeSelect);
          if (item.selected)
            $(activeSelect).val(item.value);
        });
      });
    });
  }

  function jp_changeType(id) {
  	var wrapper = $('#jp-type-' + id);

  	if(wrapper.is(':empty')) {
  		//Ak je vybraný prázdny, naplním ho

  		$.getJSON(component.options.dataPath + '?id=jp&jp_type=' + id)
  		.done(function(data) {
  			data.fields.forEach((field) => {
          var block = $('<div/>', {
            'class': 'study-form-group form-group',
            'id': field.id + ' -block'
          }).appendTo(wrapper);
          renderTag.label(field.id, field.label, 'control-label').appendTo(block);
  				renderSelect(field, block, jp_changeSelect);


          if(field.hide) //Selectbox schovám, ak má byť skrytý 
            block.hide();
  			});
        if(window.jpSelectAdded && typeof window.jpSelectAdded == 'function')
          window.jpSelectAdded(wrapper);

  		}).fail(function() {
  			console.log('fail');
  		});
  	}
  	//Schovám teraz vybrané
  	//elementy označiť ako disabled
  	var visible = $('.jp-form-type:visible');
  	visible.hide();
  	visible.find('select').prop('disabled', true);


		//Zobrazím nové
		//elementy označiť ako enabled
  	wrapper.show();
  	wrapper.find('select').prop('disabled', false);

  }

  /**
   * Vlastná serialiácia.
   * Ak je selectbox, ktorý vyvolal .change def (hodnota prosím vyberte),
   * tak sa vymažú všetky selectboxy za ním nasledujúce
   */
  function customSerialization(selects, changed) {
    var serial = '';
    var stop = false;
    var counter = 1;
    selects.each(function(){
      //Ak je to ten, ktorý vyvolal zmenu a zároveň je to def. hodnota
      if($(this).val() === $(changed).val() && $(this).val() === '') {
        stop = true;
      }
      //Vymažú sa všety nasledujúce selecty
      if(stop) {
        $(this).val('');
      }

      //Formát výstupu
      if(counter > 1) {
        serial +='&';
      }
      serial += $(this).serialize();

      counter++;
    });

    return serial;
  }
}