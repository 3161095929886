const removeDiacritics = require('./remove-diacritics');

module.exports = function (str) {
  str = str.toLowerCase();
  str = removeDiacritics(str);
  str = str.removeSpaces();
//  console.log(str);
  return str;
};

String.prototype.removeSpaces = function() {
  let str = this.replace(/\s+/g, '');
  return str;
};
