module.exports = function vacationForm (opts) {

  var self = this;
  var destinations_data;
  var defaults = {
    dataPath: '/opencms/opencms/shared/wc/vacation-form/data.json',
    name: 'vacation-form',
    prefix: 'vacation-'
  };
  var createComponent = require('../component').create.bind(self);
  var renderForm = require('../utils/render-form');
  var onSubmit = require('./actions/submit');
  var printHeadline = require('../utils/print-headline');

  return createComponent({
    options: $.extend({}, defaults, opts),
    preRenderHook,
    render,
    postInitHook
  });

  function preRenderHook (component) {
    self.data('opts', component.options);
  }

  function render (component) {
    printHeadline(opts, this);
    component.renderField = require('./render-field')(self);
    //Pri submit zmením formát date na date=xx.xx.xxxx-xx.xx.xxxx
    renderForm(component, self, onSubmit);
  }

  function postInitHook (component, context) {

    initDates();

    dateChange();

    var destination = $('#destination', context);
    var locality = $('#locality', context);
    var options = component.options;

    $.getJSON(options.urls.destinations, (items) => {
      destinations_data = items.tree;
//    console.log(items.tree);
    $.each(destinations_data, function(k, country) { // pro kazdou zemi
        $('<option/>', {
          value: country.id,
          text: country.name
        }).appendTo(destination);
        
//        console.log(result);
 
    });
    
//      items.forEach((item) => {
//        if(item.id == -1)
//          return;
//
//        $('<option/>', {
//          value: item.id,
//          text: item.name
//        }).appendTo(destination);
//      });
    
      
    
    });




    destination.change(() => {
      locality.empty();
//      locality.val('nerozhoduje');

      
      $('<option/>', {
        text: 'nerozhoduje',
        value: ''
      }).appendTo(locality); // default
      
      
//      console.log(destination.val());
      if(destination.val())
      {
        var destinationParent = destinations_data["key_" + destination.val()];
        if ('children' in destinationParent) {
          var locality_data = destinationParent.children;
  //        console.log(locality_data);
          $.each(locality_data, function (key, one_locality) { // pro kazdou lokalitu v aktualni destinaci
            $('<option/>', {
              value: one_locality.id,
              text: one_locality.name
            }).appendTo(locality);
            if (jQuery.isPlainObject(one_locality.children)) {
              $.each(one_locality.children, function (sublocality_key, sublocality) { // pro kazdou lokalitu v aktualni destinaci
                $('<option/>', {
                  value:sublocality.id,
                  text: " - " + sublocality.name
                }).appendTo(locality);
              });
            }
          });
        }
      }

      $('#locality').prev('.select-text').html('nerozhoduje'); // oprava pro mobilni zarizeni

    });
//    destination.change(() => {
//      locality.empty();
//
//      $.getJSON(`${options.urls.localities}&destination=${destination.val()}`, (items) => {
//
//        $('<option/>', {
//          text: 'nerozhoduje',
//          value: ''
//        }).appendTo(locality);
//
//        items.forEach((item) => {
//          $('<option/>', {
//            value: item.id,
//            text: item.name
//          }).appendTo(locality);
//        });
//
//      });
//    });

  }

  //Inicializuje date polia. Obmedzenie pre dateTo - minimálne od dateFrom a max dateFrom + 100 days
  function initDates() {
    var dateFrom = $('#vacation-date_from-block #date_from').datepicker('getDate');
    var dateTo = $('#vacation-date_to-block #date_to');

    dateTo.datepicker('option', 'minDate', dateFrom);
    var time = dateFrom.getTime();
    var maxDate = new Date(time + 100*86400000);
    dateTo.datepicker('option', 'maxDate', maxDate)
  }

  function dateChange() {
    var dateFrom = $('#vacation-date_from-block #date_from');
    var dateTo = $('#vacation-date_to-block #date_to');
    dateFrom.datepicker('option', 'onSelect', function(dateText, inst) {
      //Set min date
      dateTo.datepicker('option', 'minDate', dateText);
      //Set max date
      var time = dateFrom.datepicker('getDate').getTime();
      var maxDate = new Date(time + 100*86400000);
      dateTo.datepicker('option', 'maxDate', maxDate);
    });
  }
};
