/**
 * Utils
 * obsahuje všetky potrebné premenné
 * a funkcie pre prácu s renderovaním prvkov, naviazaním súčasného stavu HTML do premenných a validáciou
 */

var self = module.exports = {
  /**
   * Inicializácia premenných na začiatku práce s formulárom,
   * pridanie ID formuláru
   * @param {Object} data   Dáta prijaté AJAX-ovým volaním zo servera
   */
  initialize(data, opts) {
    this.opts = opts;
    this.data = data;
    this.id = this.data.options.form_id;
    this.form.attr('id', this.id);
    this.form.addClass(this.data.options.form_class);
    this.formObjects = [];
  },

  /**
   * Naplnenie poľa objektov formulárových prvkov a ich vykreslenie
   */
  createForm() {
    self.createTypes(this.data.fields);
    self.renderObj(this.form);
  },

  /**
   * Vykreslenie šablóny do vnútra formulára
   */
  renderTemplate() {
    if(this.data.options.template !== null)
    $(this.form).html(this.data.options.template);

  },

  /**
   * Naplnenie poľa objektov formulárových prvkov podľa typu prvku sa vyberie správny objekt (súbor), na ktorom je potom volaná metóda create().
   * Jej návratova hodnota predstavuje konkrétny formulárový prvok a je pridaná do poľa týchto prvkov.
   * @param {Object} fields   objekt obsahujúci info o form. prvkoch
   */
  createTypes(fields) {
    //Reset poľa

    this.formObjects = [];
    fields.forEach((field) => {
      let elemType = {};
      switch (field.type) {
      case 'text':
        elemType = require('./elements/gift-text');
        break;
      case 'number':
        elemType = require('./elements/gift-text');
        break;
      case 'email':
        elemType = require('./elements/gift-email');
        break;
      case 'textarea':
        elemType = require('./elements/gift-textarea');
        break;
      case 'checkbox':
        elemType = require('./elements/gift-checkbox');
        break;
      case 'select':
        elemType = require('./elements/gift-select');
        break;
      case 'radio':
        elemType = require('./elements/gift-radio');
        break;
        /*case 'datepicker':
          elemType = require('./elements/gift-datepicker');
          break;*/
        /*case 'multipleCheckbox':
          elemType = require('./elements/gift-multiple-checkbox');
          break;*/
      case 'hidden':
        elemType = require('./elements/gift-hidden');
        break;
      case 'captcha':
        elemType = require('./elements/gift-captcha');
        break;
      case 'button':
        elemType = require('./elements/gift-button');
        break;
      case 'submit':
        elemType = require('./elements/gift-submit');
        break;
      }
      let elem = new elemType.create(field);
      this.formObjects.push(elem);
    });
  },

  /**
   * Vykreslenie prvkov do formulára
   * Ak má prvok špecifikované miesto v šablóne, vykreslí sa tam, inak je pripojený priamo do <form> elementu
   * @param {jQuery} form   formulár, do ktorého sú prvky vykreslené
   */
  renderObj(form) {
    this.formObjects.forEach((element) => {
      if(element.jqObject === null) {
        //var target {jQuery}
        var target;
        if(element.templateId) {
          target = form.find('#' + element.templateId);
          element.jqObject = element.render();
        } else {
          target = form;
          element.jqObject = element.render();
        }
        element.jqObject.appendTo(target);
      } else {
        var newElement = element.render();
        element.jqObject.replaceWith(newElement);
        element.jqObject = newElement;
      }
      if(typeof element.postRender === 'function')
        element.postRender();

    });
  },

  /**
   * Validácia formulárových prvkov
   * @return true   - ak sú všetky prvky validné
   * @return false  - ak aspoň jeden prvok validný nie je
   */
  validateObj() {
    var valid = true;
    this.formObjects.forEach((element) => {
      element.validate();

      //Element je skrytý, validácia je vždy true
      if(element.jqObject.hasClass('hide')){
        element.jqObject.removeClass('invalid');
        element.valid = true;
      }
      if(!element.valid){
        valid = false;
      }

    });

    return valid;
  },

  /**
   * Nastavenie Modelu (premenných) podľa View (HTML)
   */
  setByView() {
    this.formObjects.forEach((element) => {
      element.setByView();
    });
  }
}
