var element = require('./gift-element');

module.exports = {
  //Typ input textarea
  create: function(field){ 
    element.element(this, field);
    this.maxlength = field.maxlength;

    this.render = function() {
      var classAttr ='gf-textarea gift-gf-textarea' + this.id;
      if(!this.valid) 
        classAttr += ' invalid';
      
      classAttr += this.customClass ? ' ' + this.customClass : '';
      var textareaElement = $('<div/>', {
        'class': classAttr
      });

      element.renderLabel(textareaElement, this);

      element.renderAlert(textareaElement, this);

      var textarea = $('<textarea/>', {
        id: this.id,
        name: this.name,
        value: this.value,
        html: this.value,
        maxlength: this.maxlength
      }).appendTo(textareaElement);

      return textareaElement;
    };

    this.setByView = function() {
      this.value = this.jqObject.find('#' + this.id).val();
    };
  }
}
