module.exports = function letenkyczAirticketForm (opts) {
  var self = this;
  var defaults = {
    name: 'airticket-form',
    prefix: 'airticket-',
    dataPath: '/opencms/opencms/shared/wc/airticket-form-small/data.json',
    formType: 'round_trip',
    limits: {},
    params: {
      id: opts.id
    }
  };

  // var autofocus = require('./actions/autofocus')()
  // autofocus.init()

  var createComponent = require('../component').create.bind(self);
  var renderTag = require('../utils/render-tag');
  var renderForm = require('../utils/render-form');
  var qsToObject = require('../utils/qs-to-object');
  var routesStorage = require('../utils/local-storage')(self);

  return createComponent({
    options: $.extend({}, defaults, opts),
    preRenderHook,
    render,
    postInitHook
  });

  function preRenderHook (component) {
    self.data('opts', component.options);
    self.data('form', {
      defDest: true,
      defRange: true,
      airlines: [],
      errors: []
    });

    routesStorage.loadFromLocalStorage(`${component.options.id}-air`);
  }

  function render (component) {

    component.renderField = require('./render-field')(component, self);
    var form = renderForm(component, self, () => {
      // submit
      var i = 0;
      var airline = '';
      $('.air-select-wrapper select').each(function() {
        if (i++ > 0) {
          airline += ',';
        }
        airline += $(this).val();
      });

      $('#airline').val(airline.replace(/ali-/g, '/*'));

      //$('#airline').val($('#airline').val().replace(/ali-/g, '/*'));



      //Pred odoslaním odstránim počítadlám pasažierov disabled, aby sa aj tieto polia poslali
      $('input.passenger-count').attr('disabled', false);

      //WEB-3377 ak vyberie variantu 0 0 0 0, nastaví sa 1 0 0 0
      var sucet = 0;
      $('input.passenger-count').each(function() {
        sucet += parseInt($(this).val());
      });
      if(sucet === 0)
        $('input.passenger-count[name="passengers_adt"]').val(1);

      var obj = {};
      obj.from = $('[name=departure_destination_1]').val();
      obj.to = $('[name=arrival_destination_1]').val();

      routesStorage.saveToLocalStorage(`${component.options.id}-air`, obj);

      // ie fix - checkbox checked
      var onlyDirectFlightsCheckbox = $('input[name=only_direct_flights]');
      var isChecked = onlyDirectFlightsCheckbox.is(':checked');
      if(isChecked)
        onlyDirectFlightsCheckbox.val('true');
      else
        onlyDirectFlightsCheckbox.val('false');

    });

    renderTag.input('hidden', 'lang', component.options.lang).prependTo(form);
    renderTag.input('hidden', 'client_encoding', component.options.client_encoding).prependTo(form);
    renderTag.input('hidden', 'id_dealer', component.options.id_dealer).prependTo(form);


    $('#airticket-airline-block').find('.air-select-wrapper:hidden').each(function() {
      $(this).remove();
    });

    technicalService(component.options)

    function technicalService(opts) {
      if(typeof opts.technicalServices !== 'undefined' && opts.technicalServices.state) {
        const template = `
        <div class="${defaults.name}-issue-overlay">
          <strong>${opts.technicalServices.text}</strong>
        </div>`

        $(template).appendTo(`.${defaults.name}`)
      }
    }
  }

  function postInitHook (component, context) {

    var formActions = require('./actions/form')(self);
    formActions.setFormType(component.options.formType);
    formActions.setValuesFromQuery(qsToObject(window.location.search));
    formActions.handleInterval();
    var passengerLogic = require('./actions/passengers');
    passengerLogic(component.options.limits);
  }
};
