var element = require('./element');

module.exports = {
  //Typ input email

  create: function(field) {
    element.create(this, field);
    this.placeholder = field.placeholder;

    this.render = function() {
      var classAttr ='gf-email';
      if(field.classBlock)
        classAttr += ` ${field.classBlock}`;
      if(!this.valid) 
        classAttr += ' invalid';
      
      classAttr += this.customClass ? ' ' + this.customClass : '';
      var emailElement = $('<div/>', {
        'class': classAttr
      });

      element.renderLabel(emailElement, this);

      element.renderAlert(emailElement, this);

      var input = $('<input/>', {
        id: this.id,
        'type': this.type,
        name: this.name,
        value: this.value,
        placeholder: this.placeholder
      }).appendTo(emailElement);

      return emailElement;
    };

    this.validate = function() {
      var res = true;
      if(this.required && !this.value) {
        res = false;
        this.required_valid = false;
      }
      //Kontrolujem na regexp len ak je daco zadane
      if(this.value && this.regexp) {
        var pattern = new RegExp(this.regexp, 'gi');
        if(!pattern.test(this.value)) {
          res = false;
          this.regexp_valid = false;
        }
      }

      this.valid = res;
    };

    this.setByView = function() {
      this.value = this.jqObject.find('#' + this.id).val();
    };
  }
}
