var element = require('./element');

module.exports = {
  //Typ input text
  create: function(field) {
    element.create(this, field);

    this.render = function() {
      var classAttr ='gf-button';
      if(field.classBlock)
        classAttr += ` ${field.classBlock}`;
      var buttonElement = $('<div/>', {
        'class': classAttr
      });
      classAttr = this.customClass;

      var button = $('<button/>', {
        id: this.id,
        type: this.type,
        text: this.value,
        class: classAttr
      }).appendTo(buttonElement);

      return buttonElement;
    };

    this.setByView = function() {
    };
  }
}
