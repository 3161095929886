module.exports = function (context) {
  var ret = {};
  
  //Takéto vracanie kvôli pomlčke vo viacslovnom názve form-fields
  ret['form-fields'] = require('./form-fields');
  ret['info'] = require('./fields/info');
  ret['questions'] = require('./fields/questions');
  ret['school'] = require('./fields/school');
  ret['contact'] = require('./fields/contact');

  return ret;
};

