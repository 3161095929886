/**
 * Funkcie na vykeslenie formulárových prvkov
 */
var self = module.exports = {

  /**
   * Vykreslenie textového poľa
   * @param  {String} name      Meno textového poľa
   * @param  {String} value     Hodnota
   * @param  {String} className Voliteľná trieda elementu
   */
  text (name, value, className) {
    className = className || '';
    var opts = {
      id: name,
      'class': className,
      'type': 'text',
      name,
      value
    };

    return $('<input/>', opts);
  },

  /**
   * Vykreslenie textarea elementu
   * @param  {String} name      Meno elementu
   * @param  {String} className Voliteľná trieda elementu
   */
  textarea (name, className) {
    className = className || '';
    var opts = {
      id: name,
      'class': className,
      name
    };

    return $('<textarea/>', opts);
  },

  /**
   * Vykreslenie selectu
   * @param  {String} name      Meno selectu
   * @param  {String} className Voliteľná trieda elementu
   * @param  {Object} options   Objekt obsahujúci info o jednotlivých hodnotách
   */
  select (name, className, options) {
    className = className || '';
    var opts = {
      id: name,
      'class': className,
      name
    };
    var select = $('<select/>', opts);

    options.forEach(function (option) {
      $('<option/>', {
        value: option.value,
        text: option.value,
        selected: option.selected
      }).appendTo(select);
    });

    return select;
  },

  /**
   * Vykreslenie checkboxu
   * @param  {String} name      Meno checkboxu
   * @param  {String} value     Hodnota
   * @param  {String} className Voliteľná trieda elementu
   * @param  {Boolean} checked  Ak je true, checkbox sa označí
   */
  checkbox (name, value, className, checked) {
    className = className || '';
    var opts = {
      id: name,
      'class': className,
      'type': 'checkbox',
      name,
      value: value,
      checked: checked || false
    };

    return $('<input/>', opts);
  },

  /**
   * Vykreslenie skupiny radio buttonov
   * @param  {String} name      Meno formulárového elementu
   * @param  {String} className Voliteľná trieda elementu
   * @param  {Object} items     Objekt obsahujúci info o jednotlivých radio buttonoch
   */
  radio (name, className, items) {
    className = className || '';
    var radioBlock = $('<div/>', {
      id: name,
      'class': className
    });
    items.forEach((item) => {
      var id = name + '_' + item.value.replace(/ /g,'').substr(item.value.length - 4);
      self.singleRadio(name, item.value, '', id, item.checked).appendTo(radioBlock);
      self.label(id, item.label).appendTo(radioBlock);
    });

    return radioBlock;
  },


  /**
   * Vykreslenie radio buttonu
   * @param  {String} name      Meno radio buttonu
   * @param  {String} value     Hodnota
   * @param  {String} className Voliteľná trieda elementu
   * @param  {String} id        Voliteľné id
   * @param  {Boolean} checked  Ak je true, radio sa označí
   */
  singleRadio(name, value, className, id, checked) {
    className = className || '';
    var opts = {
      id,
      'class': className,
      'type': 'radio',
      name,
      value,
      checked: checked
    };

    return $('<input/>', opts);
  },

  /**
   * Vykreslenie multiple checkboxu - s viacerými zaškrtávacími políčkami
   */
  multipleCheckbox(name, className, items) {
    className = className || '';
    var checkboxBlock = $('<div/>', {
      'class': className,
      id: name
    });
    items.forEach((item) => {
      self.checkbox(item.value, item.value, item.value, item.checked).appendTo(checkboxBlock);
      self.label(item.name, item.name).appendTo(checkboxBlock);
    });

    return checkboxBlock;
  },

  /**
   * Vykreslenie multiple elementu.
   * tj. elementu ktorý obsahuje v sebe viac formulárových elementov.
   * napr 2 selecty hodiny + minúty
   */
  multiple(name, className, items) {
    className = className || '';
    var multipleBlock = $('<div/>', {
      id: name,
      'class': className + ' sub-fields-block'
    });
    items.forEach((item) => {
      var itemBlock = self.div('', `${item.id}-block`);
      var label = null;
      if(item.label) {
        label = self.label(item.id, item.label);
        label.appendTo(itemBlock);
      }
      if(item.required) {
        if(label){
          label.append(' *');
        }
        itemBlock.attr('data-required', 'true');
        itemBlock.attr('data-type', item.type);
      }
      switch(item.type) {
      case 'text':
        self.text(item.id, item.value, 'validate-element').appendTo(itemBlock);
        break;
      case 'textarea':
        self.textarea(item.id, 'validate-element').appendTo(itemBlock);
        break;
      case 'select':
        self.select(item.id, 'validate-element', item.options).appendTo(itemBlock);
        break;
      case 'checkbox':
        self.checkbox(item.id, item.value, 'validate-element').appendTo(itemBlock);
        break;
      case 'multiple':
        self.multiple(item.id, 'validate-element', item.items).appendTo(itemBlock);
        break;
      }
      itemBlock.appendTo(multipleBlock);
    });

    return multipleBlock;
  },

  /**
   * Vykreslení labelu
   * @param  {String} name Jméno pole, ke kterému je label vázaný
   * @param  {String} html Obsah labelu, může obsahovat i html tagy
   * @return {Object}      jQuery element
   */
  label (name, html, className) {

    var opts = {
      'class': className ? `control-label ${className}` :  'control-label',
      'for': name || '',
      html
    };

    return $('<label/>', opts);
  },

  /**
   * Vykreslení divu
   * @param  {String} html      HTML obsah divu
   * @param  {String} className CSS třída divu
   * @param  {String} id        atribut id divu
   * @return {Object}           jQuery element
   */
  div (html, className, id) {

    var opts = {
      'class': className,
      html,
      id
    };

    return $('<div/>', opts);
  },
};