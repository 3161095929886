module.exports = function ybusReservationForm (opts) {


  var self = this;
  var defaults = {
    dataPath: '/opencms/opencms/shared/wc/ybus-form/data.json',
    name: 'ybus-form',
    prefix: 'ybus-',
    params: {
      id: opts.id
    }
  };
  var createComponent = require('../component').create.bind(self);
  var renderForm = require('../utils/render-form');
  var onSubmit = require('./actions/submit');
  var printHeadline = require('../utils/print-headline');

  return createComponent({
    options: $.extend({}, defaults, opts),
    preRenderHook,
    render,
    postInitHook
  });

  function preRenderHook (component) {
    self.data('opts', component.options);
    self.data('opts').currentTariffs = [];

    var routesStorage = require('./actions/routes-storage')(self);
    routesStorage.loadRoutes();
  }

  function render (component) {
    if(component.options.hideStations)
      window.hideStations = component.options.hideStations;


    let defFromURL = getIdValue(window.location.search),
      def = []
    if(typeof opts.def != 'undefined' && opts.def != null)
      def = def.concat(opts.def)
    if(typeof defFromURL != 'undefined' && defFromURL != null)
      def = def.concat(defFromURL)

    setDefFromOpts(component.data, def)

    printHeadline(opts, this);
    component.renderField = require('./render-field')(self);
    renderForm(component, self, onSubmit);
  }
  function getIdValue(s) {
    let paramsString = s.split('?')[1]

    if(typeof paramsString == 'undefined')
      return null

    let params = paramsString.split('&'),
      pairs  = params.map(function(param) {
        let pair = param.split('=')
        return {
          id: pair[0].split('form_')[1],
          value: pair[1]
        }
      })
    return pairs
  }

  // Nastaví deaultné hodnoty pre fieldy z opts podľa idčiek
  function setDefFromOpts(data, def) {

    if(def.length <= 0)
      return

    data.map(pieceOfData => {
      // Nájdem defaultnú hodnotu podľa idčka
      let defValue = def.filter(pieceOfDef => pieceOfData.id == pieceOfDef.id)[0]
      // Ak existuje, nastavím ju
      if(typeof defValue !== 'undefined')
        pieceOfData.def = defValue.value
    })
  }

  function postInitHook () {
    // Počet tarifov
    let tarifNum = 1
    let tarifVal = [];
    if(typeof opts.def !== 'undefined' && opts.def != null) {
      let tarifTmp = opts.def.filter(def => def.id == 'tariffs')[0]
      if(typeof tarifTmp !== 'undefined') {
        tarifNum = tarifTmp.value.length;
        tarifVal = tarifTmp.value;

        for (var i = 0; i < tarifNum; i++)
          $('#tariff-' + i).val(tarifVal[i]);

      }
    }
    var modifyTariffs = require('./actions/modify-tariffs');
    modifyTariffs.modify('.tariff-block', tarifNum);

    // if ($('input[name=trip_type]:checked').val() !== 'round_trip' && $('input[name=trip_type]:checked').val() !== 'round_trip_ybus')
    //   $('input[name=arrival_date]').attr('disabled', true).next().addClass('disabled');
    if (self.data('opts').routes.length === 0)
      $('#saved_routes').hide();

    if(window.ybusPostRenderFunction && typeof window.ybusPostRenderFunction === 'function')
      window.ybusPostRenderFunction(self);

    /*if(window.globalPostRenderFunction && typeof window.globalPostRenderFunction === 'function')
      window.globalPostRenderFunction(opts.name || defaults.name);*/

    //WEB-3381 - dátum príjazdu nesmie byť pred dátumom odjazdu
    var departurePicker = $('#ybus-departure_date-block .date_picker');
    var arrivalPicker = $('#ybus-arrival_date-block .date_picker');

    // departurePicker.datepicker('option', 'onSelect', function() {
    //
    //   var departureDate = departurePicker.datepicker('getDate');
    //
    //   if(departureDate > arrivalPicker.datepicker('getDate'))
    //     arrivalPicker.datepicker('setDate', departureDate);
    //
    //   arrivalPicker.datepicker('option', 'minDate', departureDate);
    // });
  }
};
