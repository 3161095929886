module.exports = function (component) {

  var data = component.data;
  var options = component.options;
  var helpers = require('./helpers');
  var qsToObject = require('../utils/qs-to-object');

  if (!component.data.initialized) {
    component.data.sortedSets = helpers.createSets(data, options);
    component.data.prices = helpers.getPrices(data);
    component.options.currency = data[0].currency;
    component.data.destinations = helpers.getDestinations(data);
    component.data.filter = {
      airline: '',
      from: '',
      to: '',
      country: '',
      minPrice: Math.min.apply(Math, component.data.prices),
      maxPrice: Math.max.apply(Math, component.data.prices)
    };
    component.data.order = {
      type: 'val',
      key: 'total',
      dir: 'desc'
    };
  }

  $.extend(component.data.filter, options.filter, qsToObject(window.location.search));
  component.data.initialized = true;
};
