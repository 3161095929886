module.exports = function carRentFormBooking (lang) {
  /**
   * Metoda pro vykresleni formulare pronajmu aut od booking.com
   * Vykresli jen div a prida jejich script pro vykreslovani. (Nutna proxy)
   */

  var self = this;
  var renderTag = require('../utils/render-tag');

  function render () {
    renderTag.div('', '', 'rc-connect-container').appendTo($(self));

    // pre slovenskou verzi
    if(lang == 'sk') {
      self.append('<iframe width="100%" scrolling="no" allowtransparency="true" frameborder="0" id="rc-connect-iframe" src="https://studentagency.rentalcars.com/partners/integrations/stand-alone-app/?cor=sk&preflang=sk&integration=stand-alone&enable-return-checkbox=true&css=https://secure.rentalcars.com/partners/integrations/stand-alone-app/import/studentagency_rc/styles.css&amp;affiliateCode=studentagency_sk&amp;fts=true&amp;adplat=hp_tab&amp;adcamp=standalone&amp;return-checkbox-enabled=true" height="335"></iframe>');
    }
    // defaultně
    else {
      self.append('<iframe width="100%" scrolling="no" allowtransparency="true" frameborder="0" id="rc-connect-iframe" src="https://studentagency.rentalcars.com/partners/integrations/stand-alone-app/?cor=cz&preflang=cs&integration=stand-alone&enable-return-checkbox=true&affiliateCode=studentagency_rc&primary=ffc119&hide-header=true&button-radius=6&css=true&form-padding=1&adplat=carrentalpage&adcamp=standalone&fts=true&affUrl=studentagency.rentalcars.com&return-checkbox-enabled=true" height="335"></iframe>');
    }
  };

  render();
};
