module.exports = function (context) {
  var store = localStorage;




  if ($('body').hasClass('homepage-new') == true) {//pouze pokud jsme na nove adrese
    return {
      saveRoute(from, to, from_id, to_id,fromLocationType, toLocationType) {
        let ObjectLastConnection = [];
        if (!checkIfRouteExists() || !isLocalStorageNameSupported())
          return
      
        // kontrolujeme, zda vsechna data jsou validni
        function checkIfRouteExists() {
          let state = false
          if (
            from.length !== 0 &&
            to.length !== 0 &&
            from_id.length !== 0 &&
            to_id.length !== 0 &&
            fromLocationType !== 0 &&
            toLocationType !== 0
          )
            state = true
          return state
        }
      
        // kontrola, zda je ukladani do localStorage vubec mozne
        // (napr. Safari, iOS anonymni rezim - v tom to nefunguje a formular se pote neda odeslast)
        function isLocalStorageNameSupported() {
          let testKey = 'test', storage = window.localStorage;
          try {
            storage.setItem(testKey, '1');
            storage.removeItem(testKey);
            return true;
          } catch (error) {
            return false;
          }
        }
      
        let searchLength = store.getItem('new_search_length') || 0;
        let obj = {};
        obj.from = { id: from_id, type: fromLocationType };
        obj.to = { id: to_id, type: toLocationType };
        ObjectLastConnection.push(obj);
      
        // WEB-3415 : neukladame duplicitni trasy
        var i = 0;
        var sameRouteCheck = true;
      
        // duplicitni trasy kontrolujeme jen z peti nejnoveji vyhledanych spoju
        if (searchLength >= 5)
          i = searchLength - 5;
      
        for (i; i < searchLength; i++) {
          var item = JSON.parse(store.getItem('new_destination_' + i));
      
          sameRouteCheck = ((item.to.id !== to_id) || (item.from.id !== from_id));
      
          if (sameRouteCheck === false)
            return;
        }
      
        if (sameRouteCheck) {
          store.setItem(`new_destination_${searchLength}`, JSON.stringify(obj));
          store.setItem('new_search_length', parseInt(searchLength) + 1);
        }
      
      },
      loadRoutes(){
        var searchLength = store.getItem('new_search_length') || 0;
        var i = 0;
      
        context.data('opts').routes = [];
      
        while (i < searchLength) {
          let item = store.getItem(`new_destination_${i}`)
          if (item) {
            if (JSON.parse(item).from.id.length !== 0 && JSON.parse(item).to.id.length !== 0)
              context.data('opts').routes.unshift(JSON.parse(item));
          }
          i += 1;
        }
      }
    }
  }
  //############ OLD ########################################################################################################
  else {
    
    return {
      /**
       * Uložení cesty do local storage
       * @param  {String} from hodnota pole destination_from
       * @param  {String} to   hodnota pole destination_to
       * @return {void}
       */
      saveRoute(from, to, from_id, to_id) {
        if (!checkIfRouteExists() || !isLocalStorageNameSupported())
          return

        // kontrolujeme, zda vsechna data jsou validni
        function checkIfRouteExists() {
          let state = false
          if (
            from.length !== 0 &&
            to.length !== 0 &&
            from_id.length !== 0 &&
            to_id.length !== 0
          )
            state = true

          return state
        }

        // kontrola, zda je ukladani do localStorage vubec mozne
        // (napr. Safari, iOS anonymni rezim - v tom to nefunguje a formular se pote neda odeslast)
        function isLocalStorageNameSupported() {
          var testKey = 'test', storage = window.localStorage;
          try {
            storage.setItem(testKey, '1');
            storage.removeItem(testKey);
            return true;
          } catch (error) {
            return false;
          }
        }
        //localStorage.clear()
        var searchLength = store.getItem('search_length') || 0;
        var obj = {}
        obj.from = from;
        obj.from_id = from_id;
        obj.to = to;
        obj.to_id = to_id;

        // WEB-3415 : neukladame duplicitni trasy
        var i = 0
        var sameRouteCheck = true;

        // duplicitni trasy kontrolujeme jen z peti nejnoveji vyhledanych spoju
        if (searchLength >= 5)
          i = searchLength - 5

        for (i; i < searchLength; i++) {
          var item = JSON.parse(store.getItem('destination_' + i));

          sameRouteCheck = ((item.to_id !== to_id) || (item.from_id !== from_id));

          if (sameRouteCheck === false)
            return;
        }

        if (sameRouteCheck) {
          store.setItem(`destination_${searchLength}`, JSON.stringify(obj));
          store.setItem('search_length', parseInt(searchLength) + 1);
        }
      },
      /**
       * Načtení cest z local storage při inicializaci formuláře
       * @return {void}
       */
      loadRoutes() {
        var searchLength = store.getItem('search_length') || 0;
        var i = 0;

        context.data('opts').routes = [];

        while (i < searchLength) {
          let item = store.getItem(`destination_${i}`)

          if (item) {
            if (JSON.parse(item).from_id.length !== 0 && JSON.parse(item).to_id.length !== 0)
              context.data('opts').routes.unshift(JSON.parse(item));
          }
          // zakomentoval jsem, delalo to neplechu
          // else {
          //   store.clear(); // smaze stary format
          // }
          i += 1;
        }
      }
    }
  };




};
