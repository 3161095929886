/**
 * General form - všeobecný formulár
 * ukážka vytvorenia nového typu prvku je v súbore ./docs/new-type.js
 * ukážkový JSON s použitím doteraz vytvorených prvkov je v ./docs/exampleJson.json
 */

//Premenná obsahujúce dáta a funkcie pre prácu s formulárom a jeho prvkami
var utils = require('./gift-utils');

module.exports = function generalForms (opts) {

  utils.form = $('<form/>', {
    'class': opts.noFormClass ? '' : 'gf-form'
  }).appendTo($(this));

  //Načítanie dát cez Ajax
  $.getJSON(opts.dataPath)
    .done(function(res) {

      //Inicializácia premenných v utils
      //Ak bola poslaná šablóna, jej vykreslednie do DOM-u
      //Vytvorenie formulára (všetkých prvkov)
      utils.initialize(res, opts);
      utils.renderTemplate();
      utils.createForm();

      if(!scrollToFirstInvalid() && utils.opts.scrollToForm)
        scrollToForm();


      //Submit funkcia
      utils.form.on('click', '#' + res.options.submit_id, function(e) {
        e.preventDefault();
        submitForm();
      });

      //Volanie custom postRender funkcie, ak existuje vo window objekte
      postRenderFunction();
    })
    .fail(function( jqxhr, textStatus, error ) {
      var err = textStatus + ', ' + error;
      console.log( 'Request Failed: ' + err );
    });


};

function submitForm() {
  //Nastavenie premenných podľa aktuálneho stavu HTML
  utils.setByView();
  //console.log('set by view');

  //Ak sú všetky povinné/regexp polia validné, nasleduje
  //serializácia a požiadavka na server
  if(utils.validateObj()) {
    //console.log('after validate');
    //Odstránenie iframu, kvôli kolízií http/https

    var serial = serialize();

    //console.log('serial',serial);
    //console.log('utils.data.options.urls.submit',utils.data.options.urls.submit);

    $.getJSON(utils.data.options.urls.submit, serial)
      .done(function(res) {
        afterResponseFunction(res);
        utils.data = res;

        //			console.log('zmena na switch');

        //Špecifické pre SA.cz
        switch(res.type) {
        //Ak je odpoveď typu form, formulár sa nanovo vykresľuje,
        //inak sa vytvorí Lightbox
        case 'form': {
          utils.form.empty();
          utils.renderTemplate();
          utils.createForm();

          //Volanie custom postRender funkcie, ak existuje vo window objekte
          postRenderFunction();
          break;
        }
        case 'redirect': {
          if(res.url)
            window.location = res.url;
          return;
          break;
        }
        default: {
          var ico	= 'sa-icon';
          if(res.type === 'success')
            ico += ' icon-upsesne';
					 else if(res.type === 'error')
            ico += ' icon-neuspesne';

          var opts = {
            'type': res.type,
            'message': {
              'icon': ico,
              'title': res.title,
              'content': res.message,
              'close': 'Zavřít'
            }
          };

          var lb = $('.gf-form').lightbox;
          lb.create(null, 'icon-lightbox', window.customRenderFunction, opts).hideX().closeOnBackground().onClose(function() {
            location.reload();
          });
        }
        }
      })
      .fail(function() {
        afterResponseFunction(null);
        //console.log('Failed');
      });
    afterSubmitFunction();
  }
  //Validácia neprešla - formulár sa znova vykreslí
  else {
    utils.renderObj();
    //Volanie custom postRender funkcie, ak existuje vo window objekte
    postRenderFunction();
    if(!scrollToFirstInvalid() && utils.opts.scrollToForm)
      scrollToForm();

  }
}

//Možnosť vlastnej serializácie ak existuje funkcia giftGeneralFormCustomSerialize, a ak je to pre ID tohoto formulára
function serialize() {
  if(window.giftGeneralFormCustomSerialize && window.giftGeneralFormId && typeof window.giftGeneralFormCustomSerialize == 'function' && window.giftGeneralFormId == utils.id)
    return window.giftGeneralFormCustomSerialize();
  //General
  if(window.generalFormCustomSerialize && window.generalFormId && typeof window.generalFormCustomSerialize == 'function' && window.generalFormId == utils.id)
    return window.generalFormCustomSerialize();

  return $(utils.form).serialize();
}

function afterSubmitFunction() {
  //Volanie custom afterSubmit funkcie, ak existuje vo window objekte
  if(window.giftGeneralFormAfterSubmitFunction && typeof window.giftGeneralFormAfterSubmitFunction == 'function')
    window.giftGeneralFormAfterSubmitFunction();

  //General
  if(window.generalFormAfterSubmitFunction && typeof window.generalFormAfterSubmitFunction == 'function')
    window.generalFormAfterSubmitFunction();

}
function afterResponseFunction(res) {
  //Volanie custom afterResponse funkcie, ak existuje vo window objekte
  if(window.giftGeneralFormAfterResponseFunction && typeof window.giftGeneralFormAfterResponseFunction == 'function')
    window.giftGeneralFormAfterResponseFunction(res);

  //General
  if(window.generalFormAfterResponseFunction && typeof window.generalFormAfterResponseFunction == 'function')
    window.generalFormAfterResponseFunction(res);

}

function postRenderFunction() {
  //Volanie custom postRender funkcie, ak existuje vo window objekte.
  //Len pre gift General Form
  if(window.giftGeneralFormPostRenderFunction && typeof window.giftGeneralFormPostRenderFunction == 'function')
    window.giftGeneralFormPostRenderFunction();

  //Pre obyčajný General Form
  if(window.generalFormPostRenderFunction && typeof window.generalFormPostRenderFunction == 'function')
    window.generalFormPostRenderFunction();

}

//Scroll na prvý zle vyplnený prvok
function scrollToFirstInvalid() {
  var invalid = $('.invalid');
  if(invalid.length){
    var total = 0;
    var header = $('#header');
    if(header.length)
      header.children().each(function() {
        total += $(this).outerHeight(true);
      });

    $('html,body').animate({
      scrollTop: invalid.first().offset().top - total
    }, 'slow');
    return true;
  }
  return false;
}

//Scroll po vykreslení formulára. Ak sú všetky elementy valid, tak sa scrollne na začiatok fomulára
function scrollToForm() {
  var total = 0;
  var header = $('#header');
  if(header.length)
    header.children().each(function() {
      total += $(this).outerHeight(true);
    });

  $('html,body').animate({
    scrollTop: utils.form.offset().top - total
  }, 'slow');
}
