/**
 * Odeslání formuláře
 * @param  {Object} e událost
 * @return {void}
 */
module.exports = function (e) {

  e.preventDefault();

  var success = true;
  var context = $(this).parent(); // element na kterém byla komponenta incializována

  $(context).find('[data-required]').each(function() {
    var input = $(this).find('input');
    var val = input.val();

    switch(input.attr('type')) {
      case 'text':
        if (val === '')
          createAlert($(this));
        else
          removeAlert($(this));
        break;
      case 'email':
        if (!validateEmail(val))
          createAlert($(this));
        else
          removeAlert($(this));
        break;
    }
  });

  if (success) {
    $.ajax({
       url: context.data('opts').urls.submit,
       data: $( this ).serialize(),
       dataType: 'json'
    }).done(function(res) {

      //Vytvoreni lightboxu po uspesnem odeslani formulare
      var ico = 'icon-fajfka';
      if (res.type === 'error')
        ico = 'icon-krizek';

      var opts = {
        'type': res.type,
        'message': {
          'icon': 'sa-icon ' + ico,
          'title': res.title,
          'content': res.message,
          'close': 'Zavřít'
        }
      };

      var lb = $('.ybus-form').lightbox;
      lb.create(null, 'icon-lightbox', window.customRenderFunction, opts).hideX().closeOnBackground();

      // reset formulare
      $('.ybus-form').trigger('reset');
    });

  }

  // vytvori chybovou hlasku (text a elementy)
  function createAlert(el) {
    success = false;

    el.addClass('invalid');

    var alertIcon = $('<div/>', {
      'class': 'alert-icon'
    }).appendTo(el);

    var alertMsgs = $('<div/>', {
      'class': 'alert-msgs'
    }).appendTo(alertIcon);

    $('<span/>', {
      'text': el.attr('data-required')
    }).appendTo(alertMsgs);
  }

  // odstrani chybovou hlasku
  function removeAlert(el) {
    el.removeClass('invalid');
    el.find('.alert-icon').remove();
  }

  // email regex
  function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
};