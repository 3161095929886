module.exports = function accomodationFormBooking (lang) {
  /**
   * Metoda pro vykresleni formulare ubytovani od booking.com
   * Vykresli jen div a prida jejich script pro vykreslovani.
   */

  var self = this;
  var renderTag = require('../utils/render-tag');

  function render () {
    renderTag.div('', '', 'b_container').appendTo($(self));

        // pre slovenskou verzi
    if(lang == 'sk') {
      self.append(`<script type = 'text/javascript' id='sp_widget' data-hash='_15cac8d28d80' data-container='b_container' data-size='100%x284' data-tpncy='false' src='https://aff.bstatic.com/static/affiliate_base/js/booking_sp_widget.js'></script>`);
    }
    // defaultně
    else {
      self.append(`<script type = 'text/javascript' id='sp_widget' data-hash='_15cac8d28d80' data-container='b_container' data-size='100%x284' data-tpncy='false' src='https://aff.bstatic.com/static/affiliate_base/js/booking_sp_widget.js'></script>`);
    }
  }
  render();

};
