//var renderTag = require('../../utils/render-tag');
var tagRenderer = require('../../utils/tag-render');

module.exports = function (field, block) {

  field.fields.forEach((item) => {
    var itemBlock = tagRenderer.div('', `${item.id}-block form-element`);
    var label = tagRenderer.label(item.id, item.label);
    if(item.required) {
      label.append(' *');
      itemBlock.attr('data-required', 'true');
    }
    label.appendTo(itemBlock);
    switch(item.type){
      case 'textarea':
        tagRenderer.textarea(item.id, 'validate-element').appendTo(itemBlock);
      break;
      case 'text':
        tagRenderer.text(item.id, item.value, 'validate-element').appendTo(itemBlock);
      break;
      case 'email':
        tagRenderer.email(item.id, item.value, 'validate-element').appendTo(itemBlock);
      break;
    }
    /*if(item.type === 'textarea') {
      renderTag.textarea(item.id).appendTo(itemBlock);
    }else {
      renderTag.input(item.type, item.id, item.value).appendTo(itemBlock);
    }*/
    itemBlock.appendTo(block);
  });

  return block;

};