module.exports = function (e) {

  e.preventDefault();

  var self = this;
  var params = $('select, input', self).not('#childrens_count, #child_age-block select').serialize();
  var count = $('select[name=childrens_count]', self).val();

  //params += `&location=${$('#location').data('submit-value')}`;

  if (count > 0) {

    params += '&children_ages=';
    var children = [];

    for (var i = 1; i <= count; i++)
      children.push($(`#achild${i}`, self).val());

    params += children.join(';');

  }

  window.location.href = $(self).parent().data('opts').urls.submit + '?' + params;
  return false;

};