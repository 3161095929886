var letterizeData = require('../letterize-data');
var renderTag = require('../render-tag');
var modal = require('../modal');
var printFormatted = require('../print-formatted');
var orderDestinations = require('../order-destination');

/**
 * Funkce která vrátí objekt s metodami pro obsluhu pole typu destination
 * @param  {object} options           nastavení
 * @param  {string} options.detail    určuje jaký se má zobrazit detail při zvolení destinace / co bude v našeptávači (airport || city)
 * @param  {string} options.template  formát, který určí jak zobrazovat možnosti v našeptávači fornma {}
 * @param  {object} context           jQuery element
 * @return {object}                   objekt obashující metody pro obsluhu bole
 */
module.exports = function (options, context) {

  var destObject = null;

  return {
    refreshDestinations,
    showDestinationDetail,
    showDestinations,
    getWhispers,
    setDestObject,
    getDestObject,
    isSetDestObject
  };

  function setDestObject(data) {
    destObject = data;
  }
  function getDestObject() {
    return destObject;
  }
  //zatiaľ nepodporované
  function isSetDestObject(data) {
    return false;
  }

  function showDestinations (e) {

    var fieldId = $(e.target).prev().attr('id');

    $.ajax({
      type: 'GET',
      url: context.data('opts').urls.states,
      success (data) {
        // na olymp7 se pouziva states
        var dest = data.destinations || data.states || data;

        dest.sort(nameComparator);

        var block = renderTag.div('', 'destinations-modal-block');

        var destFilter = renderTag.div('', 'destinations-modal-filter')/*.appendTo(block);*/

        if(context.data('opts').destination_picker_filter) {
          console.log('show destination_picker_filter');
          destFilter.appendTo(block);
        }

        let placeholder;
        var bodyClass = $('body').attr('class')
        var lang = typeof bodyClass !== 'undefined' ? bodyClass.split(' ')[0] : 'no class';

        switch(lang) {
          case 'en':
            placeholder = 'Search state';
            break;
          case 'de':
            placeholder = 'Suche Staats';
            break;
          default:
            placeholder = 'Vyhledat stát';
        }

        var filter = $('<input />', {
          'type': 'text',
          'placeholder' : placeholder
        }).appendTo(destFilter);

        var timer = setTimeout(() => {
          //Setting focus
          filter.focus();
        }, 250);

        var destBlock = renderTag.div('', 'destinations-block').appendTo(block);

        var jqDest = [];

        filter.keyup((event) => {
          handleScroll(event.target.value, jqDest, destBlock);
        });

        var destinations = renderTag.div('', 'destinations').data('input', `#${fieldId}`).appendTo(destBlock);

        if(context.data('opts').destination_picker_filter) {
          destinations.addClass('filter');
        }

        var list = $('<ul/>', {
          'class': 'states'
        }).appendTo(destinations);
        dest.forEach((d, i) => {
          d.counter = i;
          var item = $('<li/>', {
            'class': 'state',
            'data-statecode': d.value,
            'data-counter': i,
            'data-name': d.name,
            'id': 'modal_state_' + d.value
          }).appendTo(list);
          var link = $('<a/>', {
            'class': 'dest-link',
            'html': d.name
          }).appendTo(item);

          item.click(function() {
            showStateDetail(d.value, this);
          });

          jqDest.push(item);
        });

        modal.open({
          id: 'destinations-modal',
          'class': 'filterable-modal',
          title: `${data.headline}`,
          //content: destinations
          content: block
        });

      }
    });
  }

  function handleScroll(query, jqDest, destBlock) {
    if(query.length <= 0) {
      jqDest.forEach((dest) => {
        dest.show();
      });
    } else {
      jqDest.forEach((dest) => {
        if(dest.data('name').toLowerCase().indexOf(query.toLowerCase()) == 0) {
          dest.show();
        } else {
          dest.hide();
        }
      });
    }


    // jqDest.every((dest) => {
    //   if(dest.data('name').toLowerCase().indexOf(query.toLowerCase()) == 0) {
    //     destBlock.scrollTop(dest.data('counter') * 27 /* vyska itemu */);
    //     dest.addClass('active');
    //     return false;
    //   }
    //   return true;
    // });

  }

  function showStateDetail(stateCode, elem) {
    $.getJSON(context.data('opts').urls['airport'] + stateCode)
    .done(function(data) {

      //renderCities(data).appendTo(elem);
      // renderCities(stateCode, data).insertAfter(elem);
      renderCities(stateCode, data).appendTo(elem);

      //Keď už sú štáty načítané, klikom ich budem len schovávať/zbrazovať - toggle
      $(elem).unbind('click');
      $(elem).find('.dest-link').click(function() {
        // $(elem).next('.cities').toggle();
        $(elem).find('.cities').toggle();
      });

    })
    .fail(function( jqxhr, textStatus, error ) {
      var err = textStatus + ", " + error;
      console.log( "Request Failed: " + err );
    });
  }

  function renderCities(stateCode, data) {
    data.cities.sort(nameComparator);

    var citiesList = $('<ul/>', {
      'class': 'cities',
      'data-statecode': stateCode
    });

    data.cities.forEach((city) => {
      city.airports.sort(nameComparator);

      var cityItem = $('<li/>', {
        'class': 'city'
      }).appendTo(citiesList);
      var cityLink = $('<a/>', {
        'class': 'city-link',
        'html': city.name
      }).appendTo(cityItem);

      var aiportList = $('<ul/>', {
        'class': 'airports'
      }).insertAfter(cityItem);
      aiportList.hide();

      cityItem.click(function() {
        aiportList.toggle();
      });

      city.airports.forEach((airport) => {
        var airportItem = $('<li/>', {
        'class': 'airport'
        }).appendTo(aiportList);
        var airportLink = $('<a/>', {
          'class': 'airport-link',
          'html': airport.name + ' (' + airport.value + ')'
        }).appendTo(airportItem);

        airportItem.click(selectItem);
      });

    });

    return citiesList;
  }

  function showDestinationDetail () {

    var id = $(this).attr('id');
    var stateName = $(this).text();

    $.ajax({
      type: 'GET',
      url: `${context.data('opts').urls[options.detail]}${id}`,
      success (data) {

        // TODO: zdrojová data pro našeptávač by se měla sjednotit pod property items
        var items = data.airports ||  data.cities || data.destinations || data;
        var contentName = `${options.detail}-content`;
        var content = renderTag.div('', contentName);

        $('.destinations').fadeOut();

        renderTag.div('<i class="fa fa-chevron-left"></i> ' + context.data('opts').messages.back || 'zpět', 'btn btn-xs btn-aqua')
          .click(() => {
            $(`.${contentName}`).empty().fadeOut();
            $('.destinations').fadeIn();
          })
          .prependTo(content);

        $(`<h3>${stateName}</h3>`).appendTo(content);

        items.forEach((item) => {

          var itemText = printFormatted(options.template, item);

          renderTag.div(itemText, options.detail, item.value)
            .click(selectItem)
            .appendTo(content);

        });

        content.appendTo($('.modal-body'));
      }
    });
  }

  function selectItem () {

    var inputId = $('.destinations').data('input');

    $(inputId).val($(this).text())
      .trigger('keyup')
      .data('submit-value', $(this).attr('id'));

    var modalWrapp = $('#modal-wrapper');
    modalWrapp.fadeOut();
    modalWrapp.promise().done(function() {
      $(this).remove();
    });

    context.data('form').defDest = false;
  }

  function refreshDestinations (input) {
    var index = input.parents('.route').data('index');
    var indx = (input.data('index') < 0) ? 0 : input.data('index');

    if (context.data('form')) { // provede se, jen pokud existuje context.data('form'), jinak hazi chybu u jizdenek na hp

      context.data('form').routes[index][indx].value = input.val();

      if (context.data('form').type === 'round_trip') {

        if (input.attr('id') === 'departure_destination_1')
          $('#arrival_destination_2').val(input.val());

        if (input.attr('id') === 'arrival_destination_1')
          $('#departure_destination_2').val(input.val());
      }

      context.data('form').defDest = false;

    }
  }

  function getWhispers (req, res, query) {
    $.ajax({
      type: 'GET',
      contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
      url: `${context.data('opts').urls.query}${query}`,
      success (data) {

        // TODO: zdrojová data pro našeptávač by se měla sjednotit pod property items
        var items = data.airports || data.destinations || data;
        res(orderDestinations(items, query));
      }
    });
  }
};

function statesComparator(a, b) {
  if (a.country === undefined)
     return a.name.localeCompare(b.name);
  else {
    if(a.country === 'Česko')
      return -1;
    if(b.country === 'Česko')
      return 1;
    if(a.country === 'Slovensko')
      return -1;
    if(b.country === 'Slovensko')
      return 1;

    return a.country.localeCompare(b.country);
  }
}
function nameComparator(a, b) {
  if (a.name === undefined)
     return a.country.localeCompare(b.country);
  else {
    if(a.name === 'Česko' || a.name === 'Česká republika')
      return -1;
    if(b.name === 'Česko' || b.name === 'Česká republika')
      return 1;
    if(a.name === 'Slovensko')
      return -1;
    if(b.name === 'Slovensko')
      return 1;

    return a.name.localeCompare(b.name);
  }
}
