/**
 * Odeslání formuláře
 * @param  {Object} e událost
 * @return {void}
 */
module.exports = function (e) {
  e.preventDefault()
  let el = $('#select-station')
console.log('element:');
console.log(el);
  if(el.val() === '') {
    el.addClass('invalid')
    return
  }
  
  if (el.val().length === 0 || el.data().noMatch) {
    return
//    if(!el.parent().hasClass('invalid'))
//      createAlert(el.parent());
//  } else {
//    removeAlert(el.parent());
  }

  let destData = JSON.stringify(window.destinationsData.destinations) // spoji nase destinace s proviznimi prodejci
  let data = JSON.parse(destData)
  let destination = $('#select-station').val().toLowerCase().trim()

  let prefix, id, query
  // ziska spravne city/station ID ze zadane destinace v inputu a to se pote dava do URL
  data.map(state => {
    state.cities.map(city => {
      if(city.name.toLowerCase().trim() === destination) {
        prefix = (city.stations && city.stations.length <= 1) ? `stationId` : 'cityId'
        id = prefix === 'stationId' ? city.stations[0].id : city.id
      }
      else
        if(city.stations && city.stations.length > 0)
          city.stations.map(station => {
            if(station.fullname.toLowerCase().trim() === destination)
              prefix = 'stationId', id = station.id
          })
    })
  })
  query = `${prefix}=${id}`

  if(!(prefix in ['cityId', 'stationId']) && typeof id !== 'number')
    return false

  window.location = `${window.location.origin}${window.location.pathname}?${query}`
};
