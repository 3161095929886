module.exports = function airlineActionOfferWidget (options) {
  var self = this;
  var helpers = require('./helpers');
  var formatPrice = require('../utils/format-price');
  var createComponent = require('../component').create.bind(this);
  var defaults = {
    name: 'airticket-offers-widget',
    params: {
      id: options.component_id || '',
      pageName: options.pageName || '',
      set: options.set || 'default_cs',
      count: options.count || 10
    }
  };
  var prefix = 'offers-widget-';
  var component = {
    options: $.extend({}, defaults, options),
    render: render,
    helpers: helpers
  };

  function render (component) {
    var opts = component.options || {};
    var offers = component.data || [];
    var helpers = component.helpers || {};

    var header = $('<div/>', {
      'class': prefix + 'header'
    }).appendTo(self);

    var content = $('<div/>', {
      'class': prefix + 'content'
    }).appendTo(self);

    var footer = $('<div/>', {
      'class': prefix + 'footer'
    }).appendTo(self);

    var title = opts.messages.TITULEK;
    var nextText = opts.messages.TEXT_DALSI;

    if (title) {

      var titleBar = $('<div/>', {
        'class': prefix + 'title'
      }).appendTo(header);

      if (title.href)
        $('<a/>', {
          href: title.href,
          title: title.text,
          text: title.text
        }).appendTo(titleBar);
      else
        titleBar.append(title.text);
    }

    if (opts.messages.PEREX)
      $('<small/>', {
        'class': prefix + 'subtitle',
        text: opts.messages.PEREX.text
      })
      .appendTo(header);

    // vybere nahodne nabidky, pokud je tato moznost nastavena pri inicializaci
    if(options.random) {
      offers = helpers.shuffle(offers)
      const newOffers = []
      for (let i = 0; i < options.random; i++) {
        newOffers.push(offers[i])
      }
      newOffers.sort(function(a, b) {
        return parseFloat(a.total) - parseFloat(b.total)
      })

      offers = newOffers
    }

    offers.forEach(function (offer, index) {
      var limit = opts.count || offers.length;
      offer.messages = opts.messages;

      if (index < limit) {

        // nenacitame posledni dve nabidky, protoze se misto nich prida button a zaroven musime byt na hp letenek
        // if( (index === limit - 1 || index === limit - 2) && component.options.params.pageName === 'letenky-homepage')
        //   return


        var row = $('<div/>', {
          'class': prefix + 'offer offer-' + offer.type.toLowerCase()
        }).appendTo(content);

        var link = $('<a/>', {
          'class': prefix + 'offer-route',
          href: offer.url + '/lang/' + opts.lang,
          title: helpers.getRouteText(offer).replace(/<[^>]*>/g, ''),
          html: helpers.getRouteText(offer)
        }).appendTo(row);

        $('<span/>', {
          'class': prefix + 'offer-price',
          text: formatPrice(offer.total) + ' ' + offer.currency
        }).appendTo(link);
      }

    });

    if (nextText)
      $('<a/>', {
        'class': prefix + 'more-link',
        href: nextText.href,
        title: nextText.text,
        text: nextText.text
      }).appendTo(footer);

    if (opts.messages.POST_FOOTER)
      $('<small/>', {
        'class': prefix + 'subtitle',
        text: opts.messages.POST_FOOTER.text
      }).appendTo(footer);

    self.addClass('offers-widget');

    if(component.options.params.pageName === 'letenky-homepage')
      createActionMapButton(opts, content)
  }

  function createActionMapButton({text = 'Mapa akčních letenek', targetUrl = '/letenky/mapa-akcnich-letenek' }, content) {
    let button = $('<a/>', {
      class: 'flight-map-button',
      href: targetUrl,
      text: text
    }).appendTo(content)

    $('<i/>', {
      class: 'sa-icon icon-mapa-letenky'
    }).appendTo(button)

    button.appendTo(content)
  }

  return createComponent(component);
};
