/**
 * Odeslání formuláře
 * @param  {Object} e událost
 * @return {void}
 */
module.exports = function (e) {

  e.preventDefault();

  var context = $(this).parent(); // element na kterém byla komponenta incializována
  var opts = context.data('opts');
  var link = opts.urls.submit; // url pro odeslání
  link += '?' + $(this).serialize() + '&send=' + opts.params.id;

  window.location = link;
};