module.exports = function workAbroadForm (opts) {

  var self = this;
  var defaults = {
    dataPath: '/opencms/opencms/shared/wc/proxy-jp-pp.jsp',
    name: 'work-abroad-form',
    prefix: 'work-',
    params: {
      id: 'pp'
    }
  };
  var createComponent = require('../component').create.bind(self);
  var renderForm = require('../utils/render-form');
  var onSubmit = require('./actions/submit.js');
  var printHeadline = require('../utils/print-headline');
  
  return createComponent({
    options: $.extend({}, defaults, opts),
    preRenderHook,
    render,
    postInitHook
  });

   function preRenderHook (component) {
    self.data('opts', component.options);
  }

  function render (component) {
    printHeadline(opts, this);
    component.renderField = require('./render-field.js')(self);
    renderForm(component, self, onSubmit);
  }


  function postInitHook (component, context) {
    var formElement = $('form.work-abroad-form', context);
    var select = $('.work-abroad-form select', context);
    var options = component.options;

    select.change(() => {
      $.getJSON(`${options.dataPath}?id=${options.params.id}&` + formElement.serialize(), (data) => {
        data.fields.forEach((obj) => {
          var activeSelect = $('.' + obj.id, context);
          activeSelect.empty();
          obj.items.forEach((item) => {
            $('<option/>', {
                value: item.value,
                text: item.name
            }).appendTo(activeSelect);
            if (item.selected)
              $(activeSelect).val(item.value);
          });
        });
      });
    });
    return;
  }
};


