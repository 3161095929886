module.exports = function lastDestinationsPopup ($holder,context,dstCnt) {

  // var departurePicker = $('#ybus-departure_date-block .date_picker');
  // var arrivalPicker = $('#ybus-arrival_date-block .date_picker');
  //
  // departurePicker.datepicker('option', 'onSelect', function() {
  //
  //   var departureDate = departurePicker.datepicker('getDate');
  //
  //   arrivalPicker.datepicker('option', 'minDate', departureDate);
  // });

  // $('#arrival_date').datepicker({
  //   minDate: departureDateMin
  // });






  let parent = document.getElementById('ybus-arrival_date-block');
  let icon =  parent.querySelector('.input-addon');

  icon.addEventListener('click', deleteDate);

  function deleteDate(){
    let inputArrival = document.getElementById('arrival_date');
    let parent = document.getElementById('ybus-arrival_date-block');
    let icon =  parent.querySelector('.input-addon');
    if(icon.classList.contains('open-datepicker1')){
      inputArrival.value = getLangVerForInputArrivalValue();
      icon.classList.remove('open-datepicker1');
      icon.classList.add('open-datepicker');

      let dateToday = new Date();
      $('#arrival_date').datepicker({
        minDate: dateToday
      });
    }
  }

  function getLangVerForInputArrivalValue(){
    let url = window.location.hostname.split('.');
    let inputValue;

    if(url[2] == 'cz')
      inputValue = '+ vybrat';
    else if(url[2] == 'sk')
      inputValue = '+ vybrať';
    else if(url[2] == 'com')
      inputValue = '+ choose';
    else if( url[2] == 'de' || url[2] == 'at')
      inputValue = '+ wählen';

    return inputValue;
  }

  // on change destroy date picker and put cancel icon
  $('#arrival_date').on('change keyup paste', function() {
    let inputArrival = document.getElementById('arrival_date');
    let val = inputArrival.value;
    if(val != '+'){
      let parent = document.getElementById('ybus-arrival_date-block');
      let icon =  parent.querySelector('.input-addon');
      icon.classList.remove('open-datepicker');
      icon.classList.add('open-datepicker1');
      // $('div.input-addon.open-datepicker1').off('click focus');
      $('#arrival_date').datepicker('destroy' );
      $('#arrival_date').removeClass('hasDatepicker');
    }
  });



  if( //popup nebude zobrazen na jine nez nove homepage, u jineho inputu, nez destinace od
    typeof $holder === 'undefined' ||
    $('body').hasClass('homepage-new') == false ||
    $holder.attr('id') !== 'destination_from' ||
    !Array.isArray(context.data('opts').routes) ||
    context.data('opts').routes.length < 1 ||
    typeof context.data('opts').routes === 'undefined'
  )
    return;

  if(typeof $holder.data().popupIsInit === 'undefined'){ //pokud jeste nebylo okno inicializovano
    $holder.data('popupIsInit',true);
    //eventy ###
    $holder.focus(function (e) { // pri focusu na holder, zobraz popup
      if($holder.val().length === 0)
        modalShow();

    });
    $(document).click(function(e) {
      if (!$(e.target).is('li.item') && !$(e.target).is('#destination_from') )
        modalHide();

    });
    $holder.on('input', function(e){
      if($holder.val().length === 0) //pokud neni napsan ani jeden znak, vyvolej posledni destinace

        modalShow();
      else
        modalHide();

    });
    $holder.keyup((e) => {
      if(e.keyCode === 27 || e.keyCode === 9) // ESC a TAB
        modalHide();

    });
    //eventy ###
    const $wrapper = $('<ul/>',{
      'class':'last-destinations-modal',
      id:'last-destinations-modal',
      css:{
        width: '100%',
        position: 'absolute',
        background: 'white',
        'z-index': '1000',
        border: '1px solid #fbbf1f',
        'border-top': '0',
        'display': 'none'
      },
    });
    const $header = $('<li/>',{
      'class':'head',
      html:'Vaše poslední vyhledávání'
    }).appendTo($wrapper);
    context.data('opts').routes.forEach((route, index) => {
      if (index < dstCnt) { // pro vsechny posledni destinace, omezene maximalnim poctem
        var fromName = findById(allData, route.from.id);
        var toName = findById(allData, route.to.id);

        if (typeof fromName === undefined || toName === undefined)
          return; //pokud se nepodarilo nalezt spravny nazev mesta, nevypiseme nic

        let $destination = $('<li/>',{
          'class':'item',
          html:`${fromName} - ${toName}`,
          click:()=>{
            modalHide();
            $('input[name=destination_from]').val(fromName);
            $('input[name=destination_to]').val(toName);
            // id
            $('[name=destination_from_id]').val(route.from.id);
            $('[name=destination_to_id]').val(route.to.id);
            $('input[name=destination_from]').data('locationType', route.from.type);
            $('input[name=destination_to]').data('locationType', route.to.type);
            $('input[name=destination_from]').data('noMatch', false);
            $('input[name=destination_to]').data('noMatch', false);
            $('#destination_from').removeClass('invalid')
            $('#destination_from').parent().removeClass('invalid')
            $('input[name=destination_to]').removeClass('invalid')
            $('input[name=destination_to]').parent().removeClass('invalid')
          }
        });
        $destination.appendTo($wrapper);
      }
    });
    $wrapper.insertBefore($holder);
  }else
    modalShow();

};

const modalShow = () => {
  $('#last-destinations-modal').css({ display:'block'});
  //$('#last-destinations-modal').fadeIn(200);
}
const modalHide = () => {
  $('#last-destinations-modal').css({ display:'none'});
  //$('#last-destinations-modal').fadeOut(200);
}

function findById(allData, findId) {
  for (var i = 0; i < allData.length; i++) {
    var cities = allData[i].cities;
    for (var x = 0; x < cities.length; x++) {
      if (cities[x].id == findId)
        return cities[x].name;

      if (cities[x].hasOwnProperty('stations')) {
        var stations = cities[x].stations;
        for (var y = 0; y < stations.length; y++)
          if (stations[y].id == findId)
            return stations[y].fullname;


      }
    }
  }
}
