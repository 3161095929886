
var self = module.exports = {

  text (name, value, className) {
    className = className || '';
    var opts = {
      'class': className,
      'type': 'text',
      name,
      value,
      id: name
    };

    return $('<input/>', opts);
  },

  email (name, value, className) {
    className = className || '';
    var opts = {
      'class': className,
      'type': 'email',
      name,
      value,
      id: name
    };

    return $('<input/>', opts);
  },

  hidden (name, value, className) {
    className = className || '';
    var opts = {
      'class': className,
      'type': 'hidden',
      name,
      value,
      id: name
    };

    return $('<input/>', opts);
  },

  textarea(name, className) {
    className = className || '';
    var opts = {
      'class': className,
      name,
      id: name
    };
    return $('<textarea/>', opts);
  },

  checkbox(name, value, className, checked, id) {
    className = className || '';
    var opts = {
      'class': className,
      'type': 'checkbox',
      name,
      checked: checked || false,
      value: value,
      id: id
    };

    return $('<input/>', opts);
  },

  radio (name, className, items) {
    className = className || '';
    var radioBlock = $('<div/>', {
      id: name,
      'class': className
    });
    items.forEach((item) => {
      var id = name + '_' + item.value.replace(/ /g,'').substr(item.value.length - 4);
      self.singleRadio(name, item.value, '', id, item.checked).appendTo(radioBlock);
      self.label(id, item.label).appendTo(radioBlock);
    });

    return radioBlock;
  },

  singleRadio(name, value, className, id, checked) {
    className = className || '';
    var opts = {
      id,
      'class': className,
      'type': 'radio',
      name,
      value,
      checked: checked
    };

    return $('<input/>', opts);
  },

  date(name, value, className) {
    className = className || '';
    var opts = {
      'class': className,
      'type': 'date',
      name,
      value,
      id: name
    };

    return $('<input/>', opts);
  },

  select (name, className, options) {
    className = className || '';
    var opts = {
      'class': className,
      name,
      id: name
    };
    var select = $('<select/>', opts);

    options.forEach(function (option) {
      $('<option/>', {
        value: option.value,
        text: option.name,
        selected: option.selected
      }).appendTo(select);
    });
    return select;
  },

  rangeSelect (name, className, options) {
    className = className || '';
    var opts = {
      'class': className,
      name,
      id: name
    };
    var select = $('<select/>', opts);

    for(var i = options.from; i <= options.to; i += options.step) {
      $('<option/>', {
        value: i,
        text: i
      }).appendTo(select);
    }

    return select;
  },

  multipleCheckbox(name, className, items) {
    className = className || '';
    var checkboxBlock = $('<div/>', {
      'class': className,
      id: name
    });
    var index = 0;
    items.forEach((item) => {
      self.checkbox(item.name, item.value, item.value, item.checked, item.name + '_' + index).appendTo(checkboxBlock);
      self.label(item.name + '_' + index, item.label, 'control-label-checkbox').appendTo(checkboxBlock);
      index++;
    });
    return checkboxBlock;
  },

  multiple(name, className, fields) {
    className = className || '';
    var multipleBlock = $('<div/>');
    fields.forEach((field) => {
      switch(field.type) {
      case 'text':
        self.text(field.id, field.value);
      break;
      case 'select':
        self.select(field.id, '', field.fields).appendTo(multipleBlock);
      break;
      case 'rangeSelect':
        self.rangeSelect(field.id, '', field.options).appendTo(multipleBlock);
      break;
      }
    });

    return $($(multipleBlock).html());
  },


  /**
   * Vykreslení labelu
   * @param  {String} name Jméno pole, ke kterému je label vázaný
   * @param  {String} html Obsah labelu, může obsahovat i html tagy
   * @return {Object}      jQuery element
   */
  label (name, html, className) {

    var opts = {
      'class': className ? `control-label ${className}` :  'control-label',
      'for': name || '',
      html
    };

    return $('<label/>', opts);
  },

  /**
   * Vykreslení divu
   * @param  {String} html      HTML obsah divu
   * @param  {String} className CSS třída divu
   * @param  {String} id        atribut id divu
   * @return {Object}           jQuery element
   */
  div (html, className, id) {

    var opts = {
      'class': className,
      html,
      id
    };

    return $('<div/>', opts);
  }
};