module.exports = function (context) {
  var way = require('./fields/way');
  var preffered = require('./fields/preffered');
  var contact = require('./fields/contact');
  var captcha = require('./fields/captcha');

  return {
    way,
    preffered,
    contact,
    captcha
  };
};

