module.exports = {

  getRouteText: function (offer) {

    var types = {};
    var trips = offer.journeys;
    var type = offer.type;

    var template = '<span class="offer-from">' + trips[0]['from-name'] + '</span><span class="offer-delimiter"> - </span><span class="offer-to">' + trips[0]['to-name'] + '</span>';

    types.ONE_WAY = '<span class="offer-route-text">' + template + '</span>';
    types.ROUND_TRIP = '<span class="offer-route-text">' + template + '<span class="trip-type"> ' + offer.messages.A_ZPET.text + '</span>' + '</span>';
    types.OPEN_JAW = getOpenJawRoute();

    function getOpenJawRoute () {

      var openjawFrom = [
          '<span class="offer-openjaw-from">' + trips[0]['from-name'] + '</span><span class="offer-delimiter"> - </span>',
          '<span class="offer-openjaw-stop">' + trips[0]['to-name'] + '</span>'
        ].join('');

      var openjawTo = [
          '<span class="offer-openjaw-stop">' + trips[trips.length -1]['from-name'] + '</span><span class="offer-delimiter"> - </span>',
          '<span class="offer-openjaw-to">' + trips[trips.length -1]['to-name'] + '</span>'
        ].join('');

      return '<span class="offer-openjaw-line">' + openjawFrom + '</span> <span class="offer-openjaw-line">' + openjawTo + '</span>';
    }

    return types[type];
  },
  shuffle: function (array) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

};
