
module.exports = passengerLogic;

function refreshPassenger (item, sel, free, limits) {
  $(item).html('');

  var limit = limits[item] || 6;
  var max = free + sel + 1;
  max = (max > limit) ? limit : max;

  for (var i = 0; i < max; i++) {
    var option = document.createElement('option');
    option.value = i;
    option.innerHTML = i;

    if ((sel !== null) && (sel === i))
      option.selected = true;

    $(item).append(option);
  }
}

function passengerLogic (limits) {

  var adt = parseInt($('#passengers_adt').val() ? $('#passengers_adt').val() : 0);
  var yth = parseInt($('#passengers_yth').val() ? $('#passengers_yth').val() : 0);
  var ycd = parseInt($('#passengers_ycd').val() ? $('#passengers_ycd').val() : 0);
  var chd = parseInt($('#passengers_chd').val() ? $('#passengers_chd').val() : 0);
  var inf = parseInt($('#passengers_inf').val() ? $('#passengers_inf').val() : 0);
  var count = adt + ycd + yth + chd + inf;

  if (count > 9) {
    count = adt + ycd + yth + chd;
    inf = 0;
    if (count > 9) {
      chd = 0;
      count = adt + ycd + yth;
      if (count > 9) {
        yth = 0;
        count = adt + ycd;
        if (count > 9)
          ycd = 0;
      }
    }
  }

  var chdMax = 0;

  if (((adt + ycd + yth) > 0) && (adt + ycd + yth + inf < 9))
    chdMax = 9 - adt - ycd - yth - inf;
  else
    chd = 0;

  var infMax = adt + yth + ycd;

  count = adt + yth + ycd + chd;

  if (infMax > 9 - count)
    infMax = 9 - count;

  if (infMax < inf)
    inf = infMax;

  var freePlaces = 9 - adt - ycd - yth - chd - inf;

  if (chdMax > freePlaces)
    chdMax = freePlaces;

  refreshPassenger('#passengers_adt', adt, freePlaces, limits);
  refreshPassenger('#passengers_ycd', ycd, freePlaces, limits);
  refreshPassenger('#passengers_yth', yth, freePlaces, limits);
  refreshPassenger('#passengers_inf', inf, (infMax - inf), limits);
  refreshPassenger('#passengers_chd', chd, chdMax, limits);
}