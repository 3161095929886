var renderTag = require('../../utils/render-tag');
var printFormatted = require('../../utils/print-formatted');
var utils = require('../../utils/utils');
var removeDiacritics = require('../../utils/remove-diacritics');
var tagRenderer = require('../../utils/tag-render');
// var autofocus = require('../actions/autofocus')();

String.prototype.replaceAll = function(search, replacement) {
    var target = this;
    return target.split(search).join(replacement);
};

module.exports = function (options, context) {

  if(options['destinations_tree_view'])
    var actions = require('../../utils/actions/destinationTreeView')(options, context);
  else
    var actions = require('../../utils/actions/destination')(options, context);

  // pokud jsou v options nastaveny lolani akce, pouzijeme je
  if(typeof options.useLocalDestinations !== 'undefined' && options.useLocalDestinations.state)
    actions = require(`../../${options.useLocalDestinations.path}/actions/destination`)(options, context);

  return function (field, block) {

    let selected = false
    let autocomplete = null

    field.value = field.value || field.def;

    block.addClass('destination-field');

    //Načítať parameter z url, uložiť idčka staníc
    /*field = */handleUrlStation(field);

    var inputIndex = field.inputIndex || 0;
    var destination = renderTag.input('text', field.id, field.value, null, field.placeholder, field.selectOnClick, field.tabindex)
    var destinationHidden = tagRenderer.hidden(`${field.id}_short`, '', '').appendTo(block)

    // Určuje, či môžem uložiť hodnotu. Ak je false, znamená to, že autocomplete bol práve otvorený a teda nebudem prepisovať input
    // ak je true, znamená to, že focus bol získaný šípkami a teda zmením input
    let setVal = false

    var autocompleteOptions = {
      // minLength: 3,
      minLength: 1,
      source (req, res) {
        selected = false
        actions.getWhispers(req, res, encodeURIComponent($(':focus').val()));
      },
      open (e, ui) {
        autocomplete = $('.ui-autocomplete:visible')
        setVal = false
        var menu = $(this).data("uiAutocomplete").menu
        let item = autocomplete.find(`.ui-menu-item:not(.ui-state-disabled)`)
        menu.focus(null, item)
      },
      focus (e, ui) {
        if(setVal) {
          $(':focus').val(printFormatted(options.template, ui.item));
        } else {
          setVal = true;
        }
        return false;
      },
      select (e, ui) {
        // console.log('select')

        selected = true

        var templateSelect = options.templateSelect ? options.templateSelect : options.template;
        $(':focus').val(printFormatted(templateSelect, ui.item))
          .trigger('keyup')
          .data('submit-value', ui.item.value);

        //Nastavenie skrytého inputu aj po vybratí položky z našeptávača
        var inputId = $(e.target).attr('id');
        $('#' + inputId + '_id').val(ui.item.id);

        destinationHidden.val(ui.item.value);

        return false;
      },
      close (e, ui) {
        // autofocus.next()
        // if(!selected) {
        //   let item = autocomplete.find('.ui-menu-item:not(.ui-state-disabled):focus, .ui-menu-item:not(.ui-state-disabled)').first().data('item')
        //   $(e.target).val(printFormatted(options.template, item))
        //
        //   //Nastavenie skrytého inputu aj po vybratí položky z našeptávača
        //   var inputId = $(e.target).attr('id');
        //   $('#' + inputId + '_id').val(item.id);
        // }
      },
      // autoFocus: true
    };

    destination.autocomplete(autocompleteOptions);
    destination.data('index', inputIndex);

    destination.keyup((e) => {
      if (inputIndex > -1)
        actions.refreshDestinations($(e.target));
    });

    destination.data('ui-autocomplete')._renderItem = options.renderAutocompleteItem || renderAutocompleteItem;

    if (typeof options.renderAutocompleteMenu === 'function')
      destination.data('ui-autocomplete')._renderMenu = options.renderAutocompleteMenu;

    destination.appendTo(block);

    renderTag.div('', 'input-addon open-modal')
      .click(actions.showDestinations)
      .appendTo(block);

    return block;

    function renderAutocompleteItem (ul, item) {

      //ar flagUrl = '/shared/data/national-flags';
      //Dočasné pre olymp7, keď bude z olymp7 presunuté, zmazať
      var flagUrl = 'https://www.studentagency.cz/shared/data/national-flags';
      var li = $('<li/>').data('item', item);
      var img = $(`<img src="${flagUrl}/${item.statecode}.gif" alt="${item.statecode}"/>`);
      var link = $('<a/>', {
        text: printFormatted(options.template, item)
      });

      img.prependTo(link);
      link.appendTo(li);
      return li.appendTo(ul);
    }
  };

  function handleUrlStation(field) {
    const urlParam = field.urlParam || field.id;
    var param = utils.getUrlParameter(urlParam);
    if(param && typeof param === 'string') {
      param = param.replaceAll('+', ' ').replaceAll('*', ' - ');
      //field.value = param;
    }
    else
      return;// field;

    var destObject = actions.getDestObject();

    if(!destObject) {
      $.ajax({
        type: 'GET',
        url: context.data('opts').urls.states,
        success (data) {
          actions.setDestObject(data);
          destObject = data;
          field = setUrlStation(field, param, destObject);
          //return field;
        }
      });
    } else {
      field = setUrlStation(field, param, destObject);
      //return field;
    }
  }
  function setUrlStation(field, param, data) {
    // nastaveni pro propisovani pri prepnuti typu formu
    var formData = context.data('form');
    formData.routes[0][field.inputIndex].value = param;
    // nastaveni inputu destination z url
    $('#' + field.id).val(param);
    return field;
  }

};
