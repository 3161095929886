var element = require('./element');

module.exports = {
  //Typ input select
  create: function(field) {
    element.create(this, field);

    this.checked = false;

    this.render = function() {
      var classAttr = 'gf-select';
      if(field.classBlock)
        classAttr += ` ${field.classBlock}`;
      if(!this.valid)
        classAttr += ' invalid';
      classAttr += this.customClass ? ' ' + this.customClass : '';

      var selectElement = $('<div/>', {
        'class': classAttr
      });

      element.renderLabel(selectElement, this);

      element.renderAlert(selectElement, this);

      var select = $('<select/>', {
        id: this.id,
        name: this.name
      }).appendTo(selectElement);

      field.items.forEach((item) => {
        $('<option/>', {
          text: item.label,
          value: item.value
        }).appendTo(select);
      });

      if(this.value)
        select.val(this.value);

      return selectElement;
    };

    this.setByView = function() {
      this.value = this.jqObject.find('#' + this.id).val();
    };
  }
};
