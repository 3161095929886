/**
 * Otvorenie/zatvorenie a vykreslenie lightboxu
 */

/*
volanie:
var lb = $().lightbox;
lb.create(content);

--s vlastnou funkciou--
lb.create(null, customClass, customFunction, opts);
*/

var closeCallback = null;

module.exports = {
  create: createLightbox,
  destroy: destroyLightbox,
  open: openLightbox,
  close: closeLightbox,
  registerCloser: registerCloser,
  registerOpener: registerOpener,
  registerDestroyer: registerDestroyer,
  closeOnBackground: closeOnBackground,
  hideX: hideX,
  showX: showX,
  setHeight: setHeight,
  changeContent: changeContent,
  onClose: onClose
};

/*
@param content - obsah lightboxu, ked sa nepouzije vlastna render funkcia
@param customFunction - vlastna funkcia ktora renderuje obsah lightboxu
@param opts - objekt ktorý je predávaný vlastnej funkcii
*/
function createLightbox(content, customClass, customFunction, opts) {
  if(typeof customFunction === 'function') {
    content = customFunction(opts);
    renderLightbox(content, customClass);
  } else {
    var innerContent = $('<div/>', {
      'class': 'lb-main-content'
    });
    $(content).appendTo(innerContent);
    renderLightbox(innerContent, customClass);
  }

  openLightbox();

  return this;
}

function destroyLightbox() {
  closeLightbox();
  $('.lightbox').remove();
}
function openLightbox() {
  $('#light, #fade').fadeIn(200);
  window.replacePlaceholder('is-focused', 'not-focused'); // nahrazeni placeholderu pro IE9
  return this;
}
function closeLightbox() {
  $('#light, #fade').fadeOut(200);

  if(typeof closeCallback === 'function') {
    closeCallback();
  }

  return this;
}

function renderLightbox(content, customClass) {
  destroyLightbox();
  var lightbox = $('<div/>', {
    'class': 'lightbox',
    'id': 'lightbox'
  }).appendTo($('body'));
  var light = $('<div/>', {
    'class': 'white_content',
    'id': 'light'
  }).appendTo($(lightbox));
  if(customClass) {
    $(light).addClass(customClass);
  }

  //Krížik na zavretie okna
  var closeX = $('<div/>', {
  	'id': 'lightbox-close-x'
  }).appendTo(light);
  registerCloser(closeX);

  $(content).appendTo(light);

  var fade = $('<div/>', {
    'class': 'black_overlay',
    'id': 'fade'
  }).appendTo($(lightbox));

  return lightbox;
}

function changeContent(content, lbClass, customFunction, opts) {
  if(customFunction) {
    content = customFunction(opts);
  }

  var newClass = 'white_content';
  newClass += lbClass ? ' ' + lbClass : '';
  $('#light').attr('class', newClass).html(content);
}

function registerCloser(closer) {
  $(closer).on('click', closeLightbox);
  return this;
}
function registerOpener(opener) {
  $(opener).on('click', openLightbox);
  return this;
}
function registerDestroyer(destroyer) {
  $(destroyer).on('click', destroyLightbox);
  return this;
}

function closeOnBackground() {
  registerCloser($('#fade').css('cursor', 'pointer'));
  //Zavretie po stlaceni escape
  $(document).keyup(function(e) {
    if (e.keyCode == 27) {
        closeLightbox();
    }
  });

  return this;
}
function setHeight(height) {
  $('#light').height(height);
  return this;
}
function hideX() {
  $('#lightbox-close-x').hide();
  return this;
}
function showX() {
  $('#lightbox-close-x').show();
  return this;
}

function onClose(callback) {
  closeCallback = callback;
  return this;
}