//var renderTag = require('../utils/render-tag');
var tagRenderer = require('../utils/tag-render');

module.exports = function (field, block) {

	field.fields.forEach((item) => {
		var itemBlock = tagRenderer.div('', `${item.id}-block form-element`);
		var label = tagRenderer.label(item.id, item.label);
	    if(item.required) {
	      label.append(' *');
	      itemBlock.attr('data-required', 'true');
	    }
	    itemBlock.attr('data-type', item.type);
		label.appendTo(itemBlock);
	    switch(item.type) {
	    	case 'info':
	    		//Len label bez inputu
	    		break;
	    	case 'multipleCheckbox':
	    		tagRenderer.multipleCheckbox(item.id, `${item.id}-checkbox-block validate-element`, item.items).appendTo(itemBlock);
	    		break;
	    	case 'radio':
	    		tagRenderer.radio(item.id, `${item.id}-radio-block validate-element`, item.items).appendTo(itemBlock);
	    		break;
	    	case 'textarea':
	    		tagRenderer.textarea(item.id, 'validate-element').appendTo(itemBlock);
	    		break;
	    	case 'hidden':
	    		tagRenderer.hidden(item.id, item.def, 'validate-element').appendTo(itemBlock);
	    		break;
	    	case 'text':
	    		tagRenderer.text(item.id, item.value, 'validate-element').appendTo(itemBlock);
	    		break;
	    	case 'email':
	    		tagRenderer.email(item.id, item.value, 'validate-element').appendTo(itemBlock);
	    		break;
	    }
	    itemBlock.appendTo(block);
	});

  return block;

};