module.exports = function insuranceForm (opts) {

  var self = this;
  var defaults = {
    name: 'insurance-form',
    prefix: 'insurance-',
    dataPath: '/shared/wc/insurance-form/data.json',
    params: {
      id: opts.id
    }
  };
  var createComponent = require('../component').create.bind(self);
  var renderForm = require('../utils/render-form');
  var onSubmit = require('./actions/submit');
  var printHeadline = require('../utils/print-headline');

  return createComponent({
    options: $.extend({}, defaults, opts),
    preRenderHook,
    render
  });

  function preRenderHook (component) {
    self.data('opts', component.options);
  }

  function render (component) {
    printHeadline(opts, this);
    component.renderField = require('./render-field')(self);
    renderForm(component, self, onSubmit);
  }
};



