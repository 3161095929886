const renderTag = require('../utils/render-tag');
const radio = require('../utils/fields/radio');
const checkbox = require('../utils/fields/checkbox')();
const hidden = require('../utils/fields/hidden');
const date = require('../utils/fields/date')();
const select = require('../utils/fields/select.js');

var destOptions = $.extend({}, {
  detail: 'query',
  template: '{fullname}',
  renderDestinations
}, require('./actions/autocomplete'));


module.exports = function (context) {
  const destination = require('../utils/fields/destination')(destOptions, context);

  return {
    checkbox,
    trip_type,
    destination,
    date,
    switch_arrow,
    select,
    hidden,
    customer_info,
    passengers_counter,
    additional_info
  };
};



function renderDestinations (data, handleClick) {
  var opts = data.options;
  var messages = opts.messages;
  var dests = data.destinations;

  var block = $('<div/>');

  data.headline = messages.headline;
  data.count = dests.length;

  dests.forEach((item) => {

    var stateDiv = $('<div class="state"/>');

    $(`<h4>${item.country}</h4>`).appendTo(stateDiv);

    item.cities.forEach((city) => {

      var cityDiv = $('<div class="city"/>');
      var ul = $('<ul/>');

      $(`<h5 data-id="${city.id}">${city.name}</h5>`)
        .click(handleClick)
        .appendTo(cityDiv);

      if(city.stations.length > 1) {
        city.stations.forEach((station) => {
          $(`<li data-id="${station.id}">${station.fullname}</li>`)
          .click(handleClick)
          .appendTo(ul);
        });

        ul.appendTo(cityDiv);
      }

      cityDiv.appendTo(stateDiv);
    });

    stateDiv.appendTo(block);

  });

  return block;
}


/**
 * Vykreslení pole pro výběr typu jízdenky
 * @param  {Object} field data k poli
 * @param  {Object} block rodičovský element
 * @return {Object}       jQuery element
 */
function trip_type (field, block) {

  field.fields.forEach((item) => {

    item.id = field.id;
    radio(item, block, onChange);
    renderTag.label(item.value, item.label).appendTo(block);

    function onChange () {

      if ($(this).val() === 'one_way')
        $('input[name=arrival_date]').attr('disabled', true).next().addClass('disabled');
      else
        $('input[name=arrival_date]').attr('disabled', false).next().removeClass('disabled');
    }

  });

  return block;
}

/**
 * Vykreslení pole pro prohození lokací
 * @param  {Object} field data k poli
 * @param  {Object} block rodičovský element
 * @return {Object}       jQuery element
 */
function switch_arrow (field, block) {

  block.click(() => {

    var from = $('input[name=destination_from]').val();
    var to = $('input[name=destination_to]').val();

    $('input[name=destination_to]').val(from);
    $('input[name=destination_from]').val(to);

    //WEB-3380 - treba prehodiť aj hodnoty v skrytých inputoch, ktoré predstavujú id destinácií od, do

    from = $('input[name=destination_from_id]').val();
    to = $('input[name=destination_to_id]').val();

    $('input[name=destination_to_id]').val(from);
    $('input[name=destination_from_id]').val(to);
    
    //prohodit priznak nevalidniho pole (neodpovida mu zadna destinace)

    var fromNoMatch = $('input[name=destination_from]').data('noMatch');
    var toNoMatch = $('input[name=destination_to]').data('noMatch');
       
    $('input[name=destination_to]').data('noMatch',fromNoMatch);
    $('input[name=destination_from]').data('noMatch',toNoMatch);

  });

  return block;
}

/**
 * Vykreslení pole s cestovni tridou
 * @param  {Object} field data k poli
 * @param  {Object} block rodičovský element
 * @return {Object}       jQuery element
 */
function customer_info (field, block) {

  field.fields.forEach((field) => {
    var fieldWrap = $('<div/>', {
      'class': 'field-wrap'
    }).appendTo(block);

    renderTag.label(field.name, field.label).appendTo(fieldWrap);
    renderTag.input(field.type, field.name).appendTo(fieldWrap);

    if (field.alert_msg)
      fieldWrap.attr('data-required', field.alert_msg);
  });

  return block;
}

/**
 * Vykreslení dodatecne info
 * @param  {Object} field data k poli
 * @param  {Object} block rodičovský element
 * @return {Object}       jQuery element
 */
function additional_info (field, block) {
  var fields = field.fields;
  fields.forEach((field) => {
    var labelWrap = $('<div/>', {
      'class': 'field-wrap'
    }).appendTo(block);
    switch(field.type) {
      case 'text':
        renderTag.label(field.name, field.label).appendTo(labelWrap);
        renderTag.input(field.type, field.name).appendTo(labelWrap);
        break;
      case 'textarea':
        renderTag.textarea(field.name, field.placeholder).appendTo(labelWrap);
        break;
    }
  });

  return block;
}

/**
 * Vykreslení pole s nastavenim poctu cestujicich
 * @param  {Object} field data k poli
 * @param  {Object} block rodičovský element
 * @return {Object}       jQuery element
 */
function passengers_counter (field, block) {
  var wrapper = $('<div/>').appendTo(block);

  field.fields.forEach((field)=> {
    var fieldWrap = $('<div/>', {
      'class': 'field-wrap'
    }).appendTo(wrapper);
    var label = renderTag.label(field.name, field.label).appendTo(fieldWrap);

    var inputWrap = $('<div/>', {
      'class': 'input-wrap'
    }).appendTo(fieldWrap);
    var input = renderTag.input(field.type, field.name, field.value).appendTo(inputWrap);

    var buttonWrap = $('<div/>', {
      'class': 'buttons'
    }).appendTo(inputWrap);

    for(var j = 0; j < 2; j++) {
      var text = j === 0 ? '+' : '-';
      var type = j === 0 ? 'inc' : 'dec';

      var button = $('<button/>', {
        'type': 'button',
        'text': text,
        'data-type': type
      }).appendTo(buttonWrap);

      button.on('click', function() {
        var type = $(this).attr('data-type');
        var input = $(this).parent().siblings('input');

        if (type === 'inc')
          input.val(function(i, oldval) {
            return ++oldval;
          });

        else {
          if (input.val() <= 0)
            return;

          input.val(function(i, oldval) {
            return --oldval;
          });
        }
      });
    }
  });

  return block;
}
