module.exports = function ybusReservationForm (opts) {

  var self = this;
  var defaults = {
    dataPath: '/opencms/opencms/shared/wc/ybus-form/data.json',
    name: 'ybus-form',
    prefix: 'ybus-',
    params: {
      id: opts.id
    }
  };

  var createComponent = require('../component').create.bind(self);
  var renderForm = require('../utils/render-form');
  var onSubmit = require('./actions/submit');
  var printHeadline = require('../utils/print-headline');

  return createComponent({
    options: $.extend({}, defaults, opts),
    preRenderHook,
    render,
    postInitHook
  });

  function preRenderHook (component) {
    self.data('opts', component.options);
    self.data('opts').currentTariffs = [];
  }

  function render (component) {
    printHeadline(opts, this);
    component.renderField = require('./render-field')(self);
    renderForm(component, self, onSubmit);
  }

  function postInitHook () {
    var modifyTariffs = require('./actions/modify-tariffs');
    modifyTariffs.modify('.tariff-block', 1);

    if ($('input[name=trip_type]').val !== 'round_trip')
      $('input[name=arrival_date]').attr('disabled', true).next().addClass('disabled');

    //WEB-3381 - dátum príjazdu nesmie byť pred dátumom odjazdu
    var departurePicker = $('#ybus-departure_date-block .date_picker');
    var arrivalPicker = $('#ybus-arrival_date-block .date_picker');

    departurePicker.datepicker('option', 'onSelect', function() {

      var departureDate = departurePicker.datepicker('getDate');

      if(departureDate > arrivalPicker.datepicker('getDate'))
        arrivalPicker.datepicker('setDate', departureDate);

      arrivalPicker.datepicker('option', 'minDate', departureDate);
    })
  }

};
