/**
 * Funkce pro získání počtu dní na základě rozmezí dvou dat
 * @param  {Date} fromDate Datum od
 * @param  {Date} toDate   Datum do
 * @return {Number}        Počet dní
 */
module.exports = function countNights (fromDate, toDate) {

  var oneDay = 24 * 60 * 60 * 1000;
  var d1 = fromDate.getTime();
  var d2 = toDate.getTime();

  return Math.ceil((d2 - d1) / oneDay);
};

