/**
 * Funkce pro vykreslení formátovaného textu
 * @param  {String} template Šablona pro vykreslení ve formátu 'this is {value}'
 * @param  {Object} map      Mapa ze, které se dosadí hodnoty do výsledku tzn. při {value: 'xxx'} bude '{value}' nahrazeno 'xxx'
 * @return {String}          Naformátovaný text na základě vstupních parametrů
 */
module.exports = function printFormatted (template, map) {

  return template.replace(/{([a-z]+)}/g, function (match) {
    var prop = match.substring(1, match.length - 1);
    return map[prop] || prop;
  });

};