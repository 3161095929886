var element = require('./element');

module.exports = {
  //Typ input radio
  create: function(field) {
    element.create(this, field);

    this.chosen = false;
    this.checked = ''; //Bude tam uložená value toho buttonu, ktorý je checked

    this.render = function() {
      var classAttr ='gf-radio';
      if(field.classBlock)
        classAttr += ` ${field.classBlock}`;
      if(!this.valid) 
        classAttr += ' invalid';
      
      classAttr += this.customClass ? ' ' + this.customClass : '';
      var radioElement = $('<div/>', {
        'class': classAttr
      });

      element.renderLabel(radioElement, this);

      element.renderAlert(radioElement, this);

      field.items.forEach((item) => {
        var singleRadio = $('<div/>').appendTo(radioElement);
        var radio = $('<input/>', {
          id: item.id,
          'type': 'radio',
          name: field.name,
          value: item.value
        }).appendTo(singleRadio);

        if(item.checked || this.checked == item.value) {
          $(radio).attr('checked', true);
          this.checked = item.value;
        }

        if(item.label) 
          $('<label/>', {
            html: item.label,
            'for': item.id
          }).appendTo(singleRadio);
        
      });

      return radioElement;
    };

    this.validate = function() {
      var res = true;
      if(!this.checked) {
        res = false;
        this.required_valid = false;
      }
      this.valid = res;
    };

    this.setByView = function() {
      var self = this;
      this.jqObject.find('input[type="radio"]').each(function() {
        if($(this).is(':checked')) 
          self.checked = $(this).val();
        
      });
    };
  }
}
