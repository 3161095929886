module.exports = checkPassenger;

function checkPassenger(child, adult, nad70) {

  var change = {
    child: {},
    adult: {},
    nad70: {},
  }

  change.child.add = (child < 5) ? true : false;
  change.child.remove = (child > 0) ? true : false;

  change.adult.add = (adult < 5) ? true : false;
  change.adult.remove = (adult > 0) ? true : false;

  change.nad70.add = (nad70 < 5) ? true : false;
  change.nad70.remove = (nad70 > 0) ? true : false;


  
  return change;
}