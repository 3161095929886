/**
 * Funkce, která ze vstupních dat vytvoří element obsahující navigací a abecedně seřazené a setříděné položky, které
 * při prokliku zavolají funkci předanou v parametru action
 *
 * @param  {Array}    data   pole objektů ve formátu [{name:'', value:''}]
 * @param  {Function} action funkce, která se provede při kliknutí na položku
 * @return {Object}          jQuery element
 */
module.exports = function (data, action) {

  var renderTag = require('./render-tag');
  var letters = [];
  var obj = {};
  var block = renderTag.div('', 'letterized');
  var letterNav = renderTag.div('', 'letter-nav clearfix');

  data.forEach((item) => {

    var letter = item.name.substring(0, 1);

    if (letters.indexOf(letter) < 0) {
      obj[letter] = [];
      letters.push(letter);
    }

    obj[letter].push(renderTag.div(item.name, 'letter-block-item', item.value));
  });

  letters = letters.sort((a, b) => {
    return a.localeCompare(b, 'cs', {
      sensitivity: 'variant'
    });
  });

  letters.forEach((letter) => {

    var letterBlock = renderTag.div('', 'letter-block', `letter-${letter}`).data('letter', letter);

    $(`<h4>${letter}</h4>`).appendTo(letterBlock);

    $('<a/>', {
      'class': 'letter-nav-item',
      href: `#letter-${letter}`,
      text: letter
    }).appendTo(letterNav);

    obj[letter].forEach((item) =>

      item.click(action).appendTo(letterBlock)
    );

    letterBlock.appendTo(block);
  });

  letterNav.prependTo(block);

  return block;
};
