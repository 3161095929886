/**
 * Vykreslenie formulárových prvkov
 *
 * Využíva sa podmienka switch, pretože každá renderovacia funkcia berie rozdielne parametre
 *
 * Pre zavedenie nového prvku treba pridať
 * case 'typ prvku': a zavolať renderovaciu funkciu
 *
 * Pre validáciu je dôležité, aby každý obaľovací div obsahoval typ formulárového prvku, ktorý obsahuje
 * "itemBlock.attr('data-type', item.type);"
 */

var tagRenderer = require('../utils/tag-controls/tag-render');

module.exports = function (field, block) {

  field.fields.forEach((item) => {
    var itemBlock = tagRenderer.div('', `${item.id}-block`);
    var label = tagRenderer.label(item.id, item.label);
    if(item.required) {
      label.append(' *');
      itemBlock.attr('data-required', 'true');
      itemBlock.attr('data-type', item.type);
    }
    label.appendTo(itemBlock);

    switch(item.type) {
    case 'text':
      tagRenderer.text(item.id, item.value, 'validate-element').appendTo(itemBlock);
      break;
    case 'textarea':
      tagRenderer.textarea(item.id, 'validate-element').appendTo(itemBlock);
      break;
    case 'select':
      tagRenderer.select(item.id, 'validate-element', item.options).appendTo(itemBlock);
      break;
    case 'checkbox':
      tagRenderer.checkbox(item.id, item.value, 'validate-element').appendTo(itemBlock);
      break;
    case 'radio':
      tagRenderer.radio(item.id, 'validate-element', item.items).appendTo(itemBlock);
      break;
    case 'multipleCheckbox':
      tagRenderer.multipleCheckbox(item.id, 'validate-element', item.items).appendTo(itemBlock);
      break;
    case 'multiple':
      tagRenderer.multiple(item.id, 'validate-element', item.items).appendTo(itemBlock);
      break;
    }
    itemBlock.appendTo(block);
  });

  return block;

};