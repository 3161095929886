var languageDropdown = require('./utils/languageDropdown/language-dropdown.js');

module.exports = (function SAComponent () {

  if(!(window.console && console.log)) {
    console = {
      log: function(){},
      debug: function(){},
      info: function(){},
      warn: function(){},
      error: function(){}
    };
  }

  return {
    create: create,
    init: init
  };

  function create (component) {

    if (!component.render || typeof component.render !== 'function')
      return new Error('Metoda render musí existovat');

    var self = this;
    var options = component.options || {};
    var newComponent = Object.create({
      options,
      init,
      helpers: component.helpers,
      preRenderHook: component.preRenderHook || null,
      render: component.render.bind(self),
      postInitHook: component.postInitHook || null
    });

    if (!options.defer)
      init(newComponent, self);

    return newComponent;

  }

  function init (component, context) {

    var options = component.options;
    var hostname = options.hostname || '/opencms/opencms/shared/';
    var baseHref = `${hostname}wc/${options.name}`;

    if (options.loadCss)
      loadCSS(baseHref + '/style.css');

    if(options.directDataLoading && typeof options.directDataLoading !== 'undefined') {
      initComponent(options.directData)
    } else {
      // zmena na getJSON, jiank nefungovalo cross domain
      $.getJSON(options.dataPath || baseHref + '/data.json', options.params)
      .then(data => {
        initComponent(data)
      }).fail(err => {
        renderError.bind(context)
      })
    }


    // $.ajax({
    //   dataType: 'json',
    //   url: options.dataPath || baseHref + '/data.json',
    //   contentType: "text/json; charset=utf-8",
    //   data: options.params,
    //   success: initComponent,
    //   error: renderError.bind(context)
    // });

    function initComponent (response) {

      component.options = $.extend({}, response.options, options);
      component.data = response.data || response.fields;

      context.empty();

      if (typeof component.preRenderHook === 'function')
        component.preRenderHook(component);

      if (options.preRenderHook && typeof options.preRenderHook === 'function')
        options.preRenderHook(component, context);

      if (component.options.languageDropdown)
        languageDropdown(component, context);

      component.render(component);

      if (typeof component.postInitHook === 'function')
        component.postInitHook(component, context);

      if (options.postInitHook && typeof options.postInitHook === 'function')
        options.postInitHook(component, context);

    }

    function renderError () {
      this.empty();

      $('<div/>', {
        'class': 'error',
        text: 'Došlo k chybě při získávání dat, prosím zkuste stránku obnovit.'
      }).appendTo(this);

      $('<div/>', {
        'class': 'error',
        text: 'An error occurred while retrieving data, please try refreshing the page.'
      }).appendTo(this);

    }

    function loadCSS (href) {

      var ss = document.createElement( 'link' );
      ss.rel = 'stylesheet';
      ss.href = href;
      document.getElementsByTagName('head')[0].appendChild(ss);
      return ss;

    }

  }

})();
