var renderTag = require('../render-tag');

module.exports = function (options) {

  return function date (field, block) {

    block.addClass('date-field');

    //Nastaví globálne pre datepickery počet otvorených mesiacov
    if(field.monthsNumber) {
      $.datepicker.setDefaults({
        numberOfMonths: field.monthsNumber
      });
    }

    var dateInput = renderTag.input('text', field.id, field.def, null, null, null, field.tabindex).addClass(`date_picker date-${field.index}`);

    if (field.readonly)
      dateInput.attr('readonly', 'readonly');

    var dpOptions = $.extend({}, {
      //numberOfMonths: 1,
      minDate: '0d',
      showButtonPanel: true,
      maxDate: '333d',
      dateFormat: 'dd.mm.yy',
      setDate: field.setDate
    }, options);


    dateInput.datepicker(dpOptions);

    if(typeof field.defDate !== 'undefined')
      dateInput.datepicker('setDate', new Date(+new Date + (field.defDate) * 86400000));

    dateInput.appendTo(block);

    $('<div class="input-addon open-datepicker"/>')
      .click(e => {
        if($(e.target).hasClass('disabled'))
          return
        dateInput.datepicker('show')
      })
      .appendTo(block);

    return block;
  };

};
