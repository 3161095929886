var renderTag = require('../render-tag');
var isoDate = require('../iso-date');
var date = require('./date');

module.exports = function (context) {

  var countNights = require('../count-nights');
  var renderSubFields = {
    info
  };

  return function dates (field, block) {

    renderSubFields.date = date({onClose});

    field.fields.forEach((item, index) => {

      var itemBlock = $(`<div class="${item.type}-form-block"/>`);

      item.index = index + 1;

      if (item.hasOwnProperty('label'))
        renderTag.label(item.id, item.label).appendTo(itemBlock);

      renderSubFields[item.type](item, itemBlock).appendTo(block);
    });

    return block;


    function onClose () {

      var fromVal = $('.date-1', context).val();
      var toVal = $('.date-2', context).val();
      var fromDate = new Date(isoDate(fromVal));
      var toDate = new Date(isoDate(toVal));

      $('.date-2', context).datepicker('option', 'minDate', fromDate);

      if (fromDate >= toDate) {
        if(fromDate > toDate)
          toDate = new Date(fromDate.getTime() + 24 * 60 * 60 * 1000);

        $('.date-2', context).val($.datepicker.formatDate('dd.mm.yy', toDate));
        return setDays(fromDate, toDate);
      }

      setDays(fromDate, toDate);

    }

    function setDays (from, to) {
      var length = countNights(from, to);
      if(length < 0)
        length = 0;
      block.children().find('.days-spent').text(length);
      block.children().find('[type=hidden]').val(length);
    }
  };

  function info (field, block) {
    renderTag.input('hidden', field.id, field.def).appendTo(block);
    $(`<span class="days-spent">${field.def}</span>`).appendTo(block);
    return block;
  }


};


