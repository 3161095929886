var element = require('./gift-element');

module.exports = {
  //Typ input text
  create: function(field){
    element.element(this, field);

    this.render = function() {
      var classAttr ='gf-button gift-gf-button' + this.id;
      var buttonElement = $('<div/>', {
        'class': classAttr
      });
      classAttr += this.customClass ? ' ' + this.customClass : '';

      var button = $('<button/>', {
        id: this.id,
        type: this.type,
        text: this.value
      }).appendTo(buttonElement);

      return buttonElement;
    };

    this.setByView = function() {
    };

    this.postRender = function() {
      //console.log(window[this.id]);
      if(window[this.id] && window[this.id].postRender && typeof window[this.id].postRender === 'function')
        window[this.id].postRender();
    }
  }
}
