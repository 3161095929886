module.exports = {
  //Typ input checkbox
  create: function(field) {
    this.id = field.id;
    this.customClass = field.className || '';
    this.name = field.name;
    this.value = field.value;
    this.valid = true;
    this.templateId = field.templateId;
    this.jqObject = null;

    this.render = function() {
      var classAttr = 'gf-submit';
      if(field.classBlock)
        classAttr += ` ${field.classBlock}`;
      var submitBlock = $('<div/>', {
        'class': classAttr
      });

      var submit = $('<button/>', {
        id: this.id,
        type: 'submit',
        text: this.value,
        class: this.customClass
      }).appendTo(submitBlock);

      return submitBlock;
    };

    this.validate = function() {
      return true;
    };

    this.setByView = function() {};
  }
};
