var renderTag = require('../render-tag');

module.exports = function (onChange) {

  return function (field, block) {

    let checked = field.def || null

  	if(field.heading) {
  		$('<label/>', {
  			'class': 'checkbox-heading',
  			text: field.heading
  		}).appendTo(block);
  	}

    var checkbox = renderTag.input('checkbox', field.id, null, checked).appendTo(block);

    if (typeof onChange === 'function')
      checkbox.change(onChange);

    return block;
  };
};


