module.exports = {
  //Typ input checkbox
  create: function(field){ 
    this.id = field.id;
    this.name = field.name;
    this.value = field.value;
    this.valid = true;
    this.templateId = field.templateId;

    this.jqObject = null;

    this.render = function() {
      var classAttr ='gf-submit gift-gf-submit' + this.id;
      var submitBlock = $('<div/>', {
        'class': classAttr
      });
      classAttr += this.customClass ? ' ' + this.customClass : '';
      var submit = $('<button/>', {
        id: this.id,
        type: 'submit',
        text: this.value
      }).appendTo(submitBlock);

      return submitBlock;
    };

    this.validate = function() {
      return true;
    };

    this.setByView = function() {};
  }
};
