module.exports = function goparkingForm (opts) {

  var self = this;
  var defaults = {
    dataPath: '/opencms/opencms/shared/wc/goparking-form/data.json',
    name: 'goparking-form',
    prefix: 'goparking-',
    method: 'POST'
  };
  var createComponent = require('../component').create.bind(self);
  var renderForm = require('../utils/render-form');
  var onSubmit = require('./actions/submit');

  return createComponent({
    options: $.extend({}, defaults, opts),
    preRenderHook,
    render,
    postInitHook
  });

  function preRenderHook (component) {
    self.data('opts', component.options);
  }

  function render (component) {
    component.renderField = require('./render-field')(self);
    renderForm(component, self);

    setTimeout(function(){
      console.log('heloo from airport index');
      document.getElementById('reservefrom_time-minutes').remove();
      document.getElementById('reserveto_time-minutes').remove();
    }, 200);

  }

  function postInitHook (component, context) {

    // context.find('select').each(function() {
    //   new Select({
    //     el : this,
    //     alignToHighlighted : 'always'
    //   });
    // });

    var toDatepicker = $('#to_date');
    var fromDatepicker = $('#from_date');

    fromDatepicker.datepicker('option', 'onClose', function() {
      if(fromDatepicker.datepicker('getDate') > toDatepicker.datepicker('getDate')){
        toDatepicker.datepicker('setDate', fromDatepicker.datepicker('getDate'));
        var time = fromDatepicker.val().split('.');
        $('input[name="reserve[to_day]"]').val(time[0]);
        $('input[name="reserve[to_month]"]').val(time[1]);
        $('input[name="reserve[to_year]"]').val(time[2]);
      }
    });

  }

};
