var element = require('./gift-element');

module.exports = {
  //Typ input radio
  create: function(field){ 
    element.element(this, field);

    this.chosen = false;
    this.checked = ''; //Bude tam uložená value toho buttonu, ktorý je checked

    this.render = function() {
      var classAttr ='gf-radio gift-gf-radio' + this.id;
      if(!this.valid) 
        classAttr += ' invalid';
      
      classAttr += this.customClass ? ' ' + this.customClass : '';
      var radioElement = $('<div/>', {
        'class': classAttr
      });

      element.renderLabel(radioElement, this);

      element.renderAlert(radioElement, this);

      //Označí prvý radio button ako checked
      var checkFirst = true;
      if(this.checked !== '')
        checkFirst = false;
      field.items.forEach((item) => {
        if(item.checked)
          checkFirst = false;
      });

      var counter = 0;

      field.items.forEach((item) => {
        var singleRadio = $('<div/>').appendTo(radioElement);
        var radio = $('<input/>', {
          id: item.id,
          'type': 'radio',
          name: field.name,
          value: item.value
        }).appendTo(singleRadio);
        counter++;
        if(checkFirst == true){
          if(counter == 1){
            $(radio).attr('checked', true);
            this.checked = item.value;
          }
        }else if(item.checked || this.checked == item.value) {
          $(radio).attr('checked', true);
          this.checked = item.value;
        }

        if(item.label) 
          $('<label/>', {
            html: item.label,
            'for': item.id
          }).appendTo(singleRadio);
        
      });

      return radioElement;
    };

    this.validate = function() {
      var res = true;
      if(!this.checked) {
        res = false;
        this.required_valid = false;
      }
      this.valid = res;
    };

    this.setByView = function() {
      var self = this;
      this.jqObject.find('input[type="radio"]').each(function() {
        if($(this).is(':checked')) 
          self.checked = $(this).val();
        
      });
    };
  }
}
