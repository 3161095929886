/**
 * Funkce pro převod data z formátu dd.mm.yyyy do yyyy-mm-dd
 * @param  {String} pretty datum zapsané ve formátu dd.mm.yyyy;
 * @return {[type]}        [description]
 */
module.exports = function (pretty) {

  var regex = /\d{2}.\d{2}.\d{4}/;

  if (regex.test(pretty)) {

    var parts = pretty.split('.');
    return `${parts[2]}-${parts[1]}-${parts[0]}`;
  } else {
    throw new Error('Nesprávný vstupní formát');
  }

};