var showErrorModal = require('../../utils/show-error-modal');
const utils = require('../../utils/utils');

module.exports = function (context) {
  var destinations = require('../render-destinations')(context);
  var refreshDestinations = require('../../utils/actions/destination')(context).refreshDestinations;

  return {
    setFormType,
    setValuesFromQuery
  };

  function setValuesFromQuery (query) {

    var formType = query.ui_formtype || context.data('opts').formType;

    Object.keys(query).forEach(function (key) {

      var elem = $('#' + key);

      if (elem.length)
        elem.val(decodeURIComponent(query[key]).replace(/\+/g, ' '));

      if (elem.length && elem.attr('type') === 'checkbox')
        elem.attr('checked', (query[key] === 'true'));

      if (key === 'airline')
        query[key].split(',').forEach(function (airline) {
          context.data('form').airlines.push(airline);
        });

      if (key === 'error_message') {
        var error = utils.replaceAll(query[key], '+', ' ');
        context.data('form').errors.push(error);
      }

      if (key.indexOf('destination') > -1)
        if (!elem.length)
          destinations.addRoute(function () {
            $(elem.selector).val(decodeURIComponent(query[key]).replace(/\+/g, ' '));
            refreshDestinations($(elem.selector), context);
          });
        else
          refreshDestinations(elem, context);
    });

    setFormType(formType);

    if (context.data('form').errors.length > 0)
      showErrorModal(context.data('form').errors);
  }

  function setFormType (newType) {

    var formData = context.data('form');

    $('form', context).removeClass(formData.type).addClass(newType);
    formData.type = newType;

    function activateForm (type) {

      formData.routes[0][0].value = formData.defDest ?
        context.data('opts').destinations_departure[type] :
          formData.routes[0][0].value;

      var setType = {
        oneway () {
          return destinations.routes(1);
        },
        round_trip () {
          return (formData.routes.length < 2) ? destinations.addRoute(setReturnValues) : destinations.routes(2, setReturnValues);
        },
        open_jaw () {
          return (formData.routes.length < 2) ? destinations.addRoute() : destinations.routes();
        }
      };

      setType[type]();

      if (formData.defRange && type === 'open_jaw') {
        $('#search_interval').val(0);
      } else {
        $('#search_interval').val(3);

        if(newType === 'round_trip')
          $('#route_2 .departure_date label').text(context.data('opts').messages.departure_date);
      }

      if(window.airticketActivateFormCustom && window.airticketActivateFormCustom[type] && typeof window.airticketActivateFormCustom[type] == 'function')
        window.airticketActivateFormCustom[type]();
    }

    function setReturnValues () {
      $('#arrival_destination_2').val($('#departure_destination_1').val());
      $('#departure_destination_2').val($('#arrival_destination_1').val());
    }

    //Uloženie informácií u súčasnej ceste (od, do)
    var oldRoute = {
      from: $('#departure_destination_1').val(),
      to: $('#arrival_destination_1').val(),
      type: $('input[name=ui_formtype]:checked').val()
    };



    if(oldRoute.from === '')
      oldRoute.from = formData.routes[0][0].value;

    //Asi prehodenie cesty
    activateForm(newType);

    //Nastavenie údajov z predošlej cesty do vymenenej
    //$('#departure_destination_1').val(oldRoute.from);
    //$('#arrival_destination_1').val(oldRoute.to);

    setTimeout(() => $('[name=ui_formtype]').each(function () {
      if ($(this).val() === newType)
        $(this).prop('checked', true);
      else
        $(this).prop('checked', false);
    }));

  }
};