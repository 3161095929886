/**
 * Vykreslení formuláře na základě předaných parametrů
 * @param  {Object}   component Objekt obsahující, veškerá data potřebná pro vykreslení formuláře
 * @param  {Object}   context   jQuery element, do kterého se má formulář vykreslit
 * @param  {Function} onSubmit  Funkce, která obslouží odeslání formuláře
 * @return {Object}             jQuery element formuláře
 */
module.exports = function (component, context, onSubmit) {
  let lastTabindex = component.options.submitTabindex ? component.options.submitTabindex : false
  var renderTag = require('./render-tag');
  var renderField = component.renderField;
  var options = component.options;
  var form = $('<form/>', {
    'class': options.name,
    action: options.action || options.urls.submit,
    method: options.method || 'GET',
    name: options.name,
    novalidate : options.novalidate || false
//    ,autocomplete: options.autocomplete || true
  });

  if(lastTabindex)
    var submitButton = `<button type="submit" class="${options.name}-submit" tabindex="${lastTabindex}">${options.messages.btn_submit || 'Vyhledat'}</button>`;
  else
    var submitButton = `<button type="submit" class="${options.name}-submit">${options.messages.btn_submit || 'Vyhledat'}</button>`;

  // pole s id-ckami, ktere nebudou nalezeny
  var elemetsToRemove = [];
  if(options.htmlTemplate) {
    form.append(options.htmlTemplate);

    form.find('div').map(function() {
      elemetsToRemove.push(this.id);
    });
  }

//    console.log('data:', component.data);
//  console.log(renderField);
    
    
    
    
    
  component.data.forEach((field) => { // pro kazde pole ve formulari
    var className = `${options.prefix}form-group form-group`;
    var id = `${options.prefix}${(field.id || field.type)}-block`;
    var block = renderTag.div('', className, id);

//    var elem_name = field.name || field.id;


    if (field.hasOwnProperty('label') && field.type !== 'checkbox')
      renderTag.label(field.id, field.label)
        .appendTo(block);

    if(options.htmlTemplate) {
      if (typeof renderField[field.type] === 'function') {
        var element = renderField[field.type](field, block);
        var idSelector = `#${field.to}`;
        if(form.find(idSelector).length) {
          element.appendTo(field.to ? form.find(idSelector) : form);
          elemetsToRemove = jQuery.grep(elemetsToRemove, function(value) {
            return value !== field.to;
          });
        }
        else {
          element.appendTo(form);
        }
      } else {
        throw new Error(`component.renderField.${field.type} musí být funkce`);
      }
     } else {
      if (typeof renderField[field.type] === 'function')
        renderField[field.type](field, block)
          .appendTo(form);
      else
        throw new Error(`component.renderField.${field.type} musí být funkce`);
    }

    if (field.hasOwnProperty('label') && field.type === 'checkbox')
      renderTag.label(field.id, field.label)
        .appendTo(block);
  });

  //elemetsToRemove = jQuery.unique(elemetsToRemove);
  elemetsToRemove.forEach((elem) => {
    //form.find(`#${elem}`).remove();
  });

  renderTag.div(submitButton, 'form-submit')
    .appendTo(form);
  renderTag.div('', 'clearfix')
    .appendTo(form);

  form.submit(onSubmit);
  form.appendTo(context);

  return form;
};
