
var checkPassenger = require('./passengerLogic');

module.exports = function (field, block) {
  let initPassengersCount = 0
  var link = $('<a/>', {
    href: '#passengers-modal',
    'data-toggle': 'modal',
    'data-target': '#passengers-modal'
  }).appendTo(block);

  field.fields.forEach((item) => {
    initPassengersCount += item.def

    var itemBlock = $('<div/>', {
      'class': 'passenger ' + item.id,
      'href': '#',
      'data-min': item.min,
      'data-max': item.max,
      'style': 'display: none'
    }).appendTo(link);

    var input = $('<input/>', {
      'class': 'passenger-count',
      'value': item.def,
      'name': item.id,
      'disabled': ''
    }).appendTo(itemBlock);

    if(parseInt(item.def)  <= 0) {
      input.addClass('disabled');
    }
    if(item.def <= 0)
      input.addClass('null');
  });

  let overInput = `
    <label class="control-label" for="total-passangers">Počet osob</label>
    <input id="total-passangers" type="text" disabled value="${initPassengersCount}">
    <span class="passengers-over-input">osoba</span>`

  $(overInput).appendTo(link)

  //Modal okno
  var modal = $('<div/>', {
    'class': 'modal fade',
    'id': 'passengers-modal',
    'tabindex': '-1',
    'role': 'dialog',
    'aria-labelledby': 'passengers-modal-label'
  }).appendTo(block);
  var dialog = $('<div/>', {
    'class': 'modal-dialog modal-sm',
    'role': 'document'
  }).appendTo(modal);
  var modalContent = $('<div/>', {
    'class': 'modal-content'
  }).appendTo(dialog);
  var modalBody = $('<div/>', {
    'class': 'modal-body modal-passenger-body'
  }).appendTo(modalContent);
  var container = $('<div/>', {
    'class': 'container-fluid'
  }).appendTo(modalBody);
  var modalFooter = $('<div/>', {
    'class': 'modal-footer'
  }).appendTo(modalContent);
  $('<button/>', {
    'type': 'button',
    'id': 'modal-passenger-button',
    'data-dismiss': 'modal',
    'html': 'OK'
  }).appendTo(modalFooter);

  var wrapper = $('<div/>', {
    'class': 'modal-passengers-wrapper'
  }).appendTo(container);

  //Objekt ovládačov (+,-, počítadlo, hodnota)
  var controls = [];
  controls.ids = [];

  field.fields.forEach((item) => {
    var id = item.id.split('_')[1];
    controls[id] = {};
    controls.ids.push(id);

    var def = item.def ? item.def : 0;
    var min = item.min ? item.min : 0;
    var max = item.max ? item.max : 6;

    var passenger = $('<div/>', {
      'class': 'modal-passenger ' + item.id
    }).appendTo(wrapper);
    var label = item.label.replace(/&gt;/g, '>').replace(/&lt;/g, '<');
    $('<span/>', {
      html: label,
      'class': 'control-label col-sm-6',
      'for': item.id
    }).appendTo(passenger);
    var counterWrapper = $('<div/>', {
      'class': 'modal-counter-wrapper'
    }).appendTo(passenger);

    var dec = $('<span/>', {
      'class': 'dec',
      'html': '-'
    }).appendTo(counterWrapper);

    //Uložím objekt +
    controls[id].dec = dec;

    var counter = $('<span/>', {
      'class': 'counter',
      'data-for': item.id,
      'html': def
    }).appendTo(counterWrapper);

    //Uložím objekt počítadlo
    controls[id].counter = counter;

    //Uložím hodnotu
    controls[id].val = def;

    var inc = $('<span/>', {
      'class': 'inc',
      'html': '+'
    }).appendTo(counterWrapper);

    //Uložím objekt -
    controls[id].inc = inc;

  });

  //Prvýkrát pustím kontrolu na znemožnenie stláčania tlačidiel
  checkAndDisable(controls);

  //Registrovanie click eventov
  //['adt', 'yth', 'chd', 'inf'].forEach((pass) => {
  controls.ids.forEach((pass) => {

    $(controls[pass].dec).click(function() {
      if($(this).hasClass('disabled'))
        return;
      controls[pass].val--;
      controls[pass].counter.html(controls[pass].val);
      checkAndDisable(controls);
    });
    controls[pass].inc.click(function() {
      if($(this).hasClass('disabled'))
        return;
      controls[pass].val++;
      controls[pass].counter.html(controls[pass].val);
      checkAndDisable(controls);
    });

  });

  function checkAndDisable(controls) {
    var change = checkPassenger(controls.adt.val, controls.yth.val, controls.chd.val, controls.inf.val);
    //['adt', 'yth', 'chd', 'inf'].forEach((pass) => {
    controls.ids.forEach((pass) => {
      controls[pass].dec.removeClass('disabled');
      controls[pass].inc.removeClass('disabled');

      if(!change[pass].remove)
        controls[pass].dec.addClass('disabled');
      if(!change[pass].add)
        controls[pass].inc.addClass('disabled');
    });
  }


  //Po zavretí modalu sa dáta uložia do príslušných počítadiel
  /*$('body').on('hide.bs.modal', '#passengers-modal', function (e) {
    $('#passengers-modal .counter').each(function() {
      var input = $('#airticket-passengerList-block .passenger-count[name="' + $(this).data('for') + '"]');
      input.val($(this).html());
      input.removeClass('null');
      if(parseInt($(this).html()) <= 0)
        input.addClass('null');
    });
  })*/
  $('body').on('show.bs.modal', '#passengers-modal', function (e) {
    $('#airticket-passengerList-block .passenger-count').each(function() {
      var id = $(this).attr('name').split('_')[1];
      controls[id].val = parseInt($(this).val());
      controls[id].counter.html($(this).val());
      checkAndDisable(controls);
    });
  });


  $('body').on('click', '#modal-passenger-button', function (e) {
    let totalPassangers = 0
    $('#passengers-modal .counter').each(function() {
      totalPassangers += parseInt($(this).html())
    })

    $('#total-passangers').val(totalPassangers)

    let passengersText = 'osob'
    let value = totalPassangers.toString()
    if(value.match(/^[2,3,4]$/))
      passengersText += 'y'
    else if(value.match(/^[1]$/))
      passengersText += 'a'

    $('.passengers-over-input').html(passengersText)



    $('#passengers-modal .counter').each(function() {
      var input = $('#airticket-passengerList-block .passenger-count[name="' + $(this).data('for') + '"]');
      input.val($(this).html());
      input.removeClass('null');
      if(parseInt($(this).html()) <= 0)
        input.addClass('null');
    });
  })

return block;

};
