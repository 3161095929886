module.exports = function accomodationForm (opts) {

  var self = this;
  var defaults = {
    name: 'accomodation-form',
    prefix: 'accomodation-',
    dataPath: '/shared/wc/accomodation-form/data.json',
    params: {
      id: opts.id
    }
  };
  var createComponent = require('../component').create.bind(self);
  var renderTag = require('../utils/render-tag');
  var renderForm = require('../utils/render-form');
  var onSubmit = require('./actions/submit');


  return createComponent({
    options: $.extend({}, defaults, opts),
    render,
    preRenderHook
  });

  function preRenderHook (component) {
    self.data('opts', component.options);
  }

  function render (component) {
    /*
    component.renderField = require('./render-field')(self);
    var form = renderForm(component, self, onSubmit);
    */
    renderTag.div('Formulář je v rekonstrukci', '', '').prependTo($('#form-7'));
    /*
    renderTag.input('hidden', 'lang', component.options.lang).prependTo(form);
    renderTag.input('hidden', 'client_encoding', component.options.client_encoding).prependTo(form);
    renderTag.input('hidden', 'id_dealer', component.options.id_dealer).prependTo(form);
    */
  }
};
