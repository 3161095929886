/**
 * Odeslání formuláře
 * @param  {Object} e událost
 * @return {void}
 */
module.exports = function (e) {

  e.preventDefault();

  var context = $(this).parent(); // element na kterém byla komponenta incializována
  var link = context.data('opts').urls.submit; // url pro odeslání

  var dateFrom = $('#vacation-date_from-block input').attr('disabled', true).val();
  var dateTo = $('#vacation-date_to-block input').attr('disabled', true).val();
  $('#vacation-date-block input').val(dateFrom + '-' + dateTo);

  var allFields = $(this).serializeArray();

  var locality = allFields.reduce(function (prev, element) {
    if (element.name === 'locality') {
      return element;
    }
    return prev;
  }, '');

  var queryElements = allFields.reduce(function (queryParts, item) {
    if (!item.value) {
      return queryParts;
    }

    switch (item.name) {
      case 'locality':
        return queryParts;
      case 'hotel_category':
        queryParts.push('hotelCategory=' + item.value);
        return queryParts;
      case 'destination':
        var destinationValue = item.value;
        if (locality.value) {
          destinationValue = locality.value;
        }
        queryParts.push('destination=' + destinationValue);
        return queryParts;
        break;
      default:
        queryParts.push(item.name + '=' + item.value);
        return queryParts;
    }
  }, []);

  var query = queryElements.join('&');

  if (link.indexOf('?')) {
    link += '&' + query;
  } else {
    link += '?' + query;
  }

  window.location = link;
};
