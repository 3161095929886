module.exports = function satisfactionForm (opts, customRenderFunction) {
  var self = this;
  var defaults = {
    name: 'satisfaction-form',
    prefix: 'satisfaction-',
    dataPath: '/shared/wc/satisfaction-form/data.json',
    params: {
      id: opts.id
    }
  };
  var onSubmit = require('./actions/submit');

  var createComponent = require('../component').create.bind(self);
  var renderForm = require('../utils/render-form');

  return createComponent({
    options: $.extend({}, defaults, opts),
    render,
    preRenderHook
  });

  function preRenderHook (component) {
    self.data('opts', component.options);
    self.data('customRenderFunction', customRenderFunction);
  }

  function render (component) {
    component.renderField = require('./render-field')(self);
    renderForm(component, self, onSubmit);
  };

};
