/**
 * Otevření/zavření a vykreslení dialogového okna, používá bootstrapové třídy
 */

module.exports = {
  close: closeModal,
  open: openModal
};
/**
 * Skrytí a následné odstranění dialogu z DOMu
 * @return {void}
 */
function closeModal () {
  var modalWrapp = $('#modal-wrapper');
  modalWrapp.fadeOut();
  modalWrapp.promise().done(function() {
    $(this).remove();
  });
}
/**
 * Vykreslení dialogového okna na základě předaných parametrů
 * @param  {Object}         options         nastavení
 * @param  {String}         options.title   Hlavní titulek dialogového okna
 * @param  {Object, String} options.content jQuery element nebo string obashující obsah těla dialogu
 * @param  {Object, String} options.footer  jQuery element nebo string obashující obsah patičky dialogu
 * @return {Object}         jQuery element
 */
function renderModal (options) {

  var modalClass = 'modal';
  modalClass += options.class ? ' '+options.class : '';

  var modal = $('<div id="modal-wrapper" class="' + modalClass + '"/>');
  var modalContent = $('<div class="modal-content"/>').appendTo(modal);

  var content = {
    header: `<button class="close">&times;</button><h4>${options.title}</h4>`,
    body: options.content,
    footer: options.footer
  };

  for (var prop in content) {
    var part = $(`<div class="modal-${prop}"/>`);
    part.append(content[prop]);
    part.appendTo(modalContent);
  }

  return modal;
}

/**
 * Dosazení dialogu do dokumentu
 * @param  {Object}   options  Nastavení pro vykreslení dialogu
 * @param  {Function} callback Callback funkce zavolaná po dosazení dialogu do DOMu
 * @return {void}
 */
function openModal (options, callback) {

  if ($('#modal-wrapper').length <= 1) {
    var modal = renderModal(options);

    modal.appendTo($('body')).fadeIn();
    $('.close', modal).click(closeModal);

    // zavirame modal okno, pokud klikneme na overlay
    $('#modal-wrapper').on('click', function(e) {
      if ($(e.target).attr('id') == 'modal-wrapper')
        closeModal();
    })

    if (typeof callback === 'function')
      callback();
  }
}