module.exports = {

  //Funguje ako Super constructor, ktorý vždy rovnako nasetuje objekty
  create: function(instance, field) {
    //Nasetovanie základných parametrov
    instance.id = field.id;
    instance.label = field.label;
    instance.name = field.name;
    instance.value = field.value;
    instance.type = field.type;
    instance.templateId = field.templateId;
    instance.customClass = field.className || '';

    instance.valid = field.valid;
    instance.valid_error_message = field.valid_error_message; //"Zadali ste nespravny format" (vratene zo serveru)

    instance.required = field.required;
    instance.required_valid = field.required_valid;
    instance.required_error_message = field.required_error_message; //"Email musi by vyplneny"

    instance.regexp = field.regexp;
    instance.regexp_valid = field.regexp_valid;
    instance.regexp_error_message = field.regexp_error_message; //"meno@domena.cz"

    instance.jqObject = null;

    instance.render = function() {};
    instance.validate = function() {
      var res = true;
      if(instance.required && !instance.value) {
        res = false;
        instance.required_valid = false;
      }
      /*
      if(!instance.regexp...) {
        //TODO
        instance.valid = false;
        instance.regexp_valid = false;
      }
      */
      instance.valid = res;
    };
    instance.setByView = function() {};
  },

  renderAlert(appendElement, instance) {
    var msgs = [];
    if(!instance.valid) {
      /*console.log('not valid. regexp_valid:');
      console.log(instance.regexp_valid);*/
      msgs = [];
      //Ak atribút existuje a je rovný false - teda je to tento typ problému
      if(instance.required_valid != null && instance.required_valid === false)
        msgs.push(instance.required_error_message);

      if(instance.regexp_valid != null && instance.regexp_valid === false)
        msgs.push(instance.regexp_error_message);

      var alert = $('<div/>', {
        'class': 'alert-icon'
      }).appendTo(appendElement);
      var msgWrap = $('<div/>', {
        'class': 'alert-msgs'
      }).appendTo(alert);
      msgs.forEach((msg) => {
        $('<span/>', {
          text: msg
        }).appendTo(msgWrap);
      });
    }
  },

  renderLabel(appendElement, instance) {
    var label = $('<label/>', {
      'for': instance.id,
      html: instance.label + (instance.required === true ? ' *' : '')
    }).appendTo(appendElement);
  }
}
