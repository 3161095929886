/**
 * Funkce pro setrizeni destinaci podle priorit
 * @param  {Object} data data vrace v te serveru
 * @param  {String} query     hledany podvyraz destinace
 * @return {Array} pole setrizenych destinaci
 */
const parseForAutocomplete = require('./parse-for-autocomplete'); // odstraneni diakritiky, prevedeni na lowercase, odstraneni duplikovanych mezer a tabu

module.exports = function orderDestination (data, query) {
  
//  console.log(query);
  var q = decodeURI(query.toLowerCase());
  // Hash mapa index - klic
  var indexis = [];
  // vysledek - setrizena data
  var result = [];
  var city = [];
  // musi existovat city (letenkovy formular)
  if (data.length > 0 && data[0] !== undefined && data[0].city !== undefined) {
    
    
    for (var i = 0; i < data.length; i++) {
      var a = data[i];
      
      var match = 0;

      // vypocet shod
      // IATA
      if (a.value !== undefined && a.value.toLowerCase().indexOf(q) > -1)
        match = 16;

      // Pokud nema iata jedna se o jizdenky a testuj jestli nektere ze slov nezacina presne hledanym retezcem
      
//      if (a.value === undefined)
//      {
        if (beginsWith(q,a.city)) // pokud nekteré slovo začíná
        {
          match += 8;
        }
//      }
      // Pokud nema iata jedna se o jizdenky a testuj jestli nektere ze slov nezacina presne hledanym retezcem
      
      // CITY
      if (a.city !== undefined && a.city.toLowerCase().indexOf(q) > -1)
        match += 4;

      // STATE
      if (a.state !== undefined && a.state.toLowerCase().indexOf(q) > -1)
        match += 2;

      
      // AIRPORT
      if (a.name !== undefined && a.name.toLowerCase().indexOf(q) > -1)
        match += 1;

      // IF ALL-AIRPORTS
      if (a.allAirports !== undefined && a.allAirports === true)
        match += 20;




      var key = getIndex(a.city + '|' + a.statecode, indexis);
      var obj = city[key];

      if (obj === undefined)
        city[key] = new City(a, match);
      else {
        a.match = match;
        obj.cities.push(a);
        if (obj.value < match)
          obj.value = match;
      }
    }

    // setridi podle priorit
    sortByMatch(city.sort(compareByCity));
    // sjednoti vsechny letiste
    for (var ii = 0; ii < city.length; ii++) {
      if(city[ii].cities !== undefined)
        result = result.concat(city[ii].cities);
    }

  } 
  // ##############################################################################################################################
  else if (data.length > 0 && data[0] !== undefined && data[0].cities !== undefined) { 

    var bestGuess = {
      cities: new Array(),
      code:' ',
      country:' ',
      bestGuess:true
    };
    for (var i = 0; i < data.length; i++) { // 3 VYTVORENI noveho objektu destinace s nazvy, ktere odpovidaji pocatku hledaneho vyrazu
      var state = data[i];
      state.cities.forEach(function(city,index) { // projdeme vsechny mesta ve vsech statech a najdeme ty ktere odpovidaji
        var city = state.cities[index];
        city.country = state.country;
        city.locationType = "CITY";
        city.flag = state.code;
        if(beginsWith(q,city.name)) // nektere ze slov zacina presne q!!!
        {
            var cityCopy = jQuery.extend({}, city); // klonovani objektu city
            var newIndex = bestGuess.cities.push(cityCopy)-1;
            bestGuess.cities[newIndex].isBestGuess = true;
            bestGuess.cities[newIndex].country = state.country;
            bestGuess.cities[newIndex].flag = state.code;
            state.cities[index] = null;
        }
        city.stations.forEach((station) => { // prochazime vsechny stanice mesta
          station.country = state.country;
          station.flag = state.code;
          station.locationType = "STATION";
        });
      });
    }
    data = data.filter(function(n){ return n.cities.length != 0; }); // odstraneni prazdnych destinaci
    for (var i = 0; i < data.length; i++) {
      var state = data[i];
      state.cities = state.cities.filter(function(n){ return n != undefined; });  // odstraneni skopirovanych mest
    }
    
    
    if(bestGuess.cities.length > 0)
    {
      data.unshift(bestGuess);
    }
    
    //console.log('newDestinations',data);

    result = data;
  }
  // ##############################################################################################################################
  else{
    result = data;
  }

  return result;
};


// Objekt pro naseptavac
function City (a, val) {
  this.name = a.city + '|' + a.statecode;
  this.value = val;
  a.match = val;
  this.cities = [a];
}

// pseudo hash map
function getIndex (str, arr) {
  for (var i = 0; i < arr.length; i++)
    if (arr[i] === str)
      return i;

  var ind = arr.length;
  arr[ind] = str;
  return ind;
}

  // SETRITI PODLE priorit
function sortByMatch (data) {
  for(var i = 0; i < data.length; i++) {
    data[i].cities.sort(function(a, b) {
      // match (pocet shod)
      var order = b.match - a.match;
      if(order === 0) {
        // city
        order = a.city.localeCompare(b.city, 'cs', {sensitivity: 'variant'});

        if(order === 0) {
          // state
          order = a.state.localeCompare(b.state, 'cs', {sensitivity: 'variant'});
          // airport
          if(order === 0)
            order = a.name.localeCompare(b.name, 'cs', {sensitivity: 'variant'});
        }
      }
      return order;
    });
    return data;
  }
}

// Setridi podle shody na meste
function compareByCity (a, b) {
  return b.value - a.value;
}




// rozebere mesto na slova a testuje jestli nektere slovo nezacina presne q
function beginsWith(parsedQuery,city) {
  var arr = city.split(" ");
  var hasMatch = false;
  arr.forEach(function(element) {
    //if(removeDiacritics(element).toLowerCase().indexOf(removeDiacritics(q).toLowerCase()) === 0) {
    if (parseForAutocomplete(element).indexOf(parsedQuery) === 0) {
      hasMatch = true;
    }
  });
  
  
  return hasMatch;
}


function arraymove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
}

