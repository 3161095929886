/* global module, require */
'use strict';
var removeDuplicates = require('../utils/remove-duplicates');

module.exports = {
  createOptions: createOptions,
  createSets: createSets,
  getKey: getKey,
  sortOffers: sortOffers,
  getDestinations: getDestinations,
  getPrices: getPrices,
  getAirlines: getAirlines,
  getClass: getClass,
  filterOffers: filterOffers,
  showHideLowcosts
};

function createOptions (key, value, array) {

  var options = [];

  array.forEach(function (item) {

    if (typeof item === 'string')
      options.push({key: item, value: item});
    else
      options.push({key: item[key], value: item[value]});
  });

  return options;
}

function createSets (sets, options) {
  var sortedSets = {};
  var optionSets = options.sets;

  sets.forEach(function (set) {
    if (sortedSets[set.set] === undefined) {
      sortedSets[set.set] = {};
      sortedSets[set.set].data = [];
      sortedSets[set.set].name = set.set;
      sortedSets[set.set].expanded = false;
    }

    set['from-name'] = set.journeys[0]['from-name'];
    set['to-name'] = set.journeys[0]['to-name'];
    sortedSets[set.set].data.push(set);
  });

  // kontrola na chybejici sety
  for (var key in optionSets) {
    if (optionSets.hasOwnProperty(key)) {
      if(typeof sortedSets[key] === 'undefined') {
        sortedSets[key] = {};
        sortedSets[key].data = [];
        sortedSets[key].name = key;
        sortedSets[key].expanded = false;
      }
    }
  }

  return sortedSets;
}

function getKey (obj, key) {
  if (!$.isPlainObject(obj))
    return obj.toLowerCase();

  if (typeof obj[key] === 'number')
    return obj[key];

  return obj[key].toLowerCase();
}

function sortOffers (opts) {
  switch (opts.type) {
    case 'abc':
      return function (a, b) {

        if (opts.dir === 'desc')
          return getKey(a, opts.key).localeCompare(getKey(b, opts.key));
        else
          return getKey(b, opts.key).localeCompare(getKey(a, opts.key));
      };
    case 'val':
      return function (a, b) {

        if (opts.dir === 'desc')
          return getKey(a, opts.key) - getKey(b, opts.key);
        else
          return getKey(b, opts.key) - getKey(a, opts.key);
      };
  }
}

function getDestinations (offers) {
  var from = [],
    to = [];

  offers.forEach(function (offer) {
    from.push(offer.journeys[0]['from-name']);
    to.push(offer.journeys[0]['to-name']);
  });
  return {
    from: removeDuplicates(from).sort(sortOffers({type: 'abc', dir: 'desc'})),
    to: removeDuplicates(to).sort(sortOffers({type: 'abc', dir: 'desc'}))
  };
}


function getPrices (offers) {
  var prices = [];
  offers.forEach(function (offer) {
    prices.push(offer.total);
  });
  return prices;
}


function getAirlines (array) {
  var obj = {};
  array.forEach(function (item) {
    obj[item.code] = item.name;
  });
  return obj;
}

function getClass (index, setLimit) {

  return index >= setLimit ? '-removable' : '-default';
}

function filterOffers (filters) {

  return function (offer) {
    var airline = offer['airline-code'].indexOf(filters.airline) > -1;
    var from = offer['from-name'].indexOf(filters.from) > -1;
    var to = offer['to-name'].indexOf(filters.to) > -1;
    var set = offer.set.indexOf(filters.country) > -1;
    var minPrice = offer.total >= filters.minPrice;
    var maxPrice = offer.total <= filters.maxPrice;

    return airline && from && to && minPrice && maxPrice && set;
  };
}

function showHideLowcosts() {
  let lowcostState = true
  $('body').on('click', '#offers-lowcost', () => {
    let actionOfferTable = $('.action-offers-table')
    localStorage.removeItem("checkbox");
    if(lowcostState) {
      actionOfferTable.removeClass('hide-lowcost').addClass('show-lowcost')
      lowcostState = false
      localStorage.setItem("checkbox", true)
    } else {
      actionOfferTable.removeClass('show-lowcost').addClass('hide-lowcost')
      lowcostState = true
    }
  })
}
