// import 'babel-polyfill';

/* global jQuery*/
(function ($) {

  $.support.cors = true;
  $.fn.extend({

    exampleForm: require('./example-form'),
    generalForm: require('./general-forms'),

    //GF pre GiftCertificate - darkovvy poukaz
    generalFormGift: require('./general-forms-gift'),

    accomodationForm: require('./accomodation-form'),
    airticketForm: require('./airticket-form'),
    airticketFormSmall: require('./airticket-form-small'),
    insuranceForm: require('./insurance-form'),
    busRentForm: require('./bus-rent'),
    satisfactionForm: require('./satisfaction-form'),
    carRentForm: require('./car-rent-form'),
    ybusForm: require('./ybus-form'),
    ybusFormGroups: require('./ybus-form-groups'),
    vacationForm: require('./vacation-form'),
    workAbroadForm: require('./work-abroad-form'),
    studyAbroadForm: require('./study-abroad-form'),

    airticketOffersTable: require('./airticket-offers-table'),
    airticketOffersWidget: require('./airticket-offers-widget'),
    giftVoucher: require('./gift-voucher'),
    combobox: require('./combobox'),
    comboboxRj: require('./combobox-rj'), // combo pro regiojet s novym autocomplete
    lightbox: require('./utils/lightbox'),
    waitingScreen: require('./utils/waiting-screen'),

    //Validacia
    validate: require('./utils/tag-validate'),
    //Testovacia verzia
    testValidate: require('./utils/tag-controls/tag-validate'),

    carRentFormBooking: require('./car-rent-form-booking'),
    accomodationFormBooking: require('./accomodation-form-booking'),

    goparkingForm: require('./goparking-form')

  });

})(jQuery);
