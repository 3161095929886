var renderTag = require('../../utils/render-tag');

module.exports = function () {

  return {
    addAirline,
    removeAirline,
    createAirline
  };

  function createAirline(airlines, id, index) {
    var block = $('<div/>', {
      'class': ' air-select-wrapper-block',
      'id': 'air-select-block'
    });
    var select = $('<div/>', {
      'class': 'air-select-wrapper clearfix last-shown-airline shown-airline'
    });

    renderTag.select(id, 'air-select', airlines).appendTo(select);
    $('<button/>', {
      'class': 'airline-select-remove',
      'html': '-',
      'data-index': index,
      'type' : 'button'
    }).appendTo(select);
    $('<button/>', {
      'class': 'airline-select-add',
      'html': '+',
      'type' : 'button'
    }).appendTo(select);

    select.appendTo(block);

    select = $('<div/>', {
      'class': 'air-select-wrapper clearfix'
    });

    renderTag.select(id + '1', 'air-select', airlines).appendTo(select);
    $('<button/>', {
      'class': 'airline-select-remove hide',
      'html': '-',
      'data-index': index,
      'type' : 'button'
    }).appendTo(select);
    $('<button/>', {
      'class': 'airline-select-add',
      'html': '+',
      'type' : 'button'
    }).appendTo(select);

    select.hide().appendTo(block);

    select = $('<div/>', {
      'class': 'air-select-wrapper clearfix'
    });

    renderTag.select(id + '2', 'air-select', airlines).appendTo(select);
    $('<button/>', {
      'class': 'airline-select-remove hide',
      'html': '-',
      'data-index': index,
      'type' : 'button'
    }).appendTo(select);
    $('<button/>', {
      'class': 'airline-select-add',
      'html': '+',
      'type' : 'button'
    }).appendTo(select);

    select.hide().appendTo(block);

    return block;
  }

  //Pridanie selectboxu
  function addAirline(opts, airSelects) {
    /*if(airSelects.length >= 3) {
      return;
    }*/

    //Show one more
    var hiddenElements = $('#air-select-block').find('.air-select-wrapper:hidden');
    
    hiddenElements.first().show().addClass('shown-airline');

    $('.airline-select-remove.hide').removeClass('hide');

    if(hiddenElements.length <= 1)
      $('.airline-select-add').addClass('hide');

    lastShownAirline();

  }

  //Odobratie selectboxu
  function removeAirline(elem, opts, airSelects) {

    //Show one less
    var shownElements = $('#air-select-block').find('.air-select-wrapper:visible');

    if(shownElements.length <= 1)
      return;

    $(elem).closest('.air-select-wrapper').hide().removeClass('shown-airline');

    $('.airline-select-add.hide').removeClass('hide');

    /*if(shownElements.length <= 2)
      $('.airline-select-remove').addClass('hide');*/

    lastShownAirline();

  }

  function lastShownAirline() {
    $('.air-select-wrapper.shown-airline').removeClass('last-shown-airline');
    $('.air-select-wrapper.shown-airline').last().addClass('last-shown-airline');
  }

};

