var renderTag = require('../../utils/render-tag');

module.exports = function (field, block) {

  field.fields.forEach((item) => {
    var itemBlock = renderTag.div('', `${item.id}-block`);
    renderTag.input('radio', item.id, item.value, item.checked).appendTo(itemBlock);
    renderTag.label(item.value, item.label).appendTo(itemBlock);
    itemBlock.appendTo(block);
  });

  return block;
};