module.exports = function (context) {
  var renderTag = require('../utils/render-tag');
  var date = require('../utils/fields/date')(context);
  var select = require('../utils/fields/select');
  var radio = require('../utils/fields/radio');
  var hidden = require('../utils/fields/hidden');

  return {
    date,
    select,
    hidden,
    info (field, block) {

      field.fields.forEach((item) => {

        radio(item, block, function() {
          $('#vacation-date_from-block label').html(item.date_from);
          $('#vacation-date_to-block label').html(item.date_to);
        });
        if (item.hasOwnProperty('label'))
          renderTag.label(item.value, item.label).appendTo(block);
      });

      return block;

    }
  };
};

