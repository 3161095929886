//var renderTag = require('../../utils/render-tag');
var tagRenderer = require('../../utils/tag-render');

module.exports = function (field, block) {

  field.fields.forEach((item) => {
    var itemBlock = tagRenderer.div('', `${item.id}-block form-element`);
    if(item.type === 'select') {
      tagRenderer.label(item.id, item.label).appendTo(itemBlock);
      var options = [];
      item.items.forEach((field) => {
        options.push({value: field.value, name: field.name, selected: field.selected});
      });
      tagRenderer.select(item.id, '', options).appendTo(itemBlock);
    } else {
      tagRenderer.checkbox(item.id, item.value).appendTo(itemBlock);
      tagRenderer.label(item.id, item.label, 'label-checkbox').appendTo(itemBlock);
    }
    itemBlock.appendTo(block);
  });

return block;

};