/**
 * Funkce, která převede querystring do objektu,
 * @param  {String} qs querystring
 * @return {Object}    Výsledný objekt s hodnotami v querystringu
 */
module.exports = function qsToObject (qs) {

  var queryObject = {};

  qs = decodeURIComponent(qs);

  if (qs.length > 0) {
    var parts = qs.replace('?', '').split('&');
    parts.forEach(function (part) {
      var pair = part.split('=');
      if (pair[0] && pair[1])
        queryObject[pair[0]] = pair[1].replace('+', ' ');

    });
  }

  return queryObject;

};