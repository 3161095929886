/**
 * General form - všeobecný formulár
 * ukážka vytvorenia nového typu prvku je v súbore ./docs/new-type.js
 * ukážkový JSON s použitím doteraz vytvorených prvkov je v ./docs/exampleJson.json
 */

//Premenná obsahujúce dáta a funkcie pre prácu s formulárom a jeho prvkami
// import Utils from './utils'
var Utils = require('./utils');


module.exports = function generalForms (opts) {
  // var utils = require('./utils');
  let utils = new Utils()

  if(typeof opts.reloadWithoutParams !== 'undefined' && opts.reloadWithoutParams)
    window.reloadWithoutParams = opts.reloadWithoutParams;

  if(typeof opts.redirectToUrl !== 'undefined' && opts.redirectToUrl)
    utils.redirectToUrl = opts.redirectToUrl;

  utils.form = $('<form/>', {
    'class': opts.noFormClass ? '' : 'gf-form'
  }).appendTo($(this));

  //Načítanie dát cez Ajax
  $.getJSON(opts.dataPath)
  .done(function(res) {
    //Inicializácia premenných v utils
    //Ak bola poslaná šablóna, jej vykreslednie do DOM-u
    //Vytvorenie formulára (všetkých prvkov)
    utils.initialize(res, opts);
    utils.renderTemplate();
    utils.createForm();

    if(!scrollToFirstInvalid() && utils.opts.scrollToForm) {
      scrollToForm();
    }


    //Submit funkcia
    utils.form.on('click', '#' + res.options.submit_id, function(e) {
      e.preventDefault();
      submitForm(res);
    });

    //Volanie custom postRender funkcie, ak existuje vo window objekte
    postRenderFunction();

  })
  .fail(function( jqxhr, textStatus, error ) {
    var err = textStatus + ', ' + error;
    console.log( 'Request Failed: ' + err );
  });


function submitForm(opts) {
  beforeSubmitFunction();

  // nastaveni pro custom validaci
  if(window.customGeneralFormValidate === false)
    return false;

  //Nastavenie premenných podľa aktuálneho stavu HTML
  utils.setByView();
  //console.log('set by view');

  //Ak sú všetky povinné/regexp polia validné, nasleduje
  //serializácia a požiadavka na server
  if(utils.validateObj()) {
    var serial = serialize();

    // odeslani pres POST
		if(typeof opts.options.method !== 'undefined' && opts.options.method === 'POST') {
			var xhr = new XMLHttpRequest();

			xhr.open('POST', utils.data.options.urls.submit);
			xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8');
			xhr.onload = function() {
        let res = JSON.parse(xhr.responseText);
		    if (xhr.status === 200) {
          successfulSubmitFunction(res, opts.options.lang);
		    }
		    else if (xhr.status !== 200) {
					afterResponseFunction();
		    }
			};

			xhr.send($('#form-cestujici form').serialize());
		}
    // odeslani pres GET
    else {
      $.getJSON(utils.data.options.urls.submit, serial)
      .done(function(res) {
        successfulSubmitFunction(res, opts.options.lang);
      })
      .fail(function() {
        afterResponseFunction();
      });
    }

    afterSubmitFunction();
  }
  //Validácia neprešla - formulár sa znova vykreslí
  else {
    utils.renderObj();
    //Volanie custom postRender funkcie, ak existuje vo window objekte
    postRenderFunction();
    if(!scrollToFirstInvalid() && utils.opts.scrollToForm) {
      scrollToForm();
    }
  }
}

function successfulSubmitFunction(res, lang) {
  afterResponseFunction(res);
  utils.data = res;

  //Špecifické pre SA.cz

  //Ak je odpoveď typu form, formulár sa nanovo vykresľuje,
  //inak sa vytvorí Lightbox
  if(res.type === 'form') {
    utils.form.empty();
    utils.renderTemplate();
    utils.createForm();

    //Volanie custom postRender funkcie, ak existuje vo window objekte
    postRenderFunction();
  } else {
    if(window.customGeneralFormRenderResponse && typeof window.customGeneralFormRenderResponse === 'function') {
      window.customGeneralFormRenderResponse(res)
      return
    }

    var ico  = 'sa-icon';
    if(res.type === 'success') {
      ico += ' icon-upsesne';
    } else if(res.type === 'error') {
      ico += ' icon-neuspesne';
    }

    // jazykové mutace pro zavření lightboxu
    let closeLightbox = "";

    if(lang == 'sk') {
      closeLightbox = "Zatvoriť"
    } if(lang == 'de') {
      closeLightbox = "Schließen"
    } if(lang == 'en') {
      closeLightbox = "Close"
    } if(lang == 'cs') {
      closeLightbox = "Zavřít"
    }

    var opts = {
      'type': res.type,
      'message': {
        'icon': ico,
        'title': res.title,
        'content': res.message,
        'close': res.buttonText || closeLightbox
      }
    };

    var lb = $('.gf-form').lightbox;
    lb.create(null, 'icon-lightbox', window.customRenderFunction, opts).hideX().closeOnBackground().onClose(function() {
      if(window.reloadWithoutParams) {
        location.replace(window.location.pathname);
      } else if (utils.redirectToUrl) {
        location.replace(utils.redirectToUrl);
      } else {
        location.reload();
      }
    });
  }
}

//Možnosť vlastnej serializácie ak existuje funkcia generalFormCustomSerialize, a ak je to pre ID tohoto formulára
function serialize() {
  if(window.generalFormCustomSerialize && window.generalFormId && typeof window.generalFormCustomSerialize == 'function' && window.generalFormId == utils.id)
    return window.generalFormCustomSerialize();

  return $(utils.form).serialize();
}

function beforeSubmitFunction() {
  //Volanie custom afterSubmit funkcie, ak existuje vo window objekte
  if(window.generalFormBeforeSubmitFunction && typeof window.generalFormBeforeSubmitFunction == 'function') {
    window.generalFormBeforeSubmitFunction();
  }
}
function afterSubmitFunction() {
  //Volanie custom afterSubmit funkcie, ak existuje vo window objekte
  if(window.generalFormAfterSubmitFunction && typeof window.generalFormAfterSubmitFunction == 'function') {
    window.generalFormAfterSubmitFunction();
  }
}
function afterResponseFunction(res) {
  //Volanie custom afterResponse funkcie, ak existuje vo window objekte
  if(window.generalFormAfterResponseFunction && typeof window.generalFormAfterResponseFunction == 'function') {
    window.generalFormAfterResponseFunction(res);
  }
}

function postRenderFunction() {
  //Volanie custom postRender funkcie, ak existuje vo window objekte
  if(window.generalFormPostRenderFunction && typeof window.generalFormPostRenderFunction == 'function') {
    window.generalFormPostRenderFunction();
  }
}

//Scroll na prvý zle vyplnený prvok
function scrollToFirstInvalid() {
  if(utils.opts.noScroll)
    return false;

  var invalid = $('.invalid');
  if(invalid.length){
    var total = 0;
    var header = $('#header');
    if(header.length) {
      header.children().each(function() {
        total += $(this).outerHeight(true);
      });
    }
    $('html,body').animate({
      scrollTop: invalid.first().offset().top - total
    }, 'slow');
    return true;
  }
  return false;
}

//Scroll po vykreslení formulára. Ak sú všetky elementy valid, tak sa scrollne na začiatok fomulára
function scrollToForm() {
  var total = 0;
  var header = $('#header');
  if(header.length) {
    header.children().each(function() {
      total += $(this).outerHeight(true);
    });
  }
  $('html,body').animate({
    scrollTop: utils.form.offset().top - total
  }, 'slow');
}

};
