/**
 * Funkce pro vykresleni selectu pro vyber jazykove verze formulare.
 * @param  {[type]} component [description]
 * @param  {[type]} context   [description]
 * @return {[type]}           [description]
 */
//require('bootstrap-webpack!./bootstrap.config.js');
module.exports = function languageDropdownF (component, context) {

  var options = component.options;
  var langJson = options.languageDropdown;
  var selectedLang = 'cs';
  var languageDropdown = $('<div/>', {
    'class': 'dropdown',
    'id': 'dropdown-language'
  });
  var toggleBtn = $('<a />', {
    'id': 'dropdownLang',
    'data-toggle': 'dropdown',
    'aria-haspopup': 'true',
    'aria-expanded': 'false',
    'href': '#',
    'class': 'btn btn-default'
  }).append(`<span class="caret"></span>`);

  var dropdownMenu = $('<ul/>', {
    'class': 'dropdown-menu',
    'aria-labelledby': 'dropdownLang'
  });

  options.languageDropdown.forEach((field) => {
    var img = `<img src="${field.imageSrc}" alt="${field.text}">`;
    if (field.selected === true) {
      toggleBtn.prepend(`${img} ${field.text}`);
    } else {
      var li = `<li><a href="#" data-lang="${field.lang}" data-id="${field.id}">${img} ${field.text}</a></li>`;
      dropdownMenu.append(li);
    }
  });

  languageDropdown.append(toggleBtn);
  languageDropdown.append(dropdownMenu);

  // obsluha kliknuti na vyber jazyku
  dropdownMenu.find('a').click( (e) => {
    var id = $(e.target).attr('data-id');
    selectedLang = $(e.target).attr('data-lang');
    // zmena id na aktualne vybrane...kvuli prekladum
    options = $.extend({}, options, {'id': id});

    // zmena vybraneho jazyka v json
    changeSelectedLang(langJson, id);

    $.ajax({
      dataType: 'json',
      url: options.dataPath + `?id=${id}`,
      success: initComponent,
      error: renderError.bind(context)
    });
  });

  // znovu-inicializace
  function initComponent (response) {
    var cmpName = component.options.name;

    component.options = $.extend({}, options, response.options);
    component.data = response.data || response.fields;

    // nahrazeni json languageDropdown na puvodni
    component.options = $.extend({}, component.options, langJson);

    context.empty();

    if (typeof component.preRenderHook === 'function')
      component.preRenderHook(component);

    if (options.preRenderHook && typeof options.preRenderHook === 'function')
      options.preRenderHook(component, context);

    if (component.options.languageDropdown)
      languageDropdownF(component, context);

    // tady se furt prepisovala classa formulare a nebylo nastylovane
    component.options.name = cmpName;

    component.render(component);

    //addDatepickerLocale(selectedLang);
    //changeDatepickerLocale(context, selectedLang);

    if (typeof component.postInitHook === 'function')
      component.postInitHook(component, context);

    if (options.postInitHook && typeof options.postInitHook === 'function')
      options.postInitHook(component, context);

  }

  // vypsani error hlasky pri neuspesnem ajax
  function renderError () {
    this.empty();

    $('<div/>', {
      'class': 'error',
      text: 'Došlo k chybě při získávání dat, prosím zkuste stránku obnovit.'
    }).appendTo(this);

    $('<div/>', {
      'class': 'error',
      text: 'An error occurred while retrieving data, please try refreshing the page.'
    }).appendTo(this);

  }

  // zmena vybrane jazykove lokace v json
  function changeSelectedLang (object, selected) {
    object.forEach((field) => {
      if (field.id === selected)
        field.selected = true;
      else
        field.selected = false;
    });
    return object;
  }

  /**
   * Funkce pro zmenu lokalizace kalendare. TED NAJDOU NEJDE ??
   */

  function addDatepickerLocale (lang) {
    $('head').append(`<script src="http://dl.studentagency.cz/js/jquery-ui-datepicker/jquery.ui.datepicker-${lang}.js" type="text/javascript" ></script> `);
  }

  function changeDatepickerLocale (context, lang) {
    //context.find('.date_picker').datepicker( 'option', $.datepicker.regional[ `${lang}` ] );
    var opt = $.extend({}, $.datepicker.regional[ `${lang}` ], { showOn: 'focus' });
    context.find('.date_picker').datepicker( 'option', opt );
  }

  languageDropdown.appendTo(context);

  return languageDropdown;
};