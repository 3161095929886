module.exports = function (context) {
  var dates = require('../utils/fields/dates')(context);
  var zones = require('./fields/zones');
  var passengers = require('./fields/passengers');
  var passengerList = require('./fields/passengerListInsurance');

  return {
    dates,
    zones,
    passengerList,
    passengers
  };

};

