var element = require('./element');

module.exports = {
  //Typ input hidden
  create: function(field) {
    element.create(this, field);

    this.render = function() {
      var classAttr = 'gf-hidden';
      if(field.classBlock)
        classAttr += ` ${field.classBlock}`;
      classAttr += this.customClass ? ' ' + this.customClass : '';
      var element = $('<div/>', {
        'class': classAttr
      });

      var input = $('<input/>', {
        id: this.id,
        'type': this.type,
        name: this.name,
        value: this.value
      }).appendTo(element);

      return element;
    };

    this.setByView = function() {
      this.value = this.jqObject.find('#' + this.id).val();
    };

    this.validate = function() {
      this.valid = true;
    };

  }
}
