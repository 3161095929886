/**
 * Funkce pro získání posledních hledaných destinací - provede i kontrolu zda destinace existuji a doplni nazvy stanic
 * @param  {Date} fromDate Datum od
 * @param  {Date} toDate   Datum do
 * @return {Number}        Počet dní
 */



module.exports = function getLastDestinations (context,dstCnt) {
  console.log(context.data('opts').routes);
  let allData = window.allData; //velky spatny tady vidim
  console.log('allData set to',window.allData);
  let routesFull = [];

  context.data('opts').routes.forEach((route, index) => {
    if (index < dstCnt) {
      var fromName = findById(allData, route.from.id);
      var toName = findById(allData, route.to.id);

      //pokud se nepodarilo nalezt spravny nazev mesta, nevypiseme nic
      if (typeof fromName === undefined || toName === undefined)        
        return

      routesFull.push({
        from:{ id: route.from.id, name: fromName, type: route.from.type},
        to:  {id: route.to.id, name: toName, type: route.to.type}
      });




    }
  });
  console.log(context.data('opts').routes);
  return routesFull;
};


function findById(allData, findId) {
  for (var i = 0; i < allData.length; i++) {
    var cities = allData[i].cities;
    for (var x = 0; x < cities.length; x++) {
      if (cities[x].id == findId) {
        return cities[x].name;
      }
      if (cities[x].hasOwnProperty('stations')) {
        var stations = cities[x].stations;
        for (var y = 0; y < stations.length; y++) {
          if (stations[y].id == findId) {
            return stations[y].fullname;
          }
        }
      }
    }
  }
}

/* function saved_routes(field, block) {
  var allData = null;
  // Ak nie sú žiadne uložené trasy, schovám celý element
  if (context.data('opts').routes.length <= 0)
    return block.addClass('hide')

  $.getJSON(`${context.data('opts').urls.destinations}destinations-${context.data('opts').lang}.json`)
    .done(data => {
      var destinationsData = data.airports || data.destinations || data;
      var partnersData = data.partners;
      allData = destinationsData.concat(partnersData);

      var routes = $(`<div class="${field.type}"/>`);
      var separator = ' -> ';
      context.data('opts').routes.forEach((route, index) => {
        if (index < 5) {
          var fromName = findById(allData, route.from_id);
          var toName = findById(allData, route.to_id);

          // pokud se nepodarilo nalezt spravny nazev mesta, nevypiseme nic
          if (typeof fromName === undefined || toName === undefined)
            return

          $('<span/>', {
            text: `${fromName + separator + toName}`,
            'class': `${context.data('opts').prefix}saved-route`,
            'data-from': `${route.from_id}`,
            'data-to': `${route.to_id}`,
            click: load_destination
          })
            .appendTo(routes);
        }
      });

      routes.appendTo(block);

    })
    .fail(error => {
      console.error(`Cannot load destination data ${error}`);
      return block.addClass('hide')
    });
  return block;
} */