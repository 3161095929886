module.exports = function giftVoucher(opts) {
  var self = this;
  var name = 'gift-voucher';
  var errotText = 'Došlo k chybě při získávání dat, prosím zkuste stránku obnovit.';
  var errotTextEng = 'An error occurred while retrieving data, please try refreshing the page.';
  var errotTextHtml = 'Chyba pri nacitani HTML stranky.';
  var errotTextHtmlEng = 'An error occurred while loadnig html page.';

  /**
   * [printError] Funkce pro vypis chybove hlasky.
   * @param  {[string]} p_text     [cesky text zpravy]
   * @param  {[string]} p_text_eng [anglicky text zpravy]
   */
  var printError = function printError(pText, pTextEng) {
    self.empty();

    $('<div/>', {
      'class': 'error',
      text: pText
    }).appendTo(self);

    $('<div/>', {
      'class': 'error',
      text: pTextEng
    }).appendTo(self);
  };

  /**
   * [addClearFix] Funkce vrati clearfix div.
   */
  var addClearFix = function addClearFix() {
    return $('<div/>', {
      'class': 'clearfix'
    });
  };

  /**
   * [addInput] Funkce pro vytvoreni inputu.
   * @param {[type]} type [Typ inputu (text, radio)]
   * @param {[type]} name [Atribut name a jmeno pro id]
   * @param {[type]} opts [Dodatecne atributy]
   */
  var addInput = function addInput(type, name, opts) {
    var extras = '',
      tmpName = '';
    $.each(opts, function(key, val) {
      // console.log(key, value);
      if (key === 'required')
        extras += key;
      else
        extras += key + '="' + val + '"';

      extras += ' ';

    });

    if (type === 'radio')
      tmpName = name.slice(0, -2);
    else
      tmpName = name;

    return '<input type="' + type + '" name="' + tmpName + '" id="' + name + '"' + extras + '></input>';
  };

  /**
   * [addLabel] Funkce pro vytvoreni label-u.
   * @param {[type]} name [Atribut name]
   * @param {[type]} lbl  [Obsah label-u]
   * @param {[type]} cls  [Atribut class]
   * @param {[type]} req  [Atribut required]
   */
  var addLabel = function addLabel(name, lbl, cls, req) {
    var label = '';
    if (req)
      label = '<label for="' + name + '" class="' + cls + '">' + lbl + '<sup title="povinná položka">*</sup>' + ':</label>';
    else
      label = '<label for="' + name + '" class="' + cls + '">' + lbl + ':</label>';

    return label;
  };

  // addRule = function addRule(sheet, selector, styles) {
  //     if (!sheet) return;
  //     if (sheet.insertRule) return sheet.insertRule(selector + " {" + styles + "}", sheet.cssRules.length);
  //     if (sheet.addRule) return sheet.addRule(selector, styles);
  // },


  /**
   * [render] Funkce pro vykresleni potrebnych formularovych objektu podle zadaneho json-u.
   * @param  {[type]} data [json data]
   * @return {[type]}      [self]
   */
  var render = function render(data) {
    var fields = data.fields;

    fields.forEach(function(field) {

      switch (field.type) {
        case 'button':
          var button = '<button id="' + field.id + '" class="' + field.class + '">' + field.value + '</button>';
          $(field.position).append(button);
          break;

        case 'submit':
          var submit = '<button id="id_submit_button" class="' + field.class + '" type="' + field.type + '">' + field.label + '</button>';
          $(field.position).append(submit);
          break;

        case 'info':
          var info = field.label;
          $(field.position).text(info);
          $(addClearFix()).insertAfter(field.position);
          break;

        case 'select':
          var formDiv = $('<div/>', {
            'class': 'form-element ' + field.class
          });
          var label = addLabel(field.name, field.label, '', field.required);
          var selectbox = '<select id="' + field.name + '" name="' + field.name + '"></select>';
          var value = field.value;

          $(field.position).append(formDiv);
          $(label).appendTo(formDiv);
          $(selectbox).appendTo(formDiv);

          value.forEach(function(item) {
            $('#' + field.name).append($('<option>', {
              value: item.value,
              text: item.label
            }));
          });
          $(addClearFix()).insertAfter(formDiv);
          break;

        case 'textarea':
          var divTextarea = $('<div/>', {
            'class': 'form-element ' + field.class
          });
          var labelTA = addLabel(field.name, field.label, '', field.required);
          var textarea = '<textarea id="' + field.name + '" name="' + field.name + '" maxlength="60"></textarea>';

          $(field.position).append(divTextarea);
          $(labelTA).appendTo(divTextarea);
          $(textarea).appendTo(divTextarea);
          $(addClearFix()).insertAfter(divTextarea);
          break;

        case 'radio':
          var divRadio = $('<div/>', {
            'class': 'form-element ' + field.class
          });
          var divTitle = '';
          var input = '';
          var valueR = field.value;
          var i = 0;

          if (field.required)
            divTitle = '<div class="title">' + field.label + '<sup title="povinná položka">*</sup>' + '</div>';
          else
            divTitle = '<div class="title">' + field.label + '</div>';

          $(field.position).append(divRadio);
          $(divTitle).appendTo(divRadio);

          valueR.forEach(function(item) {
            var tmp = field.name + '-' + i;
            var lbl = addLabel(tmp, item.label, '', item.required),
              extras = {};

            if (field.required)
              extras.required = 'required';

            extras.value = item.value;

            if (item.img)
              lbl = '<label for="' + tmp + '">' + '<img src="' + item.img + '">' + '</label>';


            input = addInput('radio', field.name + '-' + i, extras);

            $(input).appendTo(divRadio);
            $(lbl).appendTo(divRadio);
            i++;
          });
          $(addClearFix()).insertAfter(divRadio);
          break;

        case 'input':
          var divInput = $('<div/>', {
              'class': 'form-element ' + field.class
            }),
            inputI = '',
            labelI = addLabel(field.name, field.label, '', field.required),
            extras = {};

          if (field.required)
            extras.required = 'required';

          if (field.regx)
            extras.pattern = field.regx;

          inputI = addInput('text', field.name, extras);

          $(field.position).append(divInput);
          $(labelI).appendTo(divInput);
          $(inputI).appendTo(divInput);
          $(addClearFix()).insertAfter(divInput);
          break;

        case 'gift':
          var val = field.value;
          val.forEach(function(item) {
            var certificateDiv = $('<div/>', {
                'class': 'certifikat'
              }),
              img = '<img src="' + item.img + '" name="' + item.name + '" value="' + item.value + '" label="' + item.label + '">';
              /*castka = $('<div/>', {
                'class': 'castka'
              }),
              dedication = $('<div/>', {
                'class': 'dedication'
              });*/

            $(field.position).append(certificateDiv);
            $(img).appendTo(certificateDiv);
            //$(castka).appendTo(certificateDiv);
            //$(dedication).appendTo(certificateDiv);
          });
          break;

        case 'hidden':
          var divHidden = $('<div/>', {
            'class': 'form-element ' + field.class
          });
          var extrasH = {};
          // extras.style = "display: none";
          var inputH = addInput('hidden', field.name, extrasH);
          $(field.position).append(divHidden);
          $(inputH).appendTo(divHidden);
          break;

        default:
          break;
      }
    });

    return self;
  };

  /**
   * [loadPage] Funkce dynamicky nacita html content skrze AJAX volanim XMLHttpRequest()
   * @param  {[string]} odkaz na stranku
   * @return {[type]} html content
   */
  var loadPage = function loadPage(href) {
    var xmlhttp = new XMLHttpRequest();
    xmlhttp.open('GET', href, false);
    xmlhttp.onreadystatechange = function() {
      // status 200: OK, readyState: request finished and response is ready
      if (xmlhttp.readyState !== 4 && xmlhttp.status !== 200) {
        printError(errotTextHtml, errotTextHtmlEng);
        return false;
      }
    };
    xmlhttp.send();
    return xmlhttp.responseText;
  };

  /**
   * [init] Incializacni funkce. Jadro scriptu.
   * @param  {[type]} parametry pro prizpusobeni
   * @return {[type]}
   */
  var init = function init(options) {

    // chci nacitat jine html, pokud neni zadan parametr s cestou jinak nactu default
    if (options.loadHTML && loadPage(options.loadHTML))
      self.append(loadPage(options.loadHTML));
    else if (!options.loadHTML && loadPage(options.loadHTML))
      self.append(loadPage('default.html'));


    var path = (options.hostname || '//dl.studentagency.cz/') + 'wc',
      reqOpts = {
        urlText: options.urlText,
        hostname: options.hostname
      },
      url = options.dataPath ? options.dataPath : path + '/' + name + '/data.json',
      renderComponent = render.bind(this);


    // chci nacitat externi css
    if (options.loadCss)
      $('<link/>', {
        rel: 'stylesheet',
        href: path + '/' + name + '/style.css'
      }).appendTo($('head'));


    var resData = '';

    $.ajax({
      dataType: 'json',
      crossDomain: true,
      url: url,
      data: reqOpts,
      success: function(res) {
        res.options = $.extend(res.options, options);
        resData = res.options;
        renderComponent(res).data('loading', false);
      },
      error: function() {
        self.empty();
        printError(errotText, errotTextEng);
      },
      complete: function(jqXHR, status) {
        if (status === 'success') {
          if (options.loadValidation)
            $('<script/>', {
              type: 'text/javascript',
              src: options.loadValidation
            }).appendTo($('head'));


          var nameFile = options.loadValidation;
          var lastSlash = nameFile.lastIndexOf('/') + 1;
          var lastDot = nameFile.lastIndexOf('.');
          var nameFunc = nameFile.substring(lastSlash, lastDot);

          window[nameFunc](resData);

        } else {
          self.empty();
          printError('Ajax-Complete:' + errotText, 'Ajax-Complete:' + errotTextEng);
        }
      }
    });
  };

  init(opts);

};