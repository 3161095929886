module.exports = function studyAbroadForm (opts) {

  var self = this;
  var defaults = {
    dataPath: '/opencms/opencms/shared/wc/proxy-jp-pp.jsp',
    name: 'study-abroad-form',
    prefix: 'study-',
    params: {
      id: 'jp'
    }
  };
  var createComponent = require('../component').create.bind(self);
  var renderForm = require('../utils/render-form');
  var onSubmit = require('./actions/submit.js');
  var printHeadline = require('../utils/print-headline');

  return createComponent({
    options: $.extend({}, defaults, opts),
    preRenderHook,
    render,
    postInitHook
  });

  function preRenderHook (component) {
    self.data('opts', component.options);
  }

  function render (component) {
    printHeadline(opts, this);
    component.renderField = require('./render-field.js')(component, self);
    renderForm(component, self, onSubmit);
  }

  function postInitHook (component, context) {
    var selectActions = require('./actions/jpSelActions')(component, context);
    var formElement = $('form.study-abroad-form', context);
    var select = $('.study-abroad-form select', context);
    var options = component.options;
    var formType = $('input:radio[name=jp_type]').val(); //0, 1 alebo 2

    select.change(
      selectActions.jp_changeSelect);
    return;
  }

};
