/**
 * Funkcia validate, volajúca sa na jQuery objekte
 * podľa typu elementu
 * @param {String} selector povinný parameter, podľa čoho hľadať element, ktorý má byť validovaný
 * @param {String} type     nepovinný parameter, typ validovaného elementu. Ak nie je zadaný hľadá sa z data-type na volanom elemente
 *
 * Vracia funkciu pre konkrétny typ elementu.
 * Funkcia potom
 * @return true   ak je element validný
 * @return false  ak element nie je validný
 */
module.exports = function validate (selector, type) {

  type = type || $(this).data('type');

  var val = {};

  val.text = function (ele) {
    if($(ele).val()) {
      return true;
    }
    return false;
  }
  val.textarea = function(ele) {
    if($(ele).val()) {
      return true;
    }
    return false;
  }
  val.select = function(ele) {
    if($(ele).val()) {
      return true;
    }
    return false;
  }
  val.checkbox = function(ele) {
    if($(ele).is(':checked')) {
      return true;
    }
    return false;
  }
  val.radio = function(ele) {
    if($(ele).find('input:radio:checked').length === 1) {
      return true;
    }
    return false;
  }
  val.multipleCheckbox = function(ele) {
    if($(ele).find('input:checkbox:checked').length > 0) {
      return true;
    }
    return false;
  }
  
  if(typeof val[type] === 'function') {
    return val[type]($(this).find(selector));
  }
  return true;
};