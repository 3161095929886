/*
@param opts - optiony formu
@param self - form do ktereho apendujeme
*/
module.exports = function printHeader(opts, self) {
  if ((typeof opts.header != "undefined") && opts.header.text != '' && typeof(opts.header.text) === 'string')
    var headerText = $('<h2/>', {
      text : opts.header.text,
      'class' : opts.header.className
    }).appendTo(self);
};