module.exports = function carRentForm (opts) {

  var self = this;
  var defaults = {
    name: 'car-rent-form',
    prefix: 'car-rent-',
    dataPath: '/opencms/opencms/shared/wc/car-rent-form/data.json',
    params: {
      id: opts.id
    }
  };
  var createComponent = require('../component').create.bind(self);
  var renderForm = require('../utils/render-form');
  var renderTag = require('../utils/render-tag');

  return createComponent({
    options: $.extend({}, defaults, opts),
    preRenderHook,
    render
  });

  function preRenderHook (component) {
    self.data('opts', component.options);
  }

  function render (component) {
    /*
    component.renderField = require('./render-field')(self);
    var form = renderForm(component, self, () => {});
    */
    renderTag.div('Formulář je v rekonstrukci', '', '').prependTo($('#form-8'));
    //console.log(self);
    /*
    renderTag.input('hidden', 'lang', component.options.lang).prependTo(form);
    renderTag.input('hidden', 'client_encoding', component.options.client_encoding).prependTo(form);
    renderTag.input('hidden', 'id_dealer', component.options.id_dealer).prependTo(form);
    */
  }

};
