module.exports = function (context) {
  var tagRender = require('../utils/tag-render');
  var renderTag = require('../utils/render-tag');
  var dateField = require('../utils/fields/date')(context);
  var select = require('../utils/fields/select');
  var radio = require('../utils/fields/radio');
  var hidden = require('../utils/fields/hidden');
  // var promo = require('../utils/fields/text-input');
  var opts = context.data('opts');

  return {
    date,
    timeSelect,
    promo,
    infoText
  };

  function date(field, block) {
    // var dateBlock = dateField(field, block);
    // dateBlock.appendTo(block);

    var timeElements = [];
    field.fields.forEach((item) => {
      timeElements.push(tagRender.hidden(item.id, item.def, '').appendTo(block));
    });

    block.addClass('date-field');

    //Nastaví globálne pre datepickery počet otvorených mesiacov
    if(field.monthsNumber)
      $.datepicker.setDefaults({
        numberOfMonths: field.monthsNumber
      });

    var dateInput = renderTag.input('text', field.id, field.def).addClass(`date_picker date-${field.index}`);

    if (field.readonly)
      dateInput.attr('readonly', 'readonly');

    var dpOptions = {
      //numberOfMonths: 1,
      minDate: '0d',
      showButtonPanel: true,
      maxDate: '333d',
      dateFormat: 'dd.mm.yy',
      setDate: field.setDate,
      onSelect
    }

    dateInput.datepicker(dpOptions).on('change', function () {
      onSelect($(this).val());
    });

    function onSelect (val) {
      var time = val.split('.');
      timeElements[0].val(time[0]);
      timeElements[1].val(time[1]);
      timeElements[2].val(time[2]);
    }

    if(typeof field.defDate !== 'undefined')
      dateInput.datepicker('setDate', new Date(+new Date + (field.defDate) * 86400000));

    dateInput.appendTo(block);

    $('<div class="input-addon open-datepicker"/>')
      .click(() =>
        dateInput.datepicker('show'))
      .appendTo(block);

    return block;



    return block;
  }

  function timeSelect(field, block){
    block.addClass('time-field');
    var time = tagRender.hidden(field.id, field.def, '');
    var tmpName = field.id.replace(/[\])}[{(]/g, '');
    var hours = renderTag.select(`${tmpName}-hours`, 'hours', field.hours);
    var minutes = renderTag.select(`${tmpName}-minutes`, 'minutes', field.minutes);

    hours.on('change', function () {
      time.val(hours.val() + ':' + minutes.val());
    });
    minutes.on('change', function () {
      time.val(hours.val() + ':' + minutes.val());
    });

    time.appendTo(block);
    hours.appendTo(block);
    minutes.appendTo(block);
    // $('<div class="clearfix"></div>').appendTo(block);
    return block;
  }

  function promo(field, block) {
    block.addClass('promo-field');
    // tagRender.text(field.id, opts.promoCode, '').appendTo(block);
    if(opts !== undefined){
    renderTag.input('password', field.id, opts.promoCode).appendTo(block);
    }
    var show = $(`<a class="promo-code show-code"/>`).appendTo(block);

    //hide or show password
    show.on('click', function(){
      var togglePass= $(this),
        passwordField = togglePass.prev('input');

      ( 'password' == passwordField.attr('type') ) ? passwordField.attr('type', 'text') : passwordField.attr('type', 'password');
      ( togglePass.hasClass('show-code') ) ? togglePass.removeClass('show-code').addClass('hide-code') : togglePass.removeClass('hide-code').addClass('show-code');
    });
    return block;
  }

  function infoText(field, block) {
    block.addClass('info-field');
    $(`<p>${field.value}</p>`).appendTo(block);
    return block;
  }
};
