var element = require('./element');
//Pre použitie treba načítať <script src='https://ajax.googleapis.com/ajax/libs/jqueryui/1.8.17/jquery-ui.min.js'></script>

/**
 * Formát minDate a maxDate
 * "0" - dnes
 * dátum vo formáte `dateFormat`
 * "+1m +1w" - "y" for years, "m" for months, "w" for weeks, and "d" for days
 */
module.exports = {
  //Typ date picker
  create: function(field) {
    element.create(this, field);
    this.dateOptions = {
      dateFormat: field.dateFormat || 'dd.mm.yy',
      showButtonPanel: field.showButtonPanel || false,
      closeText: field.closeText || 'X',
      prevText: field.prevText || '<',
      nextText: field.nextText || '>',
      minDate: field.minDate || 0,
      maxDate: field.maxDate || '+5m'
    };

    this.readonly = field.readonly || true;

    //$.extend(true, this.dateOptions, field.options);

    this.render = function() {
      var classAttr = 'gf-datepicker';
      if(field.classBlock)
        classAttr += ` ${field.classBlock}`;
      if(!this.valid)
        classAttr += ' invalid';
      classAttr += this.customClass ? ' ' + this.customClass : '';
      var datepickerElement = $('<div/>', {
        'class': classAttr
      });

      element.renderLabel(datepickerElement, this);

      element.renderAlert(datepickerElement, this);

      var datepicker = $('<input/>', {
        id: this.id,
        'type': 'text',
        name: this.name,
        value: this.value,
        readonly: this.readonly
      }).appendTo(datepickerElement);

      $('<div/>', {
        'class': 'input-addon open-datepicker'
      }).click(function() {
        datepicker.datepicker('show');
        //console.log('rendering open datepicker');
      }).appendTo(datepickerElement);
      return datepickerElement;
    };

    this.postRender = function() {
      this.jqObject.find('#' + this.id).datepicker(this.dateOptions);
    };

    this.setByView = function() {
      var d = this.jqObject.find('#' + this.id).datepicker('getDate');
      if(d !== null) {
        var dateTime = new Date(d);
        this.value = formatNumber(dateTime.getDate()) + '.' + formatNumber(dateTime.getMonth() + 1) + '.' + dateTime.getFullYear();
      }
    };

    function formatNumber(number) {
      return (number < 10 ? '0' : '') + number;
    }
  }
};
