module.exports = function (component) {
  var self = this;
  var opts = component.options;
  var msg = opts.messages;
  var data = component.data;
  var helpers = require('./helpers');
  var formatPrice = require('../utils/format-price');
  var address = document.referrer;
  var addressLine = window.location.pathname;


  if(typeof window.airticketOfferTablePostRenderHookFunction === 'function') {
    var numList = [];
    data.forEach((ticket) => {
      numList.push(ticket.id);
    });
    window.airticketOfferTablePostRenderHookFunction(numList);
  }

  var renderHelpers = {

    label (name, title) {
      return '<label class="offers-' + name + '-label" for="offers-' + name + '">' + title + '</label>';
    },

    input (type, name, id) {
      return '<input id="offers-' + name + '" ' + (id ? 'id="' + id + '"' : '') + ' type="' + type + '" class="offers-' + name + '-input">';
    },

    select (field, options) {
      var select = '<select id="select-id-' + field + '" data-key="' + field + '" class="filter-offers form-control" name="offers-' + field + '">';
      select += '<option value="">' + msg.VYBERTE.text + '</option>';

      options.forEach(function (option) {
        select += '<option value="' + option.key + '">' + option.value + '</option>';
      });

      select += '</select>';

      return select;
    },

    renderTableRow (item, index, set, airlines) { //data-url="' + item.url + '"
      let row
      row += '<tr data-url="' + item.url + '" class="offer-row ' + set.name + helpers.getClass(index, opts.setLimit) + '">'
      row += renderHelpers.journey(item.journeys, item.type)

      if (!opts.tripTypeIcons)
        row += '<td class="offer-type">' + (msg[item.type].text || item.type) + '</td>'

      row += '<td class="offer-airline"><div class="airline-logo"><img src="' + opts.logosBaseUrl + item['airline-code'] + '.gif"/><small>' + airlines[item['airline-code']] + '</small></div></td>'
      row += '<td class="offer-price">' + formatPrice(item.total) + ' ' + item.currency + '</td></tr>'

      return row;
    },

    offersFilter (elm) {
      var to = renderHelpers.label('to', msg.KAM.text) + renderHelpers.select('to', helpers.createOptions(0, 0, data.destinations.to) );
      var row = '<tr class="offers-filter">';

      row += '<td class="filter-field filter-from">' + renderHelpers.label('from', msg.ODKUD.text) + renderHelpers.select('from', helpers.createOptions(0, 0, data.destinations.from) ) + '</td>';
      row += '<td class="filter-field filter-empty-field">' + (!opts.tripTypeIcons ? to : '') + '</td>';
      row += '<td class="filter-field filter-to">' + (opts.tripTypeIcons ? to : '') + '</td>';
      row += '<td class="filter-field filter-airways">' + renderHelpers.label('airline', msg.LETECKA_SPOLECNOST.text) + renderHelpers.select('airline', helpers.createOptions('code', 'name', opts.airlines) ) + '</td>';
      row += '<td class="filter-field filter-lowcost">' + renderHelpers.input('checkbox', 'lowcost', 'lowcost') + renderHelpers.label('lowcost', msg.LOWCOST.text) + '</td>';
      row += '<td class="filter-field filter-price"><div>' + renderHelpers.label('price', msg.MAXIMALNI_CENA.text);

//      if (Modernizr.touchevents) {
//        row += '<form id="form-filter-price" action="" method=""><input type="text" id="max-price" name="max-price" value="' + formatPrice(data.filter.maxPrice) + '"><span>' + opts.currency + '</span><div><input type="submit"><i class="fa fa-search fa-2x"></i></div></form>';
//      }
//      else {
        if (opts.slider)
          row += `<span id="max-price"> ${formatPrice(data.filter.maxPrice)} ${opts.currency}</span> <div class="price-slider"></div>`;
//      }

      row += '</div></td></tr>';
      elm.append(row);
    },

    formSubmitFilter () {
      if (Modernizr.touchevents) {

        var input = $(this).find('#max-price'),
            inputValue = parseInt(input.val().replace(/ /g,''));

        renderHelpers.setFilter('maxPrice', inputValue);

        return false;
      }
    },

    journey (routes, tripType) {

      var from = [];
      var to = [];
      var limit = tripType !== 'OPEN_JAW' ? 1 : routes.length;
      var tripIconCell = opts.tripTypeIcons ? `<td class="offer-type-icon ${tripType.toLowerCase()}"></td>` : '';

      for (var i = 0; i < limit; i += 1) {
        from.push(routes[i]['from-name']);
        to.push(routes[i]['to-name']);
      }

      return '<td class="offer-from">' + from.join('<br/>') + '</td>' + tripIconCell + '<td class="offer-to">' + to.join('<br/>') + '</td>';
    },

    set (elm, set, limit, start) {
      var rows = '';
      var airlines = helpers.getAirlines(opts.airlines);
      var tagName = (elm.attr('tagName') || elm.prop('tagName')).toLowerCase();
      var setData = set.data.filter(helpers.filterOffers(data.filter)).sort(helpers.sortOffers(data.order));
      var footerAction = (set.expanded ? msg.SKRYT.text : msg.ZOBRAZIT_DALSI.text);

      limit = limit || setData.length;

      if (set.expanded || setData.length < limit)
        limit = setData.length;

      start = start - 1 || -1;

      setData.forEach(function (item, index) {
        if (item.set === 'sa_lowcost_cz')
          rows += renderHelpers.renderTableRow(item, index, set, airlines)
        else if ( (index < limit && index > start) && item.set !== 'sa_lowcost_cz')
          rows += renderHelpers.renderTableRow(item, index, set, airlines)
      });


      switch (tagName) {
        case 'table':
          if (setData.length > 0) {

            if (setData.length < limit/*setLimit*/)
              footerAction = '';

            // elm.append('<tr class="set-header ' + set.name + '-header"><td colspan="5">' + opts.sets[set.name].message + '</td></tr>');
            // elm.append(rows);
            // elm.append('<tr class="set-footer" data-set="' + set.name + '"><td colspan="5">' + footerAction + '<i class="sa-icon icon-sipka-prava"></i></td></tr>');

            elm.append('<tr class="set-header ' + set.name + '-header"><td colspan="5">' + opts.sets[set.name].message + '</td></tr>');
            elm.append(rows);

            // vypise radek tabulky s "zobrazit vice/mene" jen pokud je pocet nabidek vetsi nez limit
            if (setData.length > limit)
              elm.append('<tr class="set-footer '+ set.name +' " data-set="' + set.name + '"><td colspan="5">' + footerAction + '<i class="sa-icon icon-sipka-prava"></i></td></tr>');

          }
          break;

        case 'tr':
          elm.before(rows);
          break;
      }
    },

    priceSlider () {
      $('.price-slider').slider({
        range: 'min',
        min: Math.min.apply(Math, data.prices),
        max: Math.max.apply(Math, data.prices),
        value: data.filter.maxPrice,
        slide (event, ui) {
          $('#max-price').text(` ${formatPrice(ui.value)} ${opts.currency}`);
        },
        stop (event, ui) {
          renderHelpers.setFilter('maxPrice', ui.value);
        }
      });
    },

    table (limit) {

      helpers.showHideLowcosts()
      $('.action-offers-table').removeClass('show-lowcost').addClass('hide-lowcost')


      var table = $('<table class="action-offers-table hide-lowcost"/>');

      renderHelpers.offersFilter(table);

      Object.keys(opts.sets).forEach(function (key) {
        renderHelpers.set(table, data.sortedSets[key], limit);
      });

      self.empty().append(table);

      $('.filter-offers').each(function () {
        $(this).val(data.filter[$(this).attr('data-key')]);
      });

      if (opts.slider)
        renderHelpers.priceSlider();

      //Zavolanie post render funkcie po vyrenderovani selectboxov
      if(window.airticketOffersTablePostRender && typeof window.airticketOffersTablePostRender == 'function')
        window.airticketOffersTablePostRender();
    },

    setFilter (key, value) {
      data.filter[key] = value;
      renderHelpers.table(opts.setLimit);
    },

    toggleSet (elm, set) {
      if (!data.sortedSets[set].expanded) {
        if(set === 'sa_lowcost_cz') {
          $('.sa_lowcost_cz-default').remove();
        }
        $('.set-footer[data-set=' + set + ']').addClass('clicked');
        elm.find('td').text(msg.SKRYT.text).append('<i class="sa-icon icon-sipka-prava"></i>');
        $('.' + set + '-header td').append('<span class="collapse-set" data-set="' + set + '">' + msg.SKRYT.text + '<i class="sa-icon icon-sipka-prava"></i></span>');
        data.sortedSets[set].expanded = true;
        renderHelpers.set(elm, data.sortedSets[set], null, opts.setLimit);
      } else {
        $('.set-footer[data-set=' + set + ']').removeClass('clicked').find('td').text(msg.ZOBRAZIT_DALSI.text).append('<i class="sa-icon icon-sipka-prava"></i>');
        $('.' + set + '-header td').find('.collapse-set').remove();
        data.sortedSets[set].expanded = false;
        $('.' + set + '-removable').remove();
      }
    }
  };

  renderHelpers.table(opts.setLimit);


  $('body').on('submit', '#form-filter-price', renderHelpers.formSubmitFilter);
  $('body').ready( function() {
    $('.sa_lowcost_cz-removable').remove();
  });

  self.delegate('.set-footer, .collapse-set', 'click', function () {
    var elm = $(this),
    set = elm.attr('data-set');
    renderHelpers.toggleSet(elm, set);
  });

  self.delegate('.filter-offers', 'change', function () {
    var elm = $(this),
    value = elm.val(),
    key = elm.attr('data-key');

    renderHelpers.setFilter(key, value);
  });

  self.delegate('.offer-row', 'click', function () {
    var elm = $(this),
    url = elm.attr('data-url');
    window.location.href = url;
  });

};
