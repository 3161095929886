module.exports = function (e) {

  e.preventDefault();

  var self = this;
  var success = true;
  var opts = $(self).parent().data('opts');
  var customRenderFunction = $(self).parent().data('customRenderFunction');

  //Validacia
  $('div[data-required="true"]').each(function() {
    /**
     * Volanie funkcie jQuery.validate()  //Resp jQuery.testValidate(), čo je testovacia verzia
     * @param selector je trieda, ktoré obsahujú všetky validovateľné prvky ako text, textarea, checkbox...
     * @param type nie je zadaný, lebo typ je uvedený v data-type divu, na ktorom je funkcia volaná
     */
  	if(!$(this).testValidate('.validate-element')) {
      //Element nie je validný
		  $(this).addClass('alert-icon-wrap').css('color', '#ce5151');
  		success = false;
  	} else {
      //Element je validný
      $(this).removeClass('alert-icon-wrap').css('color', '#333');
    }
  })
  
  //Serializácia formulara
  if(success) {
    //Pridanie hodnoty 'true' k označeným checkoxom, aby boli serializované
    $('input:checked').each(function() {
      if($(this).attr('type') !== 'radio') {
        $(this).val('true');
      }
    });

    var data = $('.example-form').serialize();
    data += opts.id ? '&id=' + opts.id : '';

    //console.log('Dáta:');
    //console.log(data);

    send(data);
  } else {
    return false;
  }

  //Funkcia pre odoslanie a spracovanie odpovede zo serveru
  function send(data) {
    $.ajax({
      url: opts.urls.submit,
      data: data,
      dataType: 'json'})
      .done(function(res) {
        //console.log('Uspesne zvalidovane a odoslane. Odpoved:');
        //console.log(res);
      });
  };
};