var letterizeData = require('../../utils/letterize-data');
var renderTag = require('../../utils/render-tag');
var modal = require('../../utils/modal');
var printFormatted = require('../../utils/print-formatted');
var orderDestinations = require('../../utils/order-destination');
const parseForAutocomplete = require('../../utils/parse-for-autocomplete'); // odstraneni diakritiky, prevedeni na lowercase, odstraneni duplikovanych mezer a tabu


let destinationsData = null;
// let partnersData = null;
let allData = null;
let opts = null;
/**
 * Funkce která vrátí objekt s metodami pro obsluhu pole typu destination
 * @param  {object} options           nastavení
 * @param  {string} options.detail    určuje jaký se má zobrazit detail při zvolení destinace / co bude v našeptávači (airport || city)
 * @param  {string} options.template  formát, který určí jak zobrazovat možnosti v našeptávači fornma {}
 * @param  {object} context           jQuery element
 * @return {object}                   objekt obashující metody pro obsluhu bole
 */

module.exports = function (options, context) {
  var destObject = null;
  $.getJSON(`${context.data('opts').urls.destinations}destinations-${context.data('opts').lang}.json`)
          .done(data => {
            destinationsData = JSON.stringify(data.airports || data.destinations || data);
            window.destinationsData = data
            // partnersData = JSON.stringify(data.partners);
            context.data('opts').carrier = data.options.messages.carrier;
            context.data('opts').autocompleteNoObject = data.options.messages.autocompleteNoObject;

            opts = data.options;
            allData = sortData();
          })
          .fail(error => {
            console.error(`Cannot load destination data ${error}`);
          });

  return {
    refreshDestinations,
    showDestinations,
    getWhispers
  };

  function sortData() {
    let originDestinations = JSON.parse(destinationsData)
    // originPartners = JSON.parse(partnersData);

    // IE fix findIndex()
    if (!Array.prototype.findIndex) {

      Array.prototype.findIndex = function (predicate) {
        if (this === null) {
          throw new TypeError('Array.prototype.findIndex called on null or undefined');
        }
        if (typeof predicate !== 'function') {
          throw new TypeError('predicate must be a function');
        }
        var list = Object(this);
        var length = list.length >>> 0;
        var thisArg = arguments[1];
        var value;

        for (var i = 0; i < length; i++) {
          value = list[i];
          if (predicate.call(thisArg, value, i, list)) {
            return i;
          }
        }
        return -1;
      };
    }

    // reorder array
    Array.prototype.move = function (from, to) {
      this.splice(to, 0, this.splice(from, 1)[0]);
    };

    // zmena poradi pole na zakladade parametru
    Array.prototype.moveStates = function (stateCodes) {
      var self = this;
      stateCodes = stateCodes.reverse();
      stateCodes.map(code => {
        var index = self.findIndex(function (element) {
          return element.code === code;
        });
        self.move(index, 0);
      });
      return self;
    };

    // setrideni dle lokalniho jazyka
    var sortStateOpt = ['CZ', 'SK'];
    switch (context.data('opts').lang) {
      case 'sk':
        sortStateOpt = ['SK', 'CZ'];
        break;
      case 'en':
        sortStateOpt = ['UK'];
        break;
      case 'de':
        sortStateOpt = ['DE'];
        break;
      default:
        // cs
        sortStateOpt = ['CZ', 'SK'];
        break;
    }

    originDestinations.moveStates(sortStateOpt);
    // originPartners.moveStates(sortStateOpt);

    return originDestinations
  }

  function showDestinations(e) {
    var fieldId = $(e.target).prev().attr('id');
    renderDestinations(fieldId);
  }

  /**
   * Setridi mesta ve statech dle lokalniho jazyku
   * @param  {object} data              data destinaci
   * @param  {string} context           context forumalre, z nej bereme lokalni jazyk
   * @return {object}                   vraci setridene mesta
   */
  function sortCitiesByLocalelanguage(data, context) {
    for (let i = 0; i < allData.length; i++) {
      data[i].cities.sort((a, b) => {
        return a.name.localeCompare(b.name, context.data('opts').lang, {sensitivity: 'variant'});
      });
    }
    return data
  }

  function renderDestinations(fieldId) {
    var destinations = renderTag.div('', 'destinations').data('input', `#${fieldId}`);
    var handleItemClick = options.showDetail ? showDestinationDetail : selectItem;
    if (typeof options.renderDestinations === 'function') {
      allData = sortCitiesByLocalelanguage(allData, context)
      var content = options.renderDestinations(allData, handleItemClick, opts);
    }

    content.appendTo(destinations);
    modal.open({
      id: 'destinations-modal',
      title: `${opts.messages.headline}`,
      content: destinations
    });
  }

  function selectItem() {
    var inputId = $('.destinations').data('input');

    //Nastavenie idčka vybratého mesta do skrytého inputu (buď 'from' alebo 'to')
    $(inputId + '_id').val($(this).data('id'));

    $(inputId).val($(this).text())
            .trigger('keyup')
            .data('noMatch', false) // zruseni priznaku proti odeslani, kdyz nebyla nalezena v autocomplete zadna odpovidajici destinace (zde ji primo vybirame ze seznamu)
            .removeClass('invalid')
            .parent().removeClass('invalid')
            .data('submit-value', $(this).attr('id'));

    var modalWrapp = $('#modal-wrapper');
    modalWrapp.fadeOut();
    modalWrapp.promise().done(function () {
      $(this).remove();
    });

    $('#select-station_short').val($(this).attr('data-station-id'))
    // context.data('form').defDest = false;
  }

  function refreshDestinations(input) {
  }

  function getWhispers(req, res, query) {

    let whispererData = sortData();

    // dekoduje string, jinak nejde vyhledavat s diakritikou
    query = decodeURI(query);
    let parsedQuery = parseForAutocomplete(query);

    let returnDestinations = whispererData.filter(state => {
      if (parseForAutocomplete(state.country).indexOf(parsedQuery) >= 0)
        return true;

      state.cities = state.cities.filter(city => {
        if (parseForAutocomplete(city.name).indexOf(parsedQuery) >= 0)
          return true;

        if (city.stations.length > 1){
          city.showStationName = true; // kdyz ma mesto vice nez jednu zastavku, zobrazime popis zastavky
        }
        city.stations = city.stations.filter(station => {
          if (parseForAutocomplete(station.fullname).indexOf(parsedQuery) >= 0){
            return true;
          }
          station.aliases = station.aliases.filter(alias => {
            if (parseForAutocomplete(alias).indexOf(parsedQuery) >= 0){
              return true;
            }
          });
          return station.aliases.length >= 1;
        });
        return city.stations.length >= 1;
      });
      return state.cities.length >= 1;
    });
    
   
    
// vytvoreni itemu zadne shody
    if (returnDestinations.length === 0){
      returnDestinations = new Array({
        noMatch: true
      });
      res(returnDestinations);
    } else {
      res(orderDestinations(returnDestinations, parsedQuery));
    }
  }
};
