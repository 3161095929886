var renderTag = require('../render-tag');

module.exports = function (field, block, action) {

  field.index = field.index || '';

  var select = renderTag.select(field.id + field.index, field.id, field.items);

  if (typeof action === 'function')
    select.change(action);

  select.appendTo(block);

  return block;
};