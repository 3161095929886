module.exports = checkPassenger;

function checkPassenger(adt, yth, chd, inf) {
  var sucetAdtYth = adt + yth;
  var sucet = sucetAdtYth + chd + inf;

  var change = {
    adt: {},
    yth: {},
    chd: {},
    inf: {}
  }

  if(sucet >= 9) {
    //Nemozem pridat
    change.adt.add = change.yth.add = change.chd.add = change.inf.add = false;
    //Mozem odobrat
    remove();
  } else if(sucet <= 1) {
      //Nemozem odobrat
    change.adt.remove = change.yth.remove = change.chd.remove = change.inf.remove = false;
    //Mozem pridat
    add();
  } else {
    //Mozem pridat
    add();
    //Mozem odobrat
    remove();
  }

  function add() {
    //Mozem pridat inf?
    change.inf.add = (sucetAdtYth > inf);

    //Mozem pridat chd?
    change.chd.add = (sucetAdtYth > 0 && chd < 5);

    //Mozem pridat yth?
    change.yth.add = (yth < 5);

    //Mozem pridat adt?
    change.adt.add = (adt < 5);
  }

  function remove() {
    //Mozem odobrat inf?
    change.inf.remove = (inf > 0);

    //Mozem odobrat chd?
    change.chd.remove = (chd > 0);

    //Mozem odobrat yth?
    //Predpokladam, ze mozem odobrat
    change.yth.remove = true;
    //Ak je nula, nemozem odobrat
    /*if(yth <= 0) {
        change.yth.remove = false;
    }*/
    change.yth.remove = !(yth <= 0);
    //Ak stale mozem odobrat a sucet zodpovednych (adult + youth) bude po odobrati mensi ako infant, nemozem odobrat
    /*if(!change.yth.remove && sucetAdtYth <= inf) {
        change.yth.remove = false;
    }*/
    change.yth.remove = change.yth.remove && !(sucetAdtYth <= inf);
    //Ak stale mozem odobrat, ak pocet deti je nenulov a po odbrati nezostane ani jeden zodpovedny, nemozem odobrat
    /*if(!change.yth.remove && chd > 0 && sucetAdtYth <= 1) {
        change.yth.remove = false;
    }*/
    change.yth.remove = change.yth.remove && !(chd > 0 && sucetAdtYth <= 1);

    //To ist pre Adult
    change.adt.remove = true;
    change.adt.remove = !(adt <= 0);
    change.adt.remove = change.adt.remove && !(sucetAdtYth <= inf);
    change.adt.remove = change.adt.remove && !(chd > 0 && sucetAdtYth <= 1);
  }
  return change;
}