var element = require('./element');

module.exports = {
  //Typ input multiple select
  create: function(field) {
    element.create(this, field);

    this.render = function() {
      var classAttr = 'gf-multiple-select';
      if(field.classBlock)
        classAttr += ` ${field.classBlock}`;
      if(!this.valid) 
        classAttr += ' invalid';
      
      classAttr += this.customClass ? ' ' + this.customClass : '';
      var multipleSelectElement = $('<div/>', {
        'class': classAttr
      });

      element.renderLabel(multipleSelectElement, this);

      element.renderAlert(multipleSelectElement, this);

      field.items.forEach((item) => {
        var singleSelect = $('<div/>', {
          'class': 'gf-sub-select',
          id: 'gf-sub-select-' + item.id
        }).appendTo(multipleSelectElement);
        var select = $('<select/>', {
          id: item.id,
          name: item.name
        }).appendTo(singleSelect);

        item.items.forEach((item) => {
          $('<option/>', {
            text: item.label,
            value: item.value
          }).appendTo(select);
        });
      });

      return multipleSelectElement;
    };

    this.validate = function() {
      this.valid = true;
    };

    this.setByView = function() {
      var instance = this;
      instance.value = [];
      instance.jqObject.find('select').each(function() {
        instance.value.push($(this).val());
      });
      //console.log(instance.value);
    };
  }
};
