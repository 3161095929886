var element = require('./element');

module.exports = {
  //Typ input checkbox
  create: function(field) {
    element.create(this, field);

    this.checked = false;

    this.render = function() {
      var classAttr ='gf-checkbox';
      if(field.classBlock)
        classAttr += ` ${field.classBlock}`;
      if(!this.valid) 
        classAttr += ' invalid';
      
      classAttr += this.customClass ? ' ' + this.customClass : '';
      var checkboxElement = $('<div/>', {
        'class': classAttr
      });

      var checkbox = $('<input/>', {
        id: this.id,
        name: this.name,
        'type': this.type,
        value: this.value,
        'checked': field.checked
      }).appendTo(checkboxElement);

      element.renderLabel(checkboxElement, this);

      element.renderAlert(checkboxElement, this);

      return checkboxElement;
    };

    this.validate = function() {
      var res = true;
      if(this.required)
        if(this.checked) 
          res =  true;
        else {
          this.required_valid = false;
          res = false;
        }
      
      this.valid = res;
    };

    this.setByView = function() {
      this.checked = this.jqObject.find('#' + this.id).is(':checked');
    };
  }
}
