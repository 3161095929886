/*
@param wsWidth - sirka waiting-screenu
@param wsHeight - vyska waiting-screenu
@param lang - jazyk
@param timer - jak dlouho bude waiting-screen zobrazeno
@param icons - rolujuci ikonky, predava se jako string
*/
module.exports = function waitingScreen(wsWidth, wsHeight, lang, timer, icons) {
	var self = this;

	var imgsPath = [],
		companyCodes = icons.split(','),
		processedImages = 0,
		bottomText = [
		'Skupina STUDENT AGENCY <br> = největší prodejce letenek<br> = nejnižší ceny letenek.',
		'Profesionální vízový servis, <br>výhodné cestovní pojištění, <br>rezeravce ubytování a pronájem auta.',
		'Koupí letenky u STUDENT AGENCY <br>podpoříte rozvoj moderní <br>a dostupné dopravy v ČR.'
	];

	var ws = $('<div/>', {id: "ws", "data-language": lang}).appendTo(self),
		wsHeader = $('<div/>', {class: "ws-header"}).appendTo(ws),
		wsCounter = $('<div/>', {class: "ws-counter"}).appendTo(ws),
		wsCounterInner = $('<span/>').appendTo(wsCounter),
		wsRollingLeft = $('<div/>', {class: "ws-rolling-left"}).appendTo(ws),
		wsRollingRight = $('<div/>', {class: "ws-rolling-right"}).appendTo(ws),
		wsRollingLeftWrap = $('<div/>', {class: "ws-rolling-left-wrap"}).appendTo(wsRollingLeft),
		wsRollingRightWrap = $('<div/>', {class: "ws-rolling-right-wrap"}).appendTo(wsRollingRight),
		wsTextBottom = $('<p/>', {class: "ws-text-bottom"}).appendTo(ws).html(bottomText[0]);

	// prirazujeme vysku hlavnimu elementu
	ws.innerWidth(wsWidth);
	ws.innerHeight(wsHeight);
	ws.css({'margin-top' : '-' + wsHeight / 3 + 'px'});

	/* 
	*  prochazime icons, testujeme, zda obrazek existuje tak, ze vytvorime novy objekt Image() a spoustime na nej metodu onload
	*  pokud se obrazek nacte, ulotime si jeho src do pole
	*/ 
	for (var i = 0; i < companyCodes.length; i++) {
		var img = new Image();
		img.onload = function() {
			imgsPath.push(this.src);
			processedImages++;
		}
		img.onerror = function () {
			processedImages++;
		}
		img.src = 'http://let.studentagency.cz/airline-logos/' + companyCodes[i] + '.gif';
	}

	// interval kotroluje, zda se jiz vytvorily vsechny pozadovane obrazky
	var checker = setInterval(function(){
		if(companyCodes.length == processedImages) {
			clearInterval(checker);
			runScreen();
		}
	}, 10);

	function runScreen() {
		// prirazujeme imgs do divek
		for (var i = 0, j = 1; i < imgsPath.length; i += 2, j += 2) {
			wsRollingLeftWrap.append('<img src="' + imgsPath[i] + '">');
			if (i != imgsPath.length - 1) {
				wsRollingRightWrap.append('<img src="' + imgsPath[j] + '">');
			}			
		}

		var textBottom = $('#ws .ws-text-bottom');
		var counter = $('#ws .ws-counter span')
		var imgs = $('#ws > div[class^="ws-rolling"] > div img');
			
		var i = 0;
		counter.html(timer);
		var countDown = function() {
			if (timer != 0) {
				timer--;
				counter.html(timer);
				// text v dolnim odstavci menime kazdych 5 sekund
				if (timer % 5 === 0) {
					textBottom.fadeOut(400, function() {
						i++;
						(i == bottomText.length) ? (i = 0) : '';
						textBottom.html(bottomText[i]).fadeIn(600);
					})
				}
				setTimeout(countDown, 1000);
			}
			else {
				clearTimeout(countDown);
				var waitingBlock = $('#bg-waiting-window');
				body.removeClass('is-loading');
				return false;
			}
		}
		setTimeout(countDown, 1000);

		var loaded = 0;
		// kontrolujeme, zda se nacetli jiz vsechny obrazky (loaded === imgs.length)
		imgs.load(function() {
			loaded++;
		    if (loaded === imgs.length) {
				var rightWrap = $('.ws-rolling-right-wrap');
				var rightWrapOffestTop = $('.ws-rolling-right-wrap').offset().top;
				var firstElementRight = $('#ws .ws-rolling-right-wrap img:first-child').outerHeight();
				
				var leftWrap = $('.ws-rolling-left-wrap');
				var leftWrapOffestTop = $('.ws-rolling-left-wrap').offset().top;
				var firstElementLeft = $('#ws .ws-rolling-left-wrap img:first-child').outerHeight();

				var inf = function() {

					rightWrap.css("top",  '-=' + 1 + 'px');
					leftWrap.css("top",  '-=' + 1 + 'px');

					if (rightWrap.position().top < (firstElementRight * (-1))) {
						rightWrap.css("top",  '0px');
						rightWrap.find('img:first-child').appendTo(rightWrap);
						firstElementRight = rightWrap.find('img:first-child').outerHeight();
					}
					if (leftWrap.position().top < (firstElementLeft * (-1))) {
						leftWrap.css("top",  '0px');
						leftWrap.find('img:first-child').appendTo(leftWrap);
						firstElementLeft = leftWrap.find('img:first-child').outerHeight();
					}
					setTimeout(inf, 21);
				}
				wsRollingLeft.addClass('transparent');
				wsRollingRight.addClass('transparent');
				inf();
		    }
		});
	}
};