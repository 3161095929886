var isoDate = require('../../utils/iso-date.js');

module.exports = function (context) {

  return function getDatepickerDate (type, index) {

    var date = new Date();
    var cases = {
      min: getDate(index - 1),
      max: getDate(index + 1)
    };
    var time = new Date(cases[type]).getTime();

    date.setTime(time);

    return $.datepicker.formatDate('dd.mm.yy', date);

    function getDate (index) {
      var route = context.data('form').routes[index];
      if (route)
        return isoDate(route[2].value);
      else
        return null;
    }

  };

};


