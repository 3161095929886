//var renderTag = require('../../utils/render-tag');
var tagRenderer = require('../../utils/tag-render');
var date = require('../../utils/fields/date')({});
var isoDate = require('../../utils/iso-date');

module.exports = function (field, block) {

  field.fields.forEach((item) => {
    var itemBlock = tagRenderer.div('', `${item.id}-block form-element`);
    tagRenderer.label(item.id, item.label).appendTo(itemBlock);
    switch(item.type) {
      case 'multiple-select':
        //Zmenit json. Type musi byt rangeSelect
        //tagRenderer.multiple(item.id, 'validate-element', item.fields).appendTo(itemBlock);
      break;
      case 'date':
        date(item, itemBlock);
      break;
      case 'text':
        tagRenderer.text(item.id, item.value, 'validate-element').appendTo(itemBlock);
      break;
    }
    itemBlock.appendTo(block);
  });

  return block;

  function onClose () {
      var fromVal = $('.date-1', context).val();
      var toVal = $('.date-2', context).val();
      var fromDate = new Date(isoDate(fromVal));
      var toDate = new Date(isoDate(toVal));

      $('.date-2', context).datepicker('option', 'minDate', fromDate);

      if (fromDate >= toDate) {
        $('.date-2', context).val($.datepicker.formatDate('dd.mm.yy', fromDate));
        return setDays(fromDate, toDate);
      }

      setDays(fromDate, toDate);

    }
};