var letterizeData = require('../letterize-data');
var renderTag = require('../render-tag');
var modal = require('../modal');
var printFormatted = require('../print-formatted');
var orderDestinations = require('../order-destination');

/**
 * Funkce která vrátí objekt s metodami pro obsluhu pole typu destination
 * @param  {object} options           nastavení
 * @param  {string} options.detail    určuje jaký se má zobrazit detail při zvolení destinace / co bude v našeptávači (airport || city)
 * @param  {string} options.template  formát, který určí jak zobrazovat možnosti v našeptávači fornma {}
 * @param  {object} context           jQuery element
 * @return {object}                   objekt obashující metody pro obsluhu bole
 */
module.exports = function (options, context) {

  var destObject = null;

  return {
    refreshDestinations,
    showDestinationDetail,
    showDestinations,
    getWhispers,
    setDestObject,
    getDestObject
  };

  function setDestObject(data) {
    destObject = data;
  }
  function getDestObject() {
    return destObject;
  }

  function showDestinations (e) {

    var fieldId = $(e.target).prev().attr('id');
    if(destObject === null) {
      $.ajax({
        type: 'GET',
        url: context.data('opts').urls.states,
        success (data) {
          destObject = data;

          renderDestinations(fieldId)
        }
      });
    } else {
      renderDestinations(fieldId);
    }
  }

  function renderDestinations(fieldId) {
    var data = destObject;
    // na olymp7 se pouziva states
    var dest = data.destinations || data.states || data;

    // BUSY
    if(dest[0].cities !== undefined) {
      data.destinations = data.destinations.sort(statesComparator);
      data.count = 0;

      data.destinations.forEach((destination) => {
        destination.cities.forEach((city) => {
          data.count += city.stations.length;
        });
        destination.cities = destination.cities.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });
      });
    }

    var destinations = renderTag.div('', 'destinations').data('input', `#${fieldId}`);
    var handleItemClick = options.showDetail ? showDestinationDetail : selectItem;
    if (typeof options.renderDestinations === 'function')
      var content = options.renderDestinations(data, handleItemClick);
    else
      content = letterizeData(dest, handleItemClick);

    content.appendTo(destinations);

    modal.open({
      id: 'destinations-modal',
      title: `${data.headline}`/* <small>${data.count}</small>*/,
      content: destinations
    });
  }

  function showDestinationDetail () {

    var id = $(this).attr('id');
    var stateName = $(this).text();

    $.ajax({
      type: 'GET',
      url: `${context.data('opts').urls[options.detail]}${id}`,
      success (data) {

        // TODO: zdrojová data pro našeptávač by se měla sjednotit pod property items
        var items = data.airports || data.cities || data.destinations || data;
        var contentName = `${options.detail}-content`;
        var content = renderTag.div('', contentName);

        $('.destinations').fadeOut();

        renderTag.div('<i class="fa fa-chevron-left"></i> ' + context.data('opts').messages.back || 'zpět', 'btn btn-xs btn-aqua')
          .click(() => {
            $(`.${contentName}`).empty().fadeOut();
            $('.destinations').fadeIn();
          })
          .prependTo(content);

        $(`<h3>${stateName}</h3>`).appendTo(content);

        items.forEach((item) => {

          var itemText = printFormatted(options.template, item);

          renderTag.div(itemText, options.detail, item.value)
            .click(selectItem)
            .appendTo(content);

        });

        content.appendTo($('.modal-body'));
      }
    });
  }

  function selectItem () {

    var inputId = $('.destinations').data('input');

    //Nastavenie idčka vybratého mesta do skrytého inputu (buď 'from' alebo 'to')
    $(inputId + '_id').val($(this).data('id'));

    $(inputId).val($(this).text())
      .trigger('keyup')
      .data('submit-value', $(this).attr('id'));

    var modalWrapp = $('#modal-wrapper');
    modalWrapp.fadeOut();
    modalWrapp.promise().done(function() {
      $(this).remove();
    });

    context.data('form').defDest = false;
  }

  function refreshDestinations (input) {
    var index = input.parents('.route').data('index');
    var indx = (input.data('index') < 0) ? 0 : input.data('index');

    if (context && context.data('form')) { // provede se, jen pokud existuje context.data('form'), jinak hazi chybu u jizdenek na hp

      context.data('form').routes[index][indx].value = input.val();

      if (context.data('form').type === 'round_trip') {

        if (input.attr('id') === 'departure_destination_1')
          $('#arrival_destination_2').val(input.val());

        if (input.attr('id') === 'arrival_destination_1')
          $('#departure_destination_2').val(input.val());
      }

      context.data('form').defDest = false;

    }
  }

  function getWhispers (req, res, query) {
    $.ajax({
      type: 'GET',
      contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
      url: `${context.data('opts').urls.query}${query}`,
      success (data) {
        // TODO: zdrojová data pro našeptávač by se měla sjednotit pod property items
        var items = data.airports || data.destinations || data;
        res(orderDestinations(items, query));
      }
    });
  }
};

function statesComparator(a, b) {
  if (a.country === undefined)
    return a.name.localeCompare(b.name);
  else {
    if(a.country === 'Česko')
      return -1;
    if(b.country === 'Česko')
      return 1;
    if(a.country === 'Slovensko')
      return -1;
    if(b.country === 'Slovensko')
      return 1;

    return a.country.localeCompare(b.country);
  }
}
