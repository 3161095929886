var element = require('./gift-element');

module.exports = {
  //Typ input hidden
  create: function(field){ 
    element.element(this, field);

    this.render = function() {
      var classAttr ='gf-hidden gift-gf-hidden' + this.id;
      classAttr += this.customClass ? ' ' + this.customClass : '';
      var element = $('<div/>', {
        'class': classAttr
      });

      var input = $('<input/>', {
        id: this.id,
        'type': this.type,
        name: this.name,
        value: this.value
      }).appendTo(element);

      return element;
    };

    this.setByView = function() {
      this.value = this.jqObject.find('#' + this.id).val();
    };

    this.validate = function() {
      return true;
    };

  }
}
