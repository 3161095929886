var renderTag = require('../utils/render-tag');
// var autofocus = require('./actions/autofocus')();

module.exports = function renderField (component, context) {

  var formActions = require('./actions/form')(context);
  var airlineActions = require('./actions/airlines')(context);
  var passengerLogic = require('./actions/passengers');
  var destinations = require('./render-destinations')(context).destinations;
  var checkbox = require('../utils/fields/checkbox')();
  var select = require('../utils/fields/select');
  var hidden = require('../utils/fields/hidden');

  var passengerList = require('./actions/passengerList');

  var airlineSelects = require('./actions/airlineSelects')();

  return {
    checkbox,
    select,
    ui_formtype,
    destinations,
    passengers,
    airline,
    select_airline,
    passengerList,
    hidden,
    saved_routes
  };

  function ui_formtype (field, block) {

    window.airticketFormTypes = {};

    field.fields.forEach((item) => {

      var formTypeRadio = renderTag.input('radio', field.id, item.id)
        .click((e) => {
          // autofocus.reset()
          formActions.setFormType($(e.target).val())
        })
        .appendTo(block);


      renderTag.label(field.id, item.label)
        .attr('data-formtype', item.id)
        .addClass('formtype-label')
        .click((e) => {
          // autofocus.reset()
          formActions.setFormType($(e.target).attr('data-formtype'));
          return false;
        })
        .appendTo(block);

      for (var k in item.default_value) {
        if (item.default_value.hasOwnProperty(k)) {
          $(formTypeRadio).data(k, item.default_value[k]);
        }
      }

      window.airticketFormTypes[item.id] = item.default_value;
      //console.log(item.default_value);
      //console.log(component.formTypes);

    });


    return block;
  }

  function passengers (field, block) {

    field.fields.forEach((item) => {

      var passenger = $('<div class="passenger abc"/>');
      var label = item.label.replace(/&gt;/g, '>').replace(/&lt;/g, '<');

      component.options.limits['#' + item.id] = item.items.length;

      /*var start = label.indexOf('(');
      var end = label.indexOf(')');
      if (start !== -1 && end !== -1){
        var name = label.substr(0, start);
        var bracket = label.substr(start, end);
        label = `${name}<span class="age">${bracket}</span>`;
      }*/

      renderTag.label(item.id, label).appendTo(passenger);
      renderTag.select(item.id, '', item.items).change(() => {
        passengerLogic(component.options.limits);
      }).appendTo(passenger);
      passenger.appendTo(block);
    });

    return block;
  }

  function select_airline(field, block) {
    //Pole obsahujúce všetky selectboxy
    var airSelects = [];
    var opts = {}
    opts.block = block;
    opts.field = field;
    opts.iter = 0;


    $.ajax({
      type: 'GET',
      url: component.options.urls.airlines,
      success (data) {
        opts.data = data;

        /*
        opts.data.airlines.unshift({
          'name': 'OneWorld',
          'value': 'ali-O'
        }, {
          'name': 'StarAlliance',
          'value': 'ali-A'
        }, {
          'name': 'SkyTeam',
          'value': 'ali-S'
        });
        */

        renderTag.input('hidden', field.id).appendTo(block);

        var select = airlineSelects.createAirline(data.airlines, field.id + '-' + opts.iter.toString(), 0);
        opts.iter++;

        select.appendTo(block);

        airSelects.push(select);

        if(window.airSelectPostRenderFunction && typeof window.airSelectPostRenderFunction == 'function')
          window.airSelectPostRenderFunction();
      }
    });

    $('body').on('click', '.airline-select-add', function() {
      airlineSelects.addAirline(opts, airSelects);
    });
    $('body').on('click', '.airline-select-remove', function() {
      airlineSelects.removeAirline(this, opts, airSelects);
    });

    return block;
  }

  function airline (field, block) {
    renderTag.input('text', field.id, field.def).attr('id', field.id)
      .keyup((e) => {
        context.data('form').airlines = [];

        $(e.target).val().split(',').forEach((item) => {
          if (item.length > 0)
            context.data('form').airlines.push(item);
        });

      })
      .appendTo(block);

    renderTag.div('', 'input-addon open-modal')
      .click(airlineActions.showAirlines)
      .appendTo(block);



    return block;
  }

  /**
   * Vykreslení pole s uloženými cestami
   * @param  {Object} field data k poli
   * @param  {Object} block rodičovský element
   * @return {Object}       jQuery element
   */
  function saved_routes (field, block) {
    if(context.data('opts').routes.length <= 0)
      return block.addClass('hide');

    var routes = $(`<div class="${field.type}"/>`);
    var separator2 = ' -> ';
    var separator = ' <i class="fa fa-long-arrow-right" aria-hidden="true"></i> ';
    context.data('opts').routes.forEach((route, index) => {
      if(route.from === '' || route.to === '' || route.from === undefined || route.to === undefined )
        return

      if (index < 3)
        $('<span/>', {
          'html': `${getContentBetweenBrackets(route.from)}${separator}${getContentBetweenBrackets(route.to)} `,
          'title': `${route.from}${separator2}${route.to} `,
          'class': `${context.data('opts').prefix}saved-route`,
          'data-from': `${route.from_id}`,
          'data-to': `${route.to_id}`,
          'data-from-text': `${route.from}`,
          'data-to-text': `${route.to}`,
          'data-text': `${route.from}${separator2}${route.to}`,
          click: load_destination
        })
        .appendTo(routes);
    });

    routes.appendTo(block);
    return block;
  }
  /**
   * nacitani destinaci do vyhledavace z poslednich hledanych
   */
  function load_destination() {
    // var dsts = $(this).text().split(' -> ');
    var dsts = $(this).attr('data-text').split(' -> ');

    $('input[name=departure_destination_1]').val(dsts[0]);
    $('input[name=arrival_destination_1]').val(dsts[1]);

    var formData = context.data('form');
    formData.routes[0][0].value = dsts[0];
    formData.routes[0][1].value = dsts[1];
    formData.defDest = false;

    // // id
    // $('[name=destination_from_id]').val($(this).data('from'));
    // $('[name=destination_to_id]').val($(this).data('to'));

  }

  function getContentBetweenBrackets(text) {
    var regExp = /\(([^)]+)\)/g;
    var matches = text.match(regExp);
    var str = '';
    if(matches)
      for (var i = 0; i < matches.length; i++) {
        if (i !== 0)
          str += ', ';

        str += matches[i].substring(1, matches[i].length - 1);
      }
    else
     str = text;

    return str;
  }
};
