var renderTag = require('../render-tag');
var printFormatted = require('../print-formatted');
var utils = require('../utils');
var removeDiacritics = require('../remove-diacritics');
var tagRenderer = require('../tag-render');
var lastDestinationsPopup = require('../../ybus-form/last-destinations-popup');

String.prototype.replaceAll = function(search, replacement) {
    var target = this;
    return target.split(search).join(replacement);
};

module.exports = function (options, context){
  if(options['destinations_tree_view']){
    var actions = require('../actions/destinationTreeView')(options, context);
  }else{
    var actions = require('../actions/destination')(options, context);
  }
  // pokud jsou v options nastaveny lolani akce, pouzijeme je
  if(typeof options.useLocalDestinations !== 'undefined' && options.useLocalDestinations.state){
    actions = require(`../../${options.useLocalDestinations.path}/actions/destination`)(options, context);
  }
  return function (field, block) {
    let selected = false
    let autocomplete = null

    field.value = field.value || field.def;

    block.addClass('destination-field');

    //Načítať parameter z url, uložiť idčka staníc
    /*field = */handleUrlStation(field);

    var inputIndex = field.inputIndex || 0;
    var destination = renderTag.input('text', field.id, field.value, null, field.placeholder, field.selectOnClick, field.tabindex)
    var destinationHidden = tagRenderer.hidden(`${field.id}_short`, '', '').appendTo(block)

    // Určuje, či môžem uložiť hodnotu. Ak je false, znamená to, že autocomplete bol práve otvorený a teda nebudem prepisovať input
    // ak je true, znamená to, že focus bol získaný šípkami a teda zmením input
    let setVal = false

    var autocompleteOptions = {
      // minLength: 3,
      minLength: 1,
      source (req, res) {
        selected = false
        actions.getWhispers(req, res, encodeURIComponent($(':focus').val()))
      },
      open (e, ui) {
        autocomplete = $('.ui-autocomplete:visible')
        setVal = false
        var menu = $(this).data("uiAutocomplete").menu

        let item = autocomplete.find(`.ui-menu-item:not(.ui-state-disabled)`) // vyberu prvni item ktery nema classu disabled
        if (item.length ) {
          
          $(':focus').data('noMatch', false);
          $(':focus').data('locationType', item.locationType);
          $(':focus').removeClass('invalid')
          $(':focus').parent().removeClass('invalid')
          
          menu.focus(null, item);
        }
        else
        {
          $(':focus').data('noMatch', true)// pridame priznak, ze nebyla v autocomplete nalezena zadna shoda - nepujde odeslat submitem
          $(':focus').addClass('invalid')
          $(':focus').parent().addClass('invalid')
        }
      },

      focus (e, ui) {
        if(setVal) {
          $(':focus').val(printFormatted(options.template, ui.item));
        } else {
          setVal = true;
        }
        return false;
      },
      select (e, ui) {
        
        selected = true
        var templateSelect = options.templateSelect ? options.templateSelect : options.template;
        $(':focus').val(printFormatted(templateSelect, ui.item))
          .trigger('keyup')
          .data('submit-value', ui.item.value)
          .data('locationType', ui.item.locationType);

        //Nastavenie skrytého inputu aj po vybratí položky z našeptávača
        var inputId = $(e.target).attr('id');
        $('#' + inputId + '_id').val(ui.item.id);

        destinationHidden.val(ui.item.value);

        return false;
      },
      close (e, ui) {
        let focus = $(`#${field.id}`);        
        if (focus.val() !== '' || focus.val() === undefined ) { // pokud je neco zadano
          if (!selected) {
            let item = autocomplete.find('.ui-menu-item:not(.ui-state-disabled):focus, .ui-menu-item:not(.ui-state-disabled)');
            if (item !== undefined && item.length) {
              var selectedItem = item.first().data().item;
              //console.log('selected item',selectedItem.id, selectedItem.locationType);
              $(e.target).val(printFormatted(options.template, item.first().data('item')));
              focus.removeClass('invalid');
              focus.data('noMatch', false);
              focus.data('locationType', selectedItem.locationType);
              focus.data('submit-value', selectedItem.id);

              //Nastavenie skrytého inputu aj po vybratí položky z našeptávača
              var inputId = $(e.target).attr('id');
              $('#' + inputId + '_id').val(selectedItem.id);
            }
            else // pokud neni nalezena zadna odpovidajici destinace
            {
              focus.data('noMatch', true);
              focus.addClass('invalid');
            }
          }
        }
      },
      // autoFocus: true
    };

    destination.autocomplete(autocompleteOptions);
    destination.data('index', inputIndex);
    destination.attr('autocomplete','off'); // vypnuti autofill pro pole typu destination



    destination.data('ui-autocomplete')._renderItem = options.renderAutocompleteItem || renderAutocompleteItem;
    if (typeof options.renderAutocompleteMenu === 'function')
      destination.data('ui-autocomplete')._renderMenu = options.renderAutocompleteMenu;
    destination.appendTo(block);

    renderTag.div('', 'input-addon open-modal')
      .click(actions.showDestinations)
      .appendTo(block);

    return block;

    function renderAutocompleteItem (ul, item) {

      //ar flagUrl = '/shared/data/national-flags';
      //Dočasné pre olymp7, keď bude z olymp7 presunuté, zmazať
      var flagUrl = 'https://www.studentagency.cz/shared/data/national-flags';
      var li = $('<li/>').data('item', item);
      var img = $(`<img src="${flagUrl}/${item.statecode}.gif" alt="${item.statecode}"/>`);
      var link = $('<a/>', {
        text: printFormatted(options.template, item)
      });

      img.prependTo(link);
      link.appendTo(li);
      return li.appendTo(ul);
    }
  };

  function handleUrlStation(field) {
    const urlParam = field.urlParam || field.id;
    var param = utils.getUrlParameter(urlParam);
    if(param && typeof param === 'string') {
      param = param.replaceAll('+', ' ').replaceAll('*', ' - ');
      //field.value = param;
    }
    else
      return;// field;

    var destObject = actions.getDestObject();

    if(!destObject) {
      $.ajax({
        type: 'GET',
        url: context.data('opts').urls.states,
        success (data) {
          actions.setDestObject(data);
          destObject = data;
          field = setUrlStation(field, param, destObject);
          //return field;
        }
      });
    } else {
      field = setUrlStation(field, param, destObject);
      //return field;
    }
  }
  function setUrlStation(field, param, data) {

    // na olymp7 se pouziva states
    const dest = data.destinations || data.states || data;

    const station = findStation(param, dest);
    if(!station)
      return field;

    field.value = station.name;
    const hiddenInput = $('#' + field.id + '_id');
    //Skrytý input už je vykreslený, tak mu treba zmeniť value
    if(hiddenInput.length) {
      hiddenInput.val(station.id);
    } else {
      window.hiddenTypeId = {};
      window.hiddenTypeId[field.id + '_id'] = station.id;
    }
    $('#' + field.id).val(station.name);

    return field;
  }
  function findStation(param, dest) {
    var id = null;
    var name = null;
    var station = false;
    //param je string, teda názov stanice/mesta
    if(isNaN(param)) {
      name = removeDiacritics(param);
      dest.forEach((country) => {
        country.cities.forEach((city) => {
          if(removeDiacritics(city.name) === name) {
            id = city.id;
            station = {id: id, name: city.name};
            return station;
          }
          city.stations.forEach((oneStation) => {
            if(removeDiacritics(oneStation.fullname) === name) {
              id = oneStation.id;
              station = {id: id, name: oneStation.fullname};
              return station;
            }
          });
        });
      });
    }
    //param je číslo
    else {
      id = parseInt(param);
      dest.forEach((country) => {
        country.cities.forEach((city) => {
          if(city.id === id) {
            name = city.name;
            station = {id: id, name: name};
            return station;
          }
          city.stations.forEach((oneStation) => {
            if(oneStation.id === id) {
              name = oneStation.fullname;
              station = {id: id, name: name};
              return station;
            }
          });
        });
      });
    }

    return station;
  }
};
