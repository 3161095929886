/** 
 * Ukážkový formulár na použitie tag controlerov
 *
 * Vykresľovanie formulárových elementov
 * tag-render.js    - controler je použitý v súbore fields-form.js
 *
 * Validácia formulárových prvkov
 * tag-validate.js  - controler je použitý v súbore actions/submit.js
 *
 * Dáta
 * data.json        - ukážkový formát dát
 *
 * Volanie v HTML súbore
 * <div id="test"/>
 * <script>
 *   $(document).ready(function () {
 *     $('#test').exampleForm({'dataPath': 'data.json'});
 *   });
 * </script>
 */

module.exports = function exampleForm (opts, customRenderFunction) {
  var self = this;
  var defaults = {
    name: 'example-form',
    prefix: 'example-form-',
    dataPath: 'data.json',
    params: {
      id: opts.id
    }
  };
  var onSubmit = require('./actions/submit');

  var createComponent = require('../component').create.bind(self);
  var renderForm = require('../utils/render-form');

  return createComponent({
    options: $.extend({}, defaults, opts),
    render,
    preRenderHook
  });

  function preRenderHook (component) {
    self.data('opts', component.options);
    self.data('customRenderFunction', customRenderFunction);
  }

  function render (component) {
    component.renderField = require('./render-field')(self);
    renderForm(component, self, onSubmit);
  };

};
