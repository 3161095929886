var openModal = require('./modal').open;
/**
 * Pomocná funkce pro zobrazení dialogového okna s chybou
 * @param  {Array} errors Pole obsahující chybové hlášky
 * @return {void}
 */
module.exports = function showError (errors) {

  var ul = $('<ul/>');

  errors.forEach(function (err) {

    $('<li/>', {
      'class': 'error-mesage',
      text: err
    })
    .appendTo(ul);
  });

  openModal({
    content: ul,
    id: 'error-modal',
    title: 'Chyba',
    templateUrl: 'error-dialog'
  });
};