var renderTag = require('../utils/render-tag');
// var autofocus = require('./actions/autofocus')();

module.exports = function renderField (component, context) {

  var formActions = require('./actions/form')(context);
  var airlineActions = require('./actions/airlines')(context);
  var passengerLogic = require('./actions/passengers');
  var destinations = require('./render-destinations')(context).destinations;
  var checkbox = require('../utils/fields/checkbox')();
  var radio = require('../utils/fields/radio');
  var select = require('../utils/fields/select');
  var hidden = require('../utils/fields/hidden');

  var passengerList = require('./actions/passengerList');

  var airlineSelects = require('./actions/airlineSelects')();

  return {
    checkbox,
    select,
    travel_class,
    ui_formtype,
    destinations,
    passengerList,
    hidden,
    saved_routes
  };

  function travel_class(field, block) {
    field.fields.forEach((item) => {
      var formTypeRadio = renderTag.input('radio', field.id, item.id, item.checked).appendTo(block);

      renderTag.label(item.id, item.label)
        .attr('data-formtype', item.id)
        .addClass('formtype-label').appendTo(block);
    });

    return block
  }

  function ui_formtype (field, block) {

    window.airticketFormTypes = {};

    field.fields.forEach((item) => {
      var formTypeRadio = renderTag.input('radio', field.id, item.id)
        .click((e) => {
          // autofocus.reset()
          formActions.setFormType($(e.target).val())
        })
        .appendTo(block);

      renderTag.label(field.id, item.label)
        .attr('data-formtype', item.id)
        .addClass('formtype-label')
        .click((e) => {
          // autofocus.reset()
          formActions.setFormType($(e.target).attr('data-formtype'));
          return false;
        })
        .appendTo(block);

      for (var k in item.default_value) {
        if (item.default_value.hasOwnProperty(k)) {
          $(formTypeRadio).data(k, item.default_value[k]);
        }
      }

      window.airticketFormTypes[item.id] = item.default_value;
    });


    return block;
  }

  /**
   * Vykreslení pole s uloženými cestami
   * @param  {Object} field data k poli
   * @param  {Object} block rodičovský element
   * @return {Object}       jQuery element
   */
  function saved_routes (field, block) {
    if(context.data('opts').routes.length <= 0)
      return block.addClass('hide');

    var routes = $(`<div class="${field.type}"/>`);
    var separator2 = ' -> ';
    var separator = ' <i class="fa fa-long-arrow-right" aria-hidden="true"></i> ';
    context.data('opts').routes.forEach((route, index) => {
      if(route.from === '' || route.to === '')
        return

      if (index < 3)
        $('<span/>', {
          'html': `${getContentBetweenBrackets(route.from)}${separator}${getContentBetweenBrackets(route.to)} `,
          'title': `${route.from}${separator2}${route.to} `,
          'class': `${context.data('opts').prefix}saved-route`,
          'data-from': `${route.from_id}`,
          'data-to': `${route.to_id}`,
          'data-from-text': `${route.from}`,
          'data-to-text': `${route.to}`,
          'data-text': `${route.from}${separator2}${route.to}`,
          click: load_destination
        })
        .appendTo(routes);
    });

    routes.appendTo(block);
    return block;
  }
  /**
   * nacitani destinaci do vyhledavace z poslednich hledanych
   */
  function load_destination() {
    // var dsts = $(this).text().split(' -> ');
    var dsts = $(this).attr('data-text').split(' -> ');

    $('input[name=departure_destination_1]').val(dsts[0]);
    $('input[name=arrival_destination_1]').val(dsts[1]);

    var formData = context.data('form');
    formData.routes[0][0].value = dsts[0];
    formData.routes[0][1].value = dsts[1];
    formData.defDest = false;

    // // id
    // $('[name=destination_from_id]').val($(this).data('from'));
    // $('[name=destination_to_id]').val($(this).data('to'));

  }

  function getContentBetweenBrackets(text) {
    var regExp = /\(([^)]+)\)/g;
    var matches = text.match(regExp);
    var str = '';
    if(matches)
      for (var i = 0; i < matches.length; i++) {
        if (i !== 0)
          str += ', ';

        str += matches[i].substring(1, matches[i].length - 1);
      }
    else
     str = text;

    return str;
  }
};
