/**
 * Utils
 * obsahuje všetky potrebné premenné
 * a funkcie pre prácu s renderovaním prvkov, naviazaním súčasného stavu HTML do premenných a validáciou
 */

module.exports = class Utils {
  // this = this
  /**
   * Inicializácia premenných na začiatku práce s formulárom,
   * pridanie ID formuláru
   * @param {Object} data   Dáta prijaté AJAX-ovým volaním zo servera
   */
  initialize(data, opts) {
    this.reloadWithoutParams = opts.reloadWithoutParams;
    this.opts = opts;
    this.data = data;
    this.id = this.data.options.form_id;
    this.form.attr('id', this.id);
    this.form.addClass(this.data.options.form_class);
    this.formObjects = [];
  }

  /**
   * Naplnenie poľa objektov formulárových prvkov a ich vykreslenie
   */
  createForm() {
    this.createTypes(this.data.fields);
    this.renderObj(this.form);
  }

  /**
   * Vykreslenie šablóny do vnútra formulára
   */
  renderTemplate() {

    if(this.data.options.template !== null)
      $(this.form).html(this.data.options.template);

  }

  /**
   * Prevede hodnotu search parametru do objektu ?klic=hodnota => {klic: 'hodnota'}
   */
  setValueFromQuery() {
    const setValueFromQuery = require('../utils/qs-to-object');

    return setValueFromQuery(window.location.search)
  }

  /**
   * Naplnenie poľa objektov formulárových prvkov podľa typu prvku sa vyberie správny objekt (súbor), na ktorom je potom volaná metóda create().
   * Jej návratova hodnota predstavuje konkrétny formulárový prvok a je pridaná do poľa týchto prvkov.
   * @param {Object} fields   objekt obsahujúci info o form. prvkoch
   */
  createTypes(fields) {
    //Reset poľa
    this.formObjects = [];

    const keysFromQuery = this.setValueFromQuery()

    fields.forEach((field) => {
      // nastavi hodnotu ze search parametru do value fieldu
      if(typeof keysFromQuery[field.id] !== 'undefined')
        field.value = keysFromQuery[field.id]

      var elemType = {};
      switch (field.type) {
      case 'text':
        elemType = require('./elements/text');
        break;
      case 'email':
        elemType = require('./elements/email');
        break;
      case 'textarea':
        elemType = require('./elements/textarea');
        break;
      case 'checkbox':
        elemType = require('./elements/checkbox');
        break;
      case 'select':
        elemType = require('./elements/select');
        break;
      case 'radio':
        elemType = require('./elements/radio');
        break;
      case 'datepicker':
        elemType = require('./elements/datepicker');
        break;
      case 'multipleCheckbox':
        elemType = require('./elements/multiple-checkbox');
        break;
      case 'multipleSelect':
        elemType = require('./elements/multiple-select');
        break;
      case 'hidden':
        elemType = require('./elements/hidden');
        break;
      case 'captcha':
        elemType = require('./elements/captcha');
        break;
      case 'button':
        elemType = require('./elements/button');
        break;
      case 'submit':
        elemType = require('./elements/submit');
        break;
      case 'onlyText':
        elemType = require('./elements/onlyText');
        break;
      case 'file':
        elemType = require('./elements/file');
        break;
      }
      var elem = new elemType.create(field);
      this.formObjects.push(elem);
    });
  }

  /**
   * Vykreslenie prvkov do formulára
   * Ak má prvok špecifikované miesto v šablóne, vykreslí sa tam, inak je pripojený priamo do <form> elementu
   * @param {jQuery} form   formulár, do ktorého sú prvky vykreslené
   */
  renderObj(form) {
    this.formObjects.forEach((element) => {
      if(element.jqObject === null) {
        //var target {jQuery}
        var target;
        if(element.templateId) {
          target = form.find('#' + element.templateId);
          element.jqObject = element.render();
        } else {
          target = form;
          element.jqObject = element.render();
        }
        element.jqObject.appendTo(target);
      } else {
        var newElement = element.render();
        element.jqObject.replaceWith(newElement);
        element.jqObject = newElement;
      }
      if(typeof element.postRender === 'function')
        element.postRender();

    });
  }

  /**
   * Validácia formulárových prvkov
   * @return true   - ak sú všetky prvky validné
   * @return false  - ak aspoň jeden prvok validný nie je
   */
  validateObj() {
    console.log('Validácia from utils general form');
    var valid = true;
    this.formObjects.forEach((element) => {
      element.validate();

      //Element je skrytý, validácia je vždy true
      if(element.jqObject.hasClass('hide'))
        element.valid = true;

      if(!element.valid){
        console.log(`Tento element neni valid ${element}`);
        valid = false;
      }


    });
    return valid;
  }

  /**
   * Nastavenie Modelu (premenných) podľa View (HTML)
   */
  setByView() {
    this.formObjects.forEach((element) => {
      element.setByView();
    });
  }
};
