var renderTag = require('../utils/render-tag');
var radio = require('../utils/fields/radio');
var checkbox = require('../utils/fields/checkbox')();
var hidden = require('../utils/fields/hidden');

var hideStations = window.hideStations || false;

var modifyTariffs = require('./actions/modify-tariffs');
// obsah modal okna se seznamem destinaci
function renderDestinations(data, handleClick, dataOptions) {
  var opts = dataOptions;
  var messages = opts.messages;
  var carrierLabel = `<span class="carrier"> - ${opts.messages.carrier}</span>`;
  var block = $('<div/>');

  data.forEach((item) => {

    var stateDiv = $('<div class="state"/>');

    $(`<h4>${item.country}</h4>`).appendTo(stateDiv);

    item.cities.forEach((city) => {

      var cityDiv = $('<div class="city"/>');
      var ul = $('<ul/>');

      $(`<h5 data-id="${city.id}" data-location-type="CITY" >${city.partnerCity ? city.name + carrierLabel : city.name}</h5>`)
        .click(handleClick)
        .appendTo(cityDiv);

      if (city.stations.length > 1 && !hideStations) {
        city.stations.forEach((station) => {
          $(`<li data-id="${station.id}" data-location-type="STATION">${station.partnerStation ? station.fullname + carrierLabel : station.fullname}</li>`)
            .click(handleClick)
            .appendTo(ul);
        });

        ul.appendTo(cityDiv);
      }

      cityDiv.appendTo(stateDiv);
    });

    stateDiv.appendTo(block);

  });

  return block;
}

module.exports = function (context) {
  var destOptions = $.extend({}, {
    detail: 'query',
    template: '{fullname}',
    renderDestinations,
    useLocalDestinations: {
      state: true,
      path: 'ybus-form'
    }
  }, require('./actions/autocomplete')(context));

  var date = require('../utils/fields/date')();
  var destination = require('../utils/fields/destination')(destOptions, context);

  return {
    checkbox,
    trip_type,
    destination,
    date,
    switch_arrow,
    passengers,
    tariffs,
    saved_routes,
    hidden
  };

  /**
   * Vykreslení pole pro výběr typu jízdenky
   * @param  {Object} field data k poli
   * @param  {Object} block rodičovský element
   * @return {Object}       jQuery element
   */
  function trip_type(field, block) {

    field.fields.forEach(item => {


      if (typeof field.def !== 'undefined')
        if (field.def == item.value)
          item.checked = true
        else
          item.checked = false

      item.id = field.id;
      radio(item, block, onChange);
      renderTag.label(item.value, item.label).appendTo(block);

      function onChange() {

        if ($(this).val() === 'one_way')
          $('input[name=arrival_date]').attr('disabled', true).next().addClass('disabled');
        else
          $('input[name=arrival_date]').attr('disabled', false).next().removeClass('disabled');
      }

    });

    return block;
  }

  /**
   * Vykreslení pole pro prohození lokací
   * @param  {Object} field data k poli
   * @param  {Object} block rodičovský element
   * @return {Object}       jQuery element
   */
  function switch_arrow(field, block) {
    block.click(() => {

      var from = $('input[name=destination_from]').val();
      var to = $('input[name=destination_to]').val();

      $('input[name=destination_to]').val(from);
      $('input[name=destination_from]').val(to);

      //WEB-3380 - treba prehodiť aj hodnoty v skrytých inputoch, ktoré predstavujú id destinácií od, do

      from = $('input[name=destination_from_id]').val();
      to = $('input[name=destination_to_id]').val();

      $('input[name=destination_to_id]').val(from);
      $('input[name=destination_from_id]').val(to);



      //prohodit priznak nevalidniho pole (neodpovida mu zadna destinace)
      var fromNoMatch = $('input[name=destination_from]').data('noMatch');
      var toNoMatch = $('input[name=destination_to]').data('noMatch');


      if (fromNoMatch === undefined)
        fromNoMatch = false;
      if (toNoMatch === undefined)
        toNoMatch = false;

      if (fromNoMatch) {
        $('input[name=destination_to]').addClass('invalid')
        $('input[name=destination_to]').parent().addClass('invalid')
      }
      else {
        $('input[name=destination_to]').removeClass('invalid')
        $('input[name=destination_to]').parent().removeClass('invalid')
      }
      if (toNoMatch) {
        $('input[name=destination_from]').addClass('invalid')
        $('input[name=destination_from]').parent().addClass('invalid')
      }
      else {
        $('input[name=destination_from]').removeClass('invalid')
        $('input[name=destination_from]').parent().removeClass('invalid')
      }
      //      $('input[name=destination_from]').removeClass('invalid')
      //      $('input[name=destination_from]').parent().removeClass('invalid')
      //      $('input[name=destination_to]').removeClass('invalid')
      //      $('input[name=destination_to]').parent().removeClass('invalid')

      $('input[name=destination_to]').data('noMatch', fromNoMatch);
      $('input[name=destination_from]').data('noMatch', toNoMatch);

      //swap location type dat
      var fromLocationType = $('input[name=destination_from]').data('locationType');
      var toLocationType = $('input[name=destination_to]').data('locationType');

      $('input[name=destination_to]').data('locationType', fromLocationType);
      $('input[name=destination_from]').data('locationType', toLocationType);

    });

    return block;
  }

  /**
   * Vykreslení pole se sliderem pro výběr množství cestujících
   * @param  {Object} field data k poli
   * @param  {Object} block rodičovský element
   * @return {Object}       jQuery element
   */
  function passengers(field, block) {
    let tabindex = field.tabindex || false
    var container = tabindex ? $(`<div class="${field.type}" tabindex="${tabindex}"/>`) : $(`<div class="${field.type}"/>`);
    var sliderControls = $('<div class="slider-controls"/>');
    var slider = $('<div class="slider"/>');
    var tariffOpts = context.data('opts').currentTariffs;
    var maxPassengers = [];
    var i = 0;
    var sliderOptions = {
      range: 'min',
      value: field.def || 1,
      min: 1,
      max: context.data('opts').maxPassengers,
      step: 1,
      slide(event, ui) {
        modifyTariffs.modify('.tariff-block', ui.value);
        sliderControls.children().removeClass('active');
        $(`.slider-controls span[rel=${ui.value}]`, context).addClass('active');
      }
    };

    slider.slider(sliderOptions);
    slider.appendTo(container);

    while (i++ < context.data('opts').maxPassengers)
      maxPassengers.push(i);

    maxPassengers.forEach((item, index) => {
      $('<span/>', {
        rel: index + 1,
        text: index + 1,
        'class': field.def === index + 1 ? 'active' : ''
      })
        .click(function () {
          var value = $(this).attr('rel');

          sliderControls.children().removeClass('active');
          $(this).addClass('active');
          slider.slider('value', value);
          modifyTariffs.modify('.tariff-block', value);
        })
        .appendTo(sliderControls);
    });

    sliderControls.appendTo(container);
    container.appendTo(block);

    return block;
  }

  /**
   * Vykreslení pole se selectboxy pro zvolení tarifů jednotlivých cestujících
   * obsahuje i logiku vykreslování selectů na základě změny počtu cestujících
   * @param  {Object} field data k poli
   * @param  {Object} block rodičovský element
   * @param  {Number} count Počet selectboxů s tarify, který je třeba vykreslit (počet cestujících)
   * @return {Object}       jQuery element
   */
  function tariffs(field, block) {
    var container = $('<div/>');

    let tariffsMap = context.data('opts').tariffs
    let regular = tariffsMap.filter(tarif => tarif.value == 'REGULAR')[0].value
    for (var i = 0; i < context.data('opts').maxPassengers; i++) {
      //var regular = context.data('opts').tariffs[0].value;
      var tariffDiv = $('<div class="tariff-block"/>');
      var select = renderTag.select(`tariff-${i}`, '', context.data('opts').tariffs, ++field.tabindex);

      let tarifVal = regular;
      //console.log(field);
      if (typeof field.def !== 'undefined' && field.def != null && field.def.length > i && Array.isArray(field.def))
        tarifVal = tariffsMap.filter(tarif => tarif.value == field.def[i])[0].value
      select.val(tarifVal).appendTo(tariffDiv);
      tariffDiv.appendTo(container);
    }

    container.appendTo(block);

    return block;
  }

  /**
   * Vykreslení pole s uloženými cestami
   * @param  {Object} field data k poli
   * @param  {Object} block rodičovský element
   * @return {Object}       jQuery element
   */
  function saved_routes(field, block) {
    //hide block for new homepage
    if ($('body').hasClass('homepage-new') == true) return block.addClass('hide')

    var allData = null;
    // Ak nie sú žiadne uložené trasy, schovám celý element
    if (context.data('opts').routes.length <= 0)
      return block.addClass('hide')

    $.getJSON(`${context.data('opts').urls.destinations}destinations-${context.data('opts').lang}.json`)
      .done(data => {
        var destinationsData = data.airports || data.destinations || data;
        var partnersData = data.partners;
        allData = destinationsData.concat(partnersData);

        var routes = $(`<div class="${field.type}"/>`);
        var separator = ' -> ';
        context.data('opts').routes.forEach((route, index) => {
          if (index < 5) {
            var fromName = findById(allData, route.from_id);
            var toName = findById(allData, route.to_id);

            // pokud se nepodarilo nalezt spravny nazev mesta, nevypiseme nic
            if (typeof fromName === undefined || toName === undefined)
              return

            $('<span/>', {
              text: `${fromName + separator + toName}`,
              'class': `${context.data('opts').prefix}saved-route`,
              'data-from': `${route.from_id}`,
              'data-to': `${route.to_id}`,
              click: load_destination
            })
              .appendTo(routes);
          }
        });

        routes.appendTo(block);

      })
      .fail(error => {
        console.error(`Cannot load destination data ${error}`);
        return block.addClass('hide')
      });
    return block;
  }


  // najde spravnou jazykovou mutaci jiz drive vyhledaneho mesta
  // (např. pokud na CZ verzi vyhledame Vídeň a poté přepneme do SK verze, vypíše se Viedeň)
  function findById(allData, findId) {
    for (var i = 0; i < allData.length; i++) {
      var cities = allData[i].cities;
      for (var x = 0; x < cities.length; x++) {
        if (cities[x].id == findId) 
          return cities[x].name;
        
        if (cities[x].hasOwnProperty('stations')) {
          var stations = cities[x].stations;
          for (var y = 0; y < stations.length; y++) 
            if (stations[y].id == findId) 
              return stations[y].fullname;
            
          
        }
      }
    }

    /*for (var i = 0; i < allData.length; i++) {
      var cities = allData[i].cities;
      for (var i = 0; i < cities.length; i++) {
//        console.log(cities[i].id);
        if(cities[i].id === findId) {
          return cities[i].name;
        }
        var stations = cities[i].stations;
        for (var i = 0; i < stations.length; i++) {
//          console.log(stations[i].id);
          if(stations[i].id === findId) {
            return stations[i].fullname;
          }
        }
      }
    }*/
  }

  /**
   * nacitani destinaci do vyhledavace z poslednich hledanych
   */

  function load_destination() {
    var dsts = $(this).text().split(' -> ');

    $('input[name=destination_from]').val(dsts[0]);
    $('input[name=destination_to]').val(dsts[1]);


    // id
    $('[name=destination_from_id]').val($(this).data('from'));
    $('[name=destination_to_id]').val($(this).data('to'));

    $('#destination_from').removeClass('invalid')
    $('#destination_from').parent().removeClass('invalid')
    $('input[name=destination_to]').removeClass('invalid')
    $('input[name=destination_to]').parent().removeClass('invalid')

    $('input[name=destination_from]').data('noMatch', false);
    $('input[name=destination_to]').data('noMatch', false);
  }

};
