module.exports = function (e) {

  e.preventDefault();

  var self = this;
  var question = $(self).find('#captcha');
  var questionParent = question.parent();
  var answer = $(question).val();
  var success = true;
  var opts = $(self).parent().data('opts');
  var customRenderFunction = $(self).parent().data('customRenderFunction');

  $('div[data-required="true"]').each(function() {
    var input = $(this).find('input');
    if(!$(input).val()) {
      success = false;
      var controlLabel = $(this).find('.control-label');
      $(this).addClass('alert-icon-wrap');
      if ($(this).find('.alert-icon').length === 0 ) {
        $('<span/>', {
          class: 'alert-icon'
        }).hide().appendTo($(controlLabel)).fadeIn(200);
      }
      if ($(this).find('.alert-danger').length === 0 ) {
        $('<div/>', {
          class: 'fill-value alert alert-danger',
          text: opts.messages[`error_${$(input).attr('id')}`] || opts.messages.error_required
        }).hide().appendTo($(this));
      }
    }
    else {
      var alertIcon = $(this).find('.alert-icon');
      $(this).find('.fill-value').remove();
      $(this).removeClass('alert-icon-wrap');
      $(this).find('.alert-icon-label').removeClass('.alert-icon-label');
      alertIcon.fadeOut(200, function() { alertIcon.remove(); });
    }
  });
  if(parseInt(answer) !== 12) {
    success = false;
    questionParent.addClass('alert-icon-wrap');
    var controlLabel = questionParent.find('.control-label');

    if (questionParent.find('.alert-icon').length === 0 ) {
      $('<span/>', {
        class: 'alert-icon'
      }).appendTo($(controlLabel));
    }

    if (questionParent.find('.wrong-answer').length === 0 ) {
      $(`<div class="fill-value wrong-answer alert alert-danger" role="alert">${opts.messages.error_captcha}</div>`).appendTo($(question).parent());
    }
  }
  else if (parseInt(answer) === 12) {
    var alertIconQuestion = questionParent.find('.alert-icon');
    questionParent.removeClass('alert-icon-wrap');
    questionParent.find('.fill-value').remove();
    alertIconQuestion.fadeOut(200, function() { alertIconQuestion.remove(); });
  }
  if ($('.alert-icon')) {
    var desc;
    var alertIcon = $('.alert-icon');
    alertIcon.parent().addClass('alert-icon-label');

    alertIcon.hover(
      function() {
        var alertIconParent = $(this).parent().parent();
        var alertIconOffsetLeft = ($(this).offset().left - alertIconParent.offset().left);
        var alertIconOffsetTop = ($(this).offset().top - alertIconParent.offset().top + $(this).outerHeight() + $(this).outerHeight()/2);
        desc = $(this).parent().siblings('.fill-value')
        desc.css({'left': alertIconOffsetLeft + 'px', 'top': alertIconOffsetTop + 'px'});
        desc.stop().fadeIn(200);
      },
      function() {
        desc.stop().fadeOut(200);
      }
    );
  }
  if(success) {
    $('input:checked').each(function() {
      $(this).val('true');
    });
    var data = $('.bus-rent-form').serialize();
    data += opts.id ? '&id=' + opts.id : '';
    var stringTo = $('.departure-time-to-block #hours').val() + ':' + $('.departure-time-to-block #minutes').val();
    var stringBack = $('.departure-time-back-block #hours').val() + ':' + $('.departure-time-back-block #minutes').val();
    data += '&departure-time-to=' + stringTo + '&departure-time-back=' + stringBack;
    send(data);
  } else {
    return false;
  }

  function send(data) {
    $.ajax({
      url: opts.urls.submit,
      data: data,
      dataType: 'json'}).done(function(res) {

        //Vytvoreni lightboxu po uspesnem odeslani formulare
        var ico = 'icon-upsesne';
        if (res.type === 'error') {
          ico = 'icon-neuspesne';
        }
        var opts = {
          'type': res.type,
          'message': {
            'icon': 'sa-icon ' + ico,
            'title': res.title,
            'content': res.message,
            'close': 'Zavřít'
          }
        };

        var lb = $('#bus-rent-form').lightbox;
        lb.create(null, 'icon-lightbox', window.customRenderFunction, opts).hideX().closeOnBackground();

        /*
        if (typeof customRenderFunction !== 'undefined')
          customRenderFunction(res.type, res.message);
        else
          setResponseScreen(res.type, res.message);
        */
      })/*,
      error: function(xhr, stats, errorThrown) {
        if (typeof customRenderFunction !== 'undefined')
          customRenderFunction(opts.error_connection.type, opts.error_connection.message);
        else
          setResponseScreen(opts.error_connection.type, opts.error_connection.message);
      }
      */
  };

    /*
  function setResponseScreen(type, opts) {
    var container = $('.bus-rent-form').parent();
    $('.bus-rent-form').hide();
    var messageBox = $('<div/>', {'class': 'alert'});

    switch(type) {
      case 'success':
        $(messageBox).addClass('alert-success');
      break;
      case 'error':
        $(messageBox).addClass('alert-danger');
      break;
      default:
        $(messageBox).addClass('alert-warning');
      break;
    }

    $('<strong/>', {'text': opts.title}).appendTo(messageBox);
    $('<p/>', {'text': opts.content}).appendTo(messageBox);

  }
  */
};