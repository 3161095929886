module.exports = function airticketReservationForm (opts) {

  var self = this;
  var defaults = {
    name: 'airticket-form',
    prefix: 'airticket-',
    dataPath: '/opencms/opencms/shared/wc/airticket-form/data.json',
    formType: 'round_trip',
    limits: {},
    params: {
      id: opts.id
    }
  };

  // var autofocus = require('./actions/autofocus')()
  // autofocus.init()

  var createComponent = require('../component').create.bind(self);
  var renderTag = require('../utils/render-tag');
  var renderForm = require('../utils/render-form');
  var qsToObject = require('../utils/qs-to-object');
  var printHeadline = require('../utils/print-headline');
  var routesStorage = require('../utils/local-storage')(self);

  return createComponent({
    options: $.extend({}, defaults, opts),
    preRenderHook,
    render,
    postInitHook
  });

  function preRenderHook (component) {
    self.data('opts', component.options);
    self.data('form', {
      defDest: true,
      defRange: true,
      airlines: [],
      errors: []
    });

    routesStorage.loadFromLocalStorage(`${component.options.id}-air`);
  }


  async function fetchBusStations() {
    let controller = new AbortController();
    setTimeout(()=> controller.abort(),2000);
    //'https://dpl-qa-ybus-pubapi.sa.cz/restapi/consts/locations'

    return await fetch('https://brn-ybus-pubapi.sa.cz/restapi/consts/locations', {
      signal:controller.signal
    });
  }

  /**
   * @param user specific destination
   * @return bool
  */
  async function getCityId(query){
    try {
      const response = await fetchBusStations();
      const data = await response.json();
      const czechData = data.filter((country) => {
        return country.code === 'CZ';
      })
      const czechCities = czechData[0].cities;

      const doesBusStopExist = (el) => el.name.includes(query);

      const result = czechCities.some(doesBusStopExist);
      if(result){
        const city = czechCities.find(city => {
          return city.name.includes(query)
        })
        const cityId = city.id;

        return cityId;
      }
    } catch (error){
      if(error.name == 'AbortError'){
        console.log('abort');
      }
    }
  }

  function submitTheForm(component,e){
    // submit
    var i = 0;
    var airline = '';
    $('.air-select-wrapper select').each(function() {
      if (i++ > 0)
        airline += ',';

      airline += $(this).val();
    });

    $('#airline').val(airline.replace(/ali-/g, '/*'));

    //$('#airline').val($('#airline').val().replace(/ali-/g, '/*'));



    //Pred odoslaním odstránim počítadlám pasažierov disabled, aby sa aj tieto polia poslali
    $('input.passenger-count').attr('disabled', false);

    //WEB-3377 ak vyberie variantu 0 0 0 0, nastaví sa 1 0 0 0
    var sucet = 0;
    $('input.passenger-count').each(function() {
      sucet += parseInt($(this).val());
    });
    if(sucet === 0)
      $('input.passenger-count[name="passengers_adt"]').val(1);

    var obj = {};
    obj.from = $('[name=departure_destination_1]').val();
    obj.to = $('[name=arrival_destination_1]').val();

    routesStorage.saveToLocalStorage(`${component.options.id}-air`, obj);

    // ie fix - checkbox checked
    var onlyDirectFlightsCheckbox = $('input[name=only_direct_flights]');
    var isChecked = onlyDirectFlightsCheckbox.is(':checked');
    if(isChecked)
      onlyDirectFlightsCheckbox.val('true');
    else
      onlyDirectFlightsCheckbox.val('false');

    e.target.submit();
  }

  function redirectToShop(fromLocId, toLocId,destTo){
    const departDate = document.getElementById('date_picker_1');
    const arrivlDate = document.getElementById('date_picker_2');
    const submitBut = document.querySelector('.airticket-form-submit');
    const myModal = document.querySelector('.modal-body.regio');

    let link = 'https://shop.regiojet.cz/?locale=cs';
    let dateRegEx = /^(0?[1-9]|[12][0-9]|3[01])[.](0?[1-9]|1[012])[.]\d{4}$/;
    let departDateVal;
    let arrivalDateVal;

    if(departDate.value.match(dateRegEx) != null){
      departDateVal = departDate.value.split('.');
    }

    if(arrivlDate != null){
      if(arrivlDate.value.match(dateRegEx) != null){
        arrivalDateVal = arrivlDate.value.split('.');
      }
    }

    if(typeof departDateVal != 'undefined'){
      link += `&departureDate=${departDateVal[2]}-${departDateVal[1]}-${departDateVal[0]}`;
      link += `&fromLocationId=${fromLocId}`;
      link += `&toLocationId=${toLocId}`;
      if(typeof arrivalDateVal != 'undefined'){
        link += `&returnDepartureDate=${arrivalDateVal[2]}-${arrivalDateVal[1]}-${arrivalDateVal[0]}`;
      }
      link += '&fromLocationType=CITY&toLocationType=CITY&tariffs=REGULAR';
    };
    let x = 'test my';
    // <span>// REGIOJET</span>
    myModal.innerHTML = '';
    myModal.insertAdjacentHTML('afterbegin', `<p>

                                <div class="modal-content-firstLine">
                                  Hodnota "${destTo}" neodpovídá žádnému letišti. Prosím, upřesněte příletové letiště.<br>
                                </div>
                                <div class="modal-content-secondLine">
                                <span class='modalContentLink' style="font-weight:600">Pro vyhledávání autobusového či vlakového spojení do "${destTo}" pokračujte kliknutím
                               </span>
                               </div>
                               <div class="modal-content-links">
                               <button type="button" class="btn btn-default modal-content-button" data-dismiss="modal">
                               <img src="sys/images/regioLeftArrow.png"/>&nbsp;&nbsp;Zpět</button>

                               <a href="${link}" class="modalContentRedirect">Koupit zde &nbsp <img src="sys/images/regioRightArrow.png" style="padding-bottom: 3px"/> &nbsp; <img src="sys/images/regioIconNew.png" style="
    height: 18px;
"></a>
                              </div>
                               </p>`);
    $('#myModal').modal('show');

    // submitBut.setAttribute('data-toggle', 'modal');
    // submitBut.setAttribute('data-target', '#myModal');
  }

  function render (component) {

    printHeadline(opts, this);
    component.renderField = require('./render-field')(component, self);
    var form = renderForm(component, self, async (e) => {
      //WEB 10-85 verify if destinations don't exist in Airport db and if they exist in ybus Db stop redirect and show alert
      e.preventDefault();

      let destFrom = document.getElementById('departure_destination_1').value.trim();
      let destTo = document.getElementById('arrival_destination_1').value.trim();
      const destFromHiddenInput =document.getElementById('departure_destination_1_short').value;
      const destToHiddenInput =document.getElementById('arrival_destination_1_short').value;

      destFrom = destFrom.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
      destTo = destTo.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
      let cityIdFrom;
      let cityIdTo;

      // 1 Verify destination from
      if(!destFromHiddenInput){
        if(!destFrom.includes('(') && destFrom != ''){
          //now we suppose destFrom does not exist in Airport Db and we search in ybus Db
          cityIdFrom = await getCityId(destFrom.trim());
        }
      }

      // 2 Verify destination to
      const validAirport = destTo.includes('(') && destTo != '';

      if(!destToHiddenInput){
        if(!validAirport){
          //now we assume dest To does not exist in Airport Db and we search in ybus Db
          cityIdTo = await getCityId(destTo.trim());
        }
      }

      const isAirportPrague = destFrom.includes('(prg)');
      const pragueCityId = '10202003';

      if (cityIdTo && isAirportPrague) {
        redirectToShop(pragueCityId,cityIdTo,destTo);
      } else if(typeof cityIdTo && cityIdFrom){
        redirectToShop(cityIdFrom,cityIdTo,destTo);
      } else {
        submitTheForm(component,e);
      }
    });

    renderTag.input('hidden', 'lang', component.options.lang).prependTo(form);
    renderTag.input('hidden', 'client_encoding', component.options.client_encoding).prependTo(form);
    renderTag.input('hidden', 'id_dealer', component.options.id_dealer).prependTo(form);

    $('#airticket-airline-block').find('.air-select-wrapper:hidden').each(function() {
      $(this).remove();
    });

    technicalService(component.options)

    function technicalService(opts) {
      if(typeof opts.technicalServices !== 'undefined' && opts.technicalServices.state) {
        const template = `
        <div class="${defaults.name}-issue-overlay">
          <strong>${opts.technicalServices.text}</strong>
        </div>`

        $(template).appendTo(`.${defaults.name}`)
      }
    }
  }

  function postInitHook (component, context) {
    var formActions = require('./actions/form')(self);
    formActions.setFormType(component.options.formType);
    formActions.setValuesFromQuery(qsToObject(window.location.search));
    var passengerLogic = require('./actions/passengers');
    passengerLogic(component.options.limits);

    //WEB-1726
    //timeout to run the code at the end of job queue , coz element airticket-search_interval-block is loaded slightly later
    setTimeout(function(){
      const searchInterval = document.getElementById('airticket-search_interval-block');
      const travelClass = document.getElementById('airticket-travel_class-block');
      const location = window.location.href;
      if(location.includes('regiojet.cz/letenky') || location.includes('www.regiojet.sk/letenky')) {
        if (searchInterval) {
          searchInterval.removeChild(searchInterval.lastElementChild);
          travelClass.removeChild(travelClass.lastElementChild);
        }
      }
    }, 1);
  }
};
