var element = require('./element');

module.exports = {
  //Typ input text
  create: function(field) {
    element.create(this, field);
    /*if(this.regexp_valid) {
      console.log(this.regexp_valid);
    }*/

    this.render = function() {
      var classAttr = 'gf-text';
      if(field.classBlock)
        classAttr += ` ${field.classBlock}`;
      if(!this.valid)
        classAttr += ' invalid';

      classAttr += this.customClass ? ' ' + this.customClass : '';
      var textElement = $('<div/>', {
        'class': classAttr
      });

      element.renderLabel(textElement, this);

      element.renderAlert(textElement, this);

      var input = $('<input/>', {
        id: this.id,
        'type': this.type,
        name: this.name,
        value: this.value
      }).appendTo(textElement);

      return textElement;
    };

    this.validate = function() {
      var res = true;
      if(this.required && !this.value) {
        res = false;
        this.required_valid = false;
      }
      //Kontrolujem na regexp len ak je daco zadane
      if(this.value && this.regexp) {
        var pattern = new RegExp(this.regexp, 'gi');
        if(!pattern.test(this.value)) {
          res = false;
          this.regexp_valid = false;
        }
      }

      this.valid = res;
    };

    this.setByView = function() {
      this.value = this.jqObject.find('#' + this.id).val();
    };
  }
};
