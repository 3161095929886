var renderTag = require('../utils/render-tag');
var radio = require('../utils/fields/radio');

var hideStations = window.hideStations || false;


function renderDestinations (data, handleClick, dataOptions) { // vypis destinaci do seznamu (Vyber stanice)
  var opts = dataOptions;
  var messages = opts.messages;
  var carrierLabel = `<span class="carrier"> - ${opts.messages.carrier}</span>`;
  var block = $('<div/>');
  data.forEach((item) => {
    var stateDiv = $('<div class="state"/>');

    $(`<h4>${item.country}</h4>`).appendTo(stateDiv);

    item.cities.forEach((city) => {
      var cityDiv = $('<div class="city"/>');
      var ul = $('<ul/>');

      let itemId = city.stations && city.stations.length === 1 ? `stationId=${city.stations[0].id}` : `cityId=${city.id}`

      $(`<h5 data-station-id="${itemId}" data-id="${city.id}">${city.partnerCity ? city.name + carrierLabel : city.name}</h5>`)
        .click(handleClick)
        .appendTo(cityDiv);

      if(city.stations.length > 1 && !hideStations) {
        city.stations.forEach((station) => {
          $(`<li data-station-id="stationId=${station.id}" data-id="${station.id}">${station.partnerStation ? station.fullname + carrierLabel : station.fullname}</li>`)
          .click(handleClick)
          .appendTo(ul);
        });

        ul.appendTo(cityDiv);
      }

      cityDiv.appendTo(stateDiv);
    });

    stateDiv.appendTo(block);

  });

  return block;
}

module.exports = function (context) {
      

  var destOptions = $.extend({}, {
  detail: 'query',
  template: '{fullname}',
  local: '{fullname}',
  renderDestinations,
  useLocalDestinations: {
    state: true, // slouzi pro volani lokalnich akci ve web-components/src/utils/fields/destinations.js
    path: 'combobox-rj' // slouzi pro volani lokalnich akci ve web-components/src/utils/fields/destinations.js
  }
}, require('./actions/autocomplete')(context));

  var destination = require('../utils/fields/destination')(destOptions, context);

  return {
    destination
  };


};
