var element = require('./element');

module.exports = {
  create: function(field) {
    element.create(this, field);

    this.render = function() {
      var classAttr ='gf-onlyText';
      if(field.classBlock)
        classAttr += ` ${field.classBlock}`;
      var textElement = $('<div/>', {
        'class': classAttr
      });
      classAttr = this.customClass;

      if(this.label)
        element.renderLabel(textElement, this);

      var onlyText = $('<span/>', {
        id: this.id,
        text: this.value,
        class: classAttr
      }).appendTo(textElement);

      return textElement;
    };

    this.setByView = function() {
    };
  }
}
