module.exports = function comboboxSelect (opts) {

  var self = this;
  var defaults = {
    dataPath: '/opencms/opencms/shared/wc/combobox/regiojet.cz.json',
    name: 'combobox-select',
    prefix: 'combobox-',
    params: {
      id: opts.id
    }
  };
  var createComponent = require('../component').create.bind(self);
  var renderForm = require('../utils/render-form');
  var onSubmit = require('./actions/submit');

  return createComponent({
    options: $.extend({}, defaults, opts),
    preRenderHook,
    render,
    postInitHook
  });

  function preRenderHook (component) {
    self.data('opts', component.options);
    self.data('opts').currentTariffs = [];
  }

  function render (component) {
    if(component.options.hideStations)
      window.hideStations = component.options.hideStations;

    setDefFromOpts(component.data, opts.def || [])

    component.renderField = require('./render-field')(self);
    renderForm(component, self, onSubmit);
  }

  // Nastaví deaultné hodnoty pre fieldy z opts podľa idčiek
  function setDefFromOpts(data, def) {

    data.map(pieceOfData => {
      // Nájdem defaultnú hodnotu podľa idčka
      let defValue = def.filter(pieceOfDef => pieceOfData.id == pieceOfDef.id)[0]
      // Ak existuje, nastavím ju
      if(typeof defValue !== 'undefined')
        pieceOfData.def = defValue.value
    })
  }

  function postInitHook (component, context) {
    if(window.ybusPostRenderFunction && typeof window.ybusPostRenderFunction === 'function')
      window.ybusPostRenderFunction(context);
  }
};
