/**
 * Google Re-captcha
 * validácia aj setByView sú nepodstatné, nič nerobia.
 * Render len vykreslí div so správnym sitekey
 *
 * aby captcha správne fungovala, je potrebné nahrať script:
 * https://www.google.com/recaptcha/api.js?onload=grecaptchaOnloadCallback&render=explicit
 */
module.exports = {
  create: function(field) {
    this.id = field.id;
    this.type = field.type;
    this.key = field.key;
    this.valid = field.valid ? field.valid : true;
    this.required = field.required;
    this.required_error_message = field.required_error_message;
    this.templateId = field.templateId;
    this.jqObject = null;
    this.jqAlert = null;

    this.render = function() {
    //var captchaElement = this.jqObject;

      var classAttr ='gf-captcha';
      if(field.classBlock)
        classAttr += ` ${field.classBlock}`;
      if(!this.valid) 
        classAttr += ' invalid';
    
      classAttr += this.customClass ? ' ' + this.customClass : '';
      var captchaElement = $('<div/>', {
        'id': this.id,
        'class': classAttr
      });
      /*if(typeof grecaptcha !== 'undefined' && this.jqObject !== null) {
      console.log('1');
      this.jqObject.empty();
      console.log(captchaElement.html());
      console.log(grecaptcha);
      grecaptcha.render(this.id, {
        'sitekey': this.key
      });
    } else {
      console.log('2');
      var captcha = $('<div/>', {
        'class': 'g-recaptcha',
        'data-sitekey': this.key
      }).appendTo(captchaElement);
    }*/
      return captchaElement;
    }

    this.postRender = function() {
    /*if(typeof this.captchaWidget != 'undefined') {
      console.log(this.captchaWidget)
    }else{
      console.log('undefined');
    }*/

      //Ak už je captcha načítaná, použije sa objekt grecaptcha
      if(typeof grecaptchaOnloadCallback !== 'undefined') 
        this.captchaWidget = grecaptcha.render(this.id, {
          'sitekey': this.key
        });
    
      //Ak ešte načítaná nie je (prvé renderovanie formulára po reloadnutí), bastavá sa onloadCallback funkcia, ktorú zavolá samotná captcha
      else {
        window.grecaptchaObject = this;
        window.grecaptchaOnloadCallback = function() {
          window.grecaptchaObject.captchaWidget = grecaptcha.render(window.grecaptchaObject.id, {
            'sitekey': window.grecaptchaObject.key
          });
        }
      }
    }

    /*this.postRender = function() {
    if(typeof grecaptcha !== 'undefined') {
      grecaptcha.render(this.id, {
        'sitekey': this.key
      });
    } else {
      var captcha = $('<div/>', {
        'class': 'g-recaptcha',
        'data-sitekey': this.key
      }).appendTo(this.jqObject);
    }
  }

  this.reRender = function() {
    if(!this.valid) {
      this.jqObject.addClass('invalid');
      if(this.jqAlert) {
        this.jqAlert.remove();
      }
      this.jqAlert = $('<div/>', {
        'class': 'alert-icon'
      }).prependTo(this.jqObject);

      var msgWrap = $('<div/>', {
        'class': 'alert-msgs'
      }).appendTo(this.jqAlert);

      $('<span/>', {
        text: this.required_error_message
      }).appendTo(msgWrap);

    } else {
      this.jqObject.removeClass('invalid');
      if(this.jqAlert)
        this.jqAlert.remove();
      this.jqAlert = null;
    }
  }*/

    this.validate = function() {
      if($(this.jqObject).find('textarea[id^="g-recaptcha-response"]').val())
        this.valid = true;
      else
        this.valid = false;
    }

    this.setByView = function() {}
  }

}
