/**
 * Odstranění duplicitních hodnot s jedoduchého pole
 * @param  {Array} array Vstupní pole, které pravděpodobně obsahuje duplicitní položky
 * @return {Array}       Pole ve kterém byly všechny duplicitní položky odtraněny
 */
module.exports = function removeDuplicates (array) {
  var output = [],
    object = {};
  array.forEach(function (item) {
    object[item] = 0;
  });
  Object.keys(object).forEach(function (key) {
    output.push(key);
  });
  return output;
};