var renderTag = require('../render-tag');

module.exports = function (field, block) {
	var def = field.def ? field.def : '';
	if(window.hiddenTypeId)
		if(window.hiddenTypeId[field.id])
			def = window.hiddenTypeId[field.id];

  renderTag.input('hidden', field.id, def).appendTo(block);
  return block;
};
