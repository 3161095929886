/**
 * Pomocné funkce pro vykreslení pár základních typů tagů
 * @type {Object}
 */
module.exports = {

  /**
   * Funkce pro vykreslení inputu
   * @param  {String}  type    Typ inputu text, number, date, radio, checkbox
   * @param  {String}  name    atribut name inputu, použije se i jako ID pokud se nejedná o radio buttony
   * @param  {String}  value   Výchozí hodnota, použije se jako id pokud se jedná o typ radio
   * @param  {Boolean} checked Určuje zdali je položka vybraná pokud se jedná o typ radio nebo checkbox
   * @return {Object}          jQuery element
   */
  input (type, name, value, checked, placeholder, selectOnClick, tabindex) {

    var opts = {
      'class': 'form-control',
      type,
      name,
      value,
      id: name,
      placeholder
    };
    if(tabindex) {
      opts.tabindex = tabindex
    }
    if(opts.placeholder) {
      opts.onFocus = `this.placeholder=''`
      opts.onBlur = `this.placeholder='${placeholder}'`
    }
    if(selectOnClick) {
      opts.onClick = "this.setSelectionRange(0, this.value.length);";
    }

    if (type === 'checkbox' || type === 'radio') {
      opts.class = '';

      if (type === 'radio')
        opts.id = value;

      if (checked)
        opts.checked = checked;
    }

    return $('<input/>', opts);
  },

  /**
   * Funkce pro vykreslení textarea tagu
   * @param  {String}  name    atribut name inputu, použije se i jako ID
   * @return {Object}          jQuery element
  */
  textarea(name, placeholder) {
    const opts = {
      'class': 'form-control-textarea',
      name,
      id: name
    };
    if(placeholder)
      opts.placeholder = placeholder;

    return $('<textarea/>', opts);
  },


  /**
   * Vykreslení selectu
   * @param  {String} name      použije se jako atribut name a id
   * @param  {String} className použije se jako CSS třída
   * @param  {Array}  options   Pole ve formátu [{name: '', value: ''}] ze kterého se následně genrují možnosti do selectu
   * @return {Object}           jQuery element
   */
  select (name, className, options, tabindex) {

    var opts = {
      'class': className + ' form-control',
      name,
      id: name,
      tabindex
    };
    var select = $('<select/>', opts);

    options.forEach(function (option) {
      $('<option/>', {
        'class': option.class ? option.class : '',
        value: option.value,
        text: option.name,
        'selected': option.selected
      }).appendTo(select);
    });

    return select;
  },

  /**
   * Vykreslení labelu
   * @param  {String} name Jméno pole, ke kterému je label vázaný
   * @param  {String} html Obsah labelu, může obsahovat i html tagy
   * @return {Object}      jQuery element
   */
  label (name, html, className) {

    var opts = {
      'class': className ? `control-label ${className}` : 'control-label',
      'for': name || '',
      html
    };

    return $('<label/>', opts);
  },

  /**
   * Vykreslení divu
   * @param  {String} html      HTML obsah divu
   * @param  {String} className CSS třída divu
   * @param  {String} id        atribut id divu
   * @return {Object}           jQuery element
   */
  div (html, className, id) {

    var opts = {
      'class': className,
      html,
      id
    };

    return $('<div/>', opts);
  }
};
