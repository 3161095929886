var renderTag = require('../render-tag');

module.exports = function (field, block, action) {

  var radio = renderTag.input('radio', field.id, field.value, field.checked).appendTo(block);

  if (typeof action === 'function')
    radio.change(action);

  return block;
};
