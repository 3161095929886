module.exports = function (e) {

  e.preventDefault();

  var self = this;
  var success = true;
  var opts = $(self).parent().data('opts');
  var customRenderFunction = $(self).parent().data('customRenderFunction');

  //Validacia
  $('div[data-required="true"]').each(function() {
  	if(!$(this).validate('.validate-element')) {
		  $(this).addClass('alert-icon-wrap');
  		success = false;
  	} else {
      $(this).removeClass('alert-icon-wrap');
    }
  })
  
  //Serializacia formulara
  if(success) {
    var data = $('.satisfaction-form').serialize();
    data += opts.id ? '&id=' + opts.id : '';
    //console.log(data);
    send(data);
  } else {
    return false;
  }

  function send(data) {
    $.ajax({
      url: opts.urls.submit,
      data: data,
      dataType: 'json'}).done(function(res) {

        var ico = (res.type === 'success') ? 'icon-upsesne' : 'icon-neuspesne';

        var opts = {
          "type": res.type,
          "message": {
              "icon": "sa-icon " + ico,
              "title": res.title,
              "content": res.message,
              "close": "Zavřít"
          }
        };

        var lb = $('#satisfaction-form').lightbox;
        lb.create(null, "icon-lightbox", window.customRenderFunction, opts).hideX().closeOnBackground();
      }).fail(function() {
        console.log('Error');
      });
    };
};