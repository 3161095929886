var element = require('./element');

module.exports = {
  //Typ input multiple checkbox
  create: function(field) {
    element.create(this, field);

    this.chosen = false;
    this.checked = []; //Bude tam uložená value toho buttonu, ktorý je checked

    this.render = function() {
      var classAttr = 'gf-multiple-checkbox';
      if(field.classBlock)
        classAttr += ` ${field.classBlock}`;
      if(!this.valid)
        classAttr += ' invalid';
      classAttr += this.customClass ? ' ' + this.customClass : '';

      var multipleCheckboxElement = $('<div/>', {
        'class': classAttr
      });

      element.renderLabel(multipleCheckboxElement, this);

      element.renderAlert(multipleCheckboxElement, this);

      field.items.forEach((item) => {
        var singleRadio = $('<div/>').appendTo(multipleCheckboxElement);
        var checkbox = $('<input/>', {
          id: item.id,
          'type': 'checkbox',
          name: item.name,
          value: item.value
        }).appendTo(singleRadio);

        if(item.checked)
          $(checkbox).attr('checked', true);
        else if(this.checked.indexOf(item.value) !== -1)
          $(checkbox).attr('checked', true);

        if(item.label)
          $('<label/>', {
            html: item.label,
            'for': item.id
          }).appendTo(singleRadio);
      });

      return multipleCheckboxElement;
    };

    this.validate = function() {
      var res = true;
      if(this.required && this.checked.length <= 0) {
        res = false;
        this.required_valid = false;
      }
      this.valid = res;
    };

    this.setByView = function() {
      var self = this;
      this.checked = [];
      this.jqObject.find('input[type="checkbox"]').each(function() {
        if($(this).is(':checked'))
          self.checked.push($(this).val());
      });
    };
  }
}
