/**
 * Odeslání formuláře
 * @param  {Object} e událost
 * @return {void}
 */
module.exports = function (e) {

  e.preventDefault();

  var context = $(this).parent(); // element na kterém byla komponenta incializována
  var link = context.data('opts').urls.submit; // url pro odeslání

  var insuranceBlock = $('#insurance-passengerList-block');
  insuranceBlock.find('input[name="passengers_child"]').attr('name', 'deti');
  insuranceBlock.find('input[name="passengers_adult"]').attr('name', 'dospeli');
  insuranceBlock.find('input[name="passengers_nad70"]').attr('name', 'nad70');

  insuranceBlock.find('.passenger-count').each(function() {
    $(this).prop("disabled", false);
  });

  if(link.indexOf('?') >= 0) {
    link += '&' + $(this).serialize();
  } else {
    link += '?' + $(this).serialize();
  }
  window.location = link;
};