/* global module, require */
module.exports = function airlineActionOfferTable (options) {

  var createComponent = require('../component').create.bind(this);
  var preRenderHook = require('./pre-render-hook');
  var render = require('./render');
  var defaults = {
    name: 'airticket-offers-table',
    slider: false,
    setLimit: 5,
    logosBaseUrl: 'http://let.studentagency.cz/airline-logos/',
    params: {
      id: options.id || ''
    }
  };

  window.preRenderHook = true;

  var postInitHook = function(component, context) {
    if(!typeof Select == 'function') {
      return;
    }
  };

  var component = {
    options: $.extend({}, defaults, options),
    preRenderHook,
    postInitHook,
    render
  };

  return createComponent(component);
};
