//var renderTag = require('../utils/render-tag');
var tagRenderer = require('../../utils/tag-render');

module.exports = function (field, block) {

	field.fields.forEach((item) => {
		var itemBlock = tagRenderer.div('', `${item.id}-block form-element`);
		var label = tagRenderer.label(item.id, item.label);
	    if(item.required) {
	      label.append(' *');
	      itemBlock.attr('data-required', 'true');
	    }
	    itemBlock.attr('data-type', item.type);
		label.appendTo(itemBlock);
	    switch(item.type) {
	    	case 'radio':
	    		tagRenderer.radio(item.id, `${item.id}-radio-block validate-element`, item.items).appendTo(itemBlock);
	    		break;
	    }
	    itemBlock.appendTo(block);
	});

  return block;

};