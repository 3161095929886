/**
 * Upravené metody pro skryvani/zobrazovani tarifu
 * @type {Object}
 */
module.exports = {

  modify (selector, count) {

    count = count || 1;

    var visible = $(selector + ':visible').length;

    if(visible !== count)
      if(visible > count)
        $(selector + ':gt(' + (count - 1) + ')').hide();
      else
        $(selector + ':lt(' + count + ')').show();
  }

};