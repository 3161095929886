var modal = require('../../utils/modal');
var letterizeData = require('../../utils/letterize-data');
var renderTag = require('../../utils/render-tag');

module.exports = function (context) {

  return {
    showAirlines,
    selectAirline,
    removeAirline,
    maxAirlines
  };

  function showAirlines () {
    $.ajax({
      type: 'GET',
      url: context.data('opts').urls.airlines,
      success (data) {

        var selectedAirlines = context.data('form').airlines;


        modal.open({
          id: 'airlines-modal',
          title: `${data.headline} <small>${data.count}</small>`,
          content: getDialogContent(data)
        }, () => {

          selectedAirlines.forEach(renderSelectedAirline);
          maxAirlines(selectedAirlines.length);
        });

      }
    });

    function getDialogContent (data) {
      var content = renderTag.div('', 'airlines-modal');
      var alliances = {
        'ali-O': 'OneWorld',
        'ali-A': 'StarAlliance',
        'ali-S': 'SkyTeam'
      };
      var airlines = letterizeData(data.airlines, selectAirline);

      $(`<h3>${data.h3}</h3>`).appendTo(content);

      Object.keys(alliances).forEach((key) =>
        renderTag.div(alliances[key], 'add-alliance', key)
          .click(selectAirline)
          .appendTo(content)
      );

      $(`<h3>${data.h2}</h3>`).appendTo(content);

      airlines.appendTo(content);

      $('.letter-nav', airlines)
        .prependTo(content);

      return content;
    }

  }

  function renderSelectedAirline (id) {
    $('<div/>', {
      'data-id': id,
      'class': 'selected-airline btn btn-xs btn-aqua',
      text: id
    })
    .click(removeAirline)
    .appendTo($('.modal-header'));
  }

  function selectAirline () {
    
    var airlines = context.data('form').airlines;
    var id = $(this).attr('id');

    if (airlines.length <= 2 && airlines.indexOf(id) < 0) {

      airlines.push(id);
      $(this).hide();
      renderSelectedAirline(id);

      $('#airline').val(airlines.join(','));
    }

    maxAirlines(airlines.length);
  }

  function removeAirline () {
    
    var id = $(this).attr('data-id');
    var airlines = context.data('form').airlines;

    airlines.splice(airlines.indexOf($(this).text()), 1);
    $('#airline').val(airlines.join(','));

    $(this).remove();
    $(`#${id}`).show();

    if (airlines.length < 3) {
      $('.airlines-modal').show();
      $('.max-airlines').remove();
    }
  }

  function maxAirlines (airlinesLength) {

    if (airlinesLength > 2) {
      $('.airlines-modal').hide();
      renderTag.div('Vybrali jste maximální počet leteckých společností', 'max-airlines')
        .appendTo($('.modal-body'));
    }
  }
};

