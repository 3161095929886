var renderTag = require('../utils/render-tag');
var isoDate = require('../utils/iso-date');
// var autofocus = require('./actions/autofocus')();

module.exports = function (context, formTypeElement) {
  var destOptions = {
    detail: 'airport',
    template: '{city} / {name} ({value}) / {state}',
    templateSelect: '{city} ({value})',
    showDetail: true
  };

  if(context.data('opts') && context.data('opts')['destinations_tree_view'])
    destOptions['destinations_tree_view'] = true;


  var getDatepickerDate = require('./actions/datepicker')(context);
  var destination = require('./fields/destination')(destOptions, context);
  var renderRouteField = {
    date,
    destination
  };
  var opts = context.data('opts');
  var openJawClickCount = 0;
  var minDateOpts = opts && opts.minDate ? opts.minDate : +1;
  minDateOpts = `${minDateOpts}d`;

  return {
    addRoute,
    destinations,
    removeRoute,
    routes
  };

  function getPrettyDate(days) {
    var date = new Date();
    var month = date.getMonth() + 1;
    var day = date.getDate() + days;
    var year = date.getFullYear();
    var prettyDate = (day < 10 ? '0' : '') + day + '.' + (month < 10 ? '0' : '') + month + '.' + year;
    return prettyDate;
  }

  function date (item, block) {

    // korekce datumu u open jaw
    if(context.data('form').type === 'open_jaw' && opts.dateCorrectionOpenJaw && openJawClickCount < 2) {
      openJawClickCount++;
      if (item.index === 1)
        item.value = getPrettyDate(7);

      if(item.index === 2)
        item.value = getPrettyDate(14);

    } else
      item.value = item.value || item.def;

    block.addClass('date-field');

    var submitDate = renderTag.input('hidden', item.id, isoDate(item.value)).addClass(item.class).attr('id', item.name);
    var datepicker = renderTag.input('text', 'date_picker_' + item.index, item.value);

    datepicker.addClass('date_picker');
    datepicker.attr('readonly', true);

    //Nastaví globálne pre datepickery počet otvorených mesiacov
    if(item.monthsNumber) {
      $.datepicker.setDefaults({
        numberOfMonths: item.monthsNumber
      });
    }

    datepicker.datepicker({
      showOn: 'both',
      //numberOfMonths: item.monthsNumber ? item.monthsNumber : 1,
      minDate: minDateOpts,
      maxDate: '333d',
      showButtonPanel: true,
      onClose: function() {
        // autofocus.next()
      },
      closeText: 'X',
      prevText: '<',
      nextText: '>',
      currentText: '',
      dateFormat: 'dd.mm.yy',
      beforeShow,
      onSelect
    }).on('change', function () {
      onSelect($(this).val());
    });

    datepicker.find('.ui-datepicker-current').hide();

    datepicker.keyup((e) => {
      $(e.target).next().val(isoDate($(e.target).val()));
      context.data('form').routes[item.index - 1][2].value = $(e.target).val();
    });

    datepicker.appendTo(block);

    $('<div class="input-addon open-datepicker"/>')
      .click(() =>
        datepicker.datepicker('show'))
      .appendTo(block);

    submitDate.appendTo(block);

    function onSelect (val) {

      submitDate.val(isoDate(val));

      var selectedDate = new Date(isoDate(val));
      var parentRoute = datepicker.parents('.route');

      context.data('form').routes[parentRoute.data('index')][2].value = val;

      $('.route').each(function (index) {

        var dateField = $(this).find('.departure_date');
        var date = new Date(dateField.find('input[type=hidden]').val());

        if (selectedDate.getTime() > date.getTime() && index > parentRoute.data('index')) {
          dateField.find('input[type=hidden]').val(isoDate(val));
          dateField.find('input[type=text]').val(val);
          context.data('form').routes[index][2].value = val;
        }
      });
    }

    function beforeShow (val, dp){
      var index = $(this).parents('.route').data('index');

      if (index > 0 )
        dp.settings.minDate = getDatepickerDate('min', index);

      /*if (index + 1 < context.data('form').routes.length && index >= 0)
        dp.settings.maxDate = getDatepickerDate('max', index);*/

      if (index === 0) {
        dp.settings.minDate = minDateOpts;
        dp.settings.maxDate = '333d';
      }
    }

    return block;
  }

  function routeButton (route) {

    var routesLength = context.data('form').routes.length;
    var maxRoutes = context.data('form').maxRoutes || 5;
    var container = $('<div class="openjaw-buttons">');
    /*var add = renderTag.div(opts.messages.add_route, 'btn add-route');
    var remove = renderTag.div(opts.messages.remove_route, 'btn remove-route');*/
    var add = renderTag.div(opts.messages.add_route ? opts.messages.add_route : 'přidat cestu', 'btn add-route');
    var remove = renderTag.div(opts.messages.remove_route ? opts.messages.remove_route : 'odebrat cestu', 'btn remove-route');

    add.click(addRoute);
    remove.click(removeRoute);

    if (routesLength === 2)
      add.appendTo(container);

    if (routesLength > 2 && routesLength < maxRoutes) {
      add.appendTo(container);
      remove.appendTo(container);
    }

    if (routesLength === maxRoutes)
      remove.appendTo(container);

    container.appendTo(route);

    return container;
  }

  function destinations (field, block, index) {

    index = index || 0;

    var items = field.fields || field;
    var route = $(`<div id="route_${index + 1}" class="route"/>`).data('index', index);

    context.data('form').maxRoutes = field.max_destinations;

    if (!context.data('form').routes) {
      context.data('form').routes = [];
      context.data('form').routes.push(field.fields);
    }

    items.forEach((item, inputIndex) => {

      item.class = item.class || item.id.substring(0, item.id.length - 2);
      item.index = index + 1;
      item.inputIndex = inputIndex;
      item.id = item.class + '_' + item.index;
      var itemBlock = $(`<div class="${item.class}"/>`);

      renderTag.label(item.id, item.label).appendTo(itemBlock);
      renderRouteField[item.type](item, itemBlock).appendTo(route);
    });

    if (context.data('form').type === 'open_jaw' && index === context.data('form').routes.length - 1)
      routeButton(route);

    route.appendTo(block);
    return block;
  }

  function routes (limit, callback) {

    $('#airticket-destinations-block').empty();

    limit = limit || context.data('form').routes.length;

    context.data('form').routes.forEach(function (item, index) {
      if (index < limit)
        destinations(item, $('#airticket-destinations-block'), index);
    });

    if (typeof callback === 'function')
      callback();

    // autofocus.bind(context.data('form').routes)
  }

  function createRoute (from, to, date) {
    var fromLabel = context.find('#route_1 .departure_destination label').text();
    var toLabel = context.find('#route_1 .arrival_destination label').text();

    var dateLabel = context.find('#route_1 .departure_date label').text();

    return [{
      'class': 'departure_destination',
      label: fromLabel || 'Odlet z',
      type: 'destination',
      value: from || ''
    }, {
      'class': 'arrival_destination',
      label: toLabel || 'Přílet do',
      type: 'destination',
      value: to || ''
    }, {
      'class': 'departure_date',
      label: dateLabel || 'Odlet',
      type: 'date',
      value: date || ''
    }];
  }

  function addRoute (callback) {
    var routesData = context.data('form').routes;
    var daysUp = routesData.length === 1 ? 7 : 3;
    var newDate = new Date(isoDate(routesData[routesData.length - 1][2].value)).getTime() + (1000 * 60 * 60 * 24) * daysUp;
    var newRoute = createRoute('', '', $.datepicker.formatDate('dd.mm.yy', new Date(newDate)));

    $('#airticket-destinations-block').empty();
    routesData.push(newRoute);
    routes(null, callback);
  }

  function removeRoute () {

    $('#airticket-destinations-block').empty();
    context.data('form').routes.splice(context.data('form').routes.length - 1, 1);
    routes();
  }


};
