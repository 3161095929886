var renderTag = require('../utils/render-tag');

module.exports = function renderField (component, context) {
  var select = require('../utils/fields/select');
	var selectActions = require('./actions/jpSelActions')(component);

  return {
    jp_formtype,
    select
  };


  function jp_formtype (field, block) {

  	var first = true;

  	field.fields.forEach((item) => {

	    var formTypeRadio = renderTag.input('radio', field.id, item.id, first)
	      .click((e) => {
          	selectActions.jp_changeType(item.id);
          	formTypeRadio.prop('checked', true);
	      }).appendTo(block);

	     if(first) {
	     	formTypeRadio.prop('checked', true);
	     	first = false;
	     }

	    renderTag.label(/*field.id*/String(item.id), item.label)
        //.attr('data-formtype', item.id)
        //.addClass('formtype-label')
        .click((e) => {
          selectActions.jp_changeType(item.id);
          formTypeRadio.prop('checked', true);
          return false;
        })
        .appendTo(block);
	  });

  	return block;
  }

};

