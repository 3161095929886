var renderTag = require('../../utils/render-tag');

module.exports = function (field, block) {

  field.fields.forEach((item) => {
    var itemBlock = renderTag.div('', `${item.id}-block form-element`);
    renderTag.label(item.id, item.label).appendTo(itemBlock);
    renderTag.input(item.type, item.id, item.value).appendTo(itemBlock);
    itemBlock.appendTo(block);
  });

  return block;

};